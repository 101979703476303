import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'replyFormWrapper', 'editCommentFormWrapper'
  ]

  showReplyForm() {
    this.replyFormWrapperTarget.classList.remove('hidden')
    this.replyFormWrapperTarget.querySelector('ds-text-editor').focus()
  }

  hideReplyForm() {
    this.replyFormWrapperTarget.classList.add('hidden')
  }

  showEditCommentForm() {
    this.editCommentFormWrapperTarget.classList.remove('hidden')
    this.editCommentFormWrapperTarget.querySelector('ds-text-editor').focus()
  }

  hideEditCommentForm() {
    this.editCommentFormWrapperTarget.classList.add('hidden')
  }

  insertMention(e) {
    const { authorName, authorId } = e.target.dataset

    const editor = this.replyFormWrapperTarget.querySelector('ds-text-editor')

    editor.value = `
      <span data-type='mention'
        class='mention text-primary'
        data-label='${authorName}'
        data-id='${authorId}'
      >
        ${authorName}
      </span>&nbsp;`

    editor.focus()
  }
}
