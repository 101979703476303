<template>
  <div
    class="b-calendar inline-block pt-7 pb-6 px-6 bg-white border-gray-300 dark:bg-gray-800 dark:border-gray-700"
  >
    <header class="px-2 mb-3 text-gray-900 dark:text-white">
      <p class="text-base font-medium">
        {{ calendarTitle }}
        <section class="float-right -mt-1">
          <button
            class="h-8 w-8 mr-1 border border-gray-300 dark:border-gray-700 rounded text-center"
            type="button"
            @click="onClickPrevMonth"
          >
            <svg-sprite-icon
              class="inline-block"
              :width="24"
              :height="24"
              :icon="require('assets/icons/chevron-left-small.svg')"
            />
          </button>
          <button
            class="h-8 w-8 border border-gray-300 dark:border-gray-700 rounded text-center"
            type="button"
            @click="onClickNextMonth"
          >
            <svg-sprite-icon
              class="inline-block"
              :width="24"
              :height="24"
              :icon="require('assets/icons/chevron-right-small.svg')"
            />
          </button>
        </section>
      </p>
    </header>
    <ul class="grid grid-cols-7 mb-2">
      <li
        v-for="weekday in weekdays"
        :key="weekday"
        class="w-10 h-6 text-center text-black uppercase leading-6 dark:text-gray-500"
        :style="{ 'font-size': '10px' }"
      >
        {{ weekday }}
      </li>
    </ul>
    <ul class="grid grid-cols-7 gap-y-1">
      <template
        v-for="week in weeks"
      >
        <li
          v-for="day in week"
          :key="day.d.toDate().toString()"
          class="w-10 h-10"
        >
          <button
            class="b-calendar-day relative w-full h-full text-sm text-center border dark:text-white"
            :class="{
              'text-gray-900': !day.beforeCurrentMonth && !day.afterCurrentMonth && !day.disabled,
              'text-gray-300': day.beforeCurrentMonth || day.disabled,
              'text-gray-600': day.afterCurrentMonth && day.disabled,
              'bg-gray-100 border-gray-100 dark:bg-blue-800 dark:border-blue-800': day.inRange && !day.selected,
              'border-transparent': !day.selected,
              'font-medium border-blue-600 rounded': day.selected,
              'rounded-r-none': day.rangeStart && !rangeStart,
              'rounded-l-none': day.rangeFinish,
              today: day.today,
              'cursor-pointer': !day.disabled,
              'cursor-default': day.disabled,
            }"
            type="button"
            :disabled="day.disabled"
            @click="selectDay(day)"
          >
            {{ day.label }}
          </button>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import UTC from 'dayjs/plugin/utc'
import Timezone from 'dayjs/plugin/timezone'
import IsBetween from 'dayjs/plugin/isBetween'
import identity from 'lodash/identity'
import chunk from 'lodash/chunk'
import SvgSpriteIcon from 'common/components/SvgSpriteIcon'

dayjs.extend(UTC)
dayjs.extend(Timezone)
dayjs.extend(IsBetween)

export default {
  name: 'BCalendar',
  components: {
    SvgSpriteIcon,
  },
  props: {
    value: {
      type: [Number, Date, Array],
      default: null,
    },
    disablePastDates: {
      type: Boolean,
      default: false,
    },
    range: {
      type: Boolean,
      default: false,
    },
    timezone: {
      type: String,
      default: dayjs.tz.guess(),
    },
  },
  data() {
    return {
      weekdays: ['Sun', 'Mon', 'Tue', 'Wen', 'Thu', 'Fri', 'Sat'],
      year: dayjs().tz(this.timezone).year(),
      month: dayjs().tz(this.timezone).month(),
      date: dayjs().tz(this.timezone).date(),
      rangeStart: null,
    }
  },
  computed: {
    currentDate() {
      const { year, month, timezone } = this

      return dayjs().tz(timezone).year(year).month(month)
    },
    calendarTitle() {
      return this.currentDate.format('MMMM YYYY')
    },
    weeks() {
      const {
        value, rangeStart, disablePastDates, timezone
      } = this
      const dates = []
      const firstMonthWeekday = this.currentDate.startOf('month').startOf('week')
      const lastMonthWeekday = this.currentDate.endOf('month').endOf('week')
      let i = firstMonthWeekday.clone()

      while (i.isBefore(lastMonthWeekday)) {
        const today = i.isSame(this.currentDate, 'day')
        const baseDay = {
          label: i.date(),
          d: i,
          date: i.toDate(),
          beforeCurrentMonth: i.month() < this.month,
          afterCurrentMonth: i.month() > this.month,
          disabled: !today && (disablePastDates && i.isBefore(dayjs().tz(timezone), 'day')),
          selected: false,
          inRange: false,
          rangeStart: false,
          rangeFinish: false,
          today,
        }

        if (!Array.isArray(value) && !rangeStart) {
          dates.push({
            ...baseDay,
            selected: this.matchDates(i.valueOf(), value),
          })
        } else {
          const realRangeStart = rangeStart || value[0]
          const isRangeStart = this.matchDates(i.valueOf(), realRangeStart)
          const isRangeFinish = !rangeStart && this.matchDates(i.valueOf(), value[1])
          const isInRange = !rangeStart && i.isBetween(value[0], value[1])

          dates.push({
            ...baseDay,
            selected: isRangeStart || isRangeFinish,
            inRange: isInRange,
            rangeStart: isRangeStart,
            rangeFinish: isRangeFinish,
          })
        }

        i = i.add(1, 'days')
      }

      return chunk(dates, 7)
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        const value = Array.isArray(val) ? val?.[1] : val

        if (!val) return

        const days = this.weeks.flatMap(identity)
        const d = dayjs(value).tz(this.timezone)
        const isPresent = !!days.find(day => d.isSame(day.d, 'date'))

        if (isPresent) return

        this.year = d.year()
        this.month = d.month()
      },
    },
  },
  methods: {
    matchDates(a, b) {
      return dayjs(a).tz(this.timezone).isSame(b, 'year')
        && dayjs(a).tz(this.timezone).isSame(b, 'month')
        && dayjs(a).tz(this.timezone).isSame(b, 'date')
    },
    onClickPrevMonth() {
      if (this.month === 0) {
        this.year--
        this.month = 11
        return
      }

      this.month--
    },
    onClickNextMonth() {
      if (this.month === 11) {
        this.year++
        this.month = 0
        return
      }

      this.month++
    },
    selectDay({ d }) {
      if (!this.range || this.rangeStart === d.valueOf()) {
        this.rangeStart = null
        this.$emit('input', d.valueOf())
        return
      }

      if (!this.rangeStart) {
        this.rangeStart = d.valueOf()
        return
      }

      const range = [
        dayjs(this.rangeStart).tz(this.timezone).startOf('day').valueOf(),
        d.endOf('day').valueOf(),
      ]

      this.$emit('input', range.sort((a, b) => a - b))
      this.rangeStart = null
    },
  },
}
</script>

<style scoped>
.b-calendar-day {
  &.today::after {
    @apply absolute w-1 h-1 bg-blue-600 rounded-full;

    content: "";
    left: 50%;
    bottom: 4px;
    transform: translateX(-50%);
  }
}
</style>
