import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['clearableInput']

  submit() {
    this.element.closest('form').requestSubmit()
  }

  closeDrawer() {
    document.getElementById('people-more-filters-drawer').close()
  }

  apply() {
    this.submit()
    this.closeDrawer()
  }

  clearFilters() {
    this.clearableInputTargets.forEach(input => { input.clear() })
    setTimeout(() => this.submit(), 10)
  }
}
