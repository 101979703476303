import { Controller } from '@hotwired/stimulus'
import { truncate } from 'lodash'

export default class extends Controller {
  connect() {
    const form = this.element
    const submitButton = this.element.querySelector('[type="submit"]')

    const TRANSPARENT_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='

    form.addEventListener('turbo:submit-start', event => {
      const isReadOnly = form.closest('.readonly-step')

      if (isReadOnly || submitButton.disabled) {
        event.detail.formSubmission.stop()
        return
      }

      submitButton.setAttribute('disabled', 'true')
    })

    const supportPagesTogglers = form.querySelectorAll('.support-pages-toggler')

    if (supportPagesTogglers) {
      Array.from(supportPagesTogglers).forEach(element => {
        element.addEventListener('click', event => {
          event.preventDefault()

          const legacyPageURLContainer = event.target.closest('.lecacy-page-container')
          const customTargetToggler = legacyPageURLContainer.querySelector('.custom-target')
          const pagesToggler = legacyPageURLContainer.querySelector('.pages-target')
          const websitePagesFieldset = legacyPageURLContainer.querySelector('.website-pages-fields')
          const customURLFieldset = legacyPageURLContainer.querySelector('.custom-url-fields')
          const hiddenIsURLCustomField = legacyPageURLContainer.querySelector('input[type="hidden"]')

          hiddenIsURLCustomField.value = !(hiddenIsURLCustomField.value === 'true')
          customTargetToggler.classList.toggle('hidden')
          pagesToggler.classList.toggle('hidden')
          websitePagesFieldset.classList.toggle('hidden')
          websitePagesFieldset.toggleAttribute('disabled')
          customURLFieldset.toggleAttribute('disabled')
          customURLFieldset.classList.toggle('hidden')
        })
      })
    }

    // Colors step
    const accentColorInput = form.querySelector('[name*=accent_color]')
    const darkModeAccentColorInput = form.querySelector('[name*=dark_mode_accent_color]')
    const colorStyleTag = document.querySelector('#builder-accent-color')

    if (accentColorInput && darkModeAccentColorInput && colorStyleTag) {
      [accentColorInput, darkModeAccentColorInput].forEach(colorPickerInput => {
        colorPickerInput.addEventListener('change', () => {
          colorStyleTag.textContent = `#builder-screen {
          --builder-accent-color: ${accentColorInput.value};
          --builder-dark-mode-accent-color: ${darkModeAccentColorInput.value};
        }`
        })
      })
    }

    // App name and icon step
    // App name
    const appNameInput = document.getElementById('native_apps_builder_config[app_name]')

    if (appNameInput) {
      appNameInput.addEventListener('input', event => {
        document.getElementById('svg-app-name').innerHTML = truncate(event.target.value, { length: 10 })
      })
    }

    // App icon
    const appIconUploaderInput = form.querySelector('[name*=app_icon]')

    if (appIconUploaderInput) {
      const previewIconElement = document.getElementById('preview-icon')
      const noPreviewIconElement = document.getElementById('no-preview-icon')

      appIconUploaderInput.addEventListener('change', event => {
        const imageURL = event.target?._input?.input?.value

        if (imageURL) {
          previewIconElement.classList.remove('hidden')
          noPreviewIconElement.classList.add('hidden')
          previewIconElement.querySelector('image').setAttribute('xlink:href', imageURL)
        } else {
          previewIconElement.classList.add('hidden')
          noPreviewIconElement.classList.remove('hidden')
          previewIconElement.querySelector('image').setAttribute('xlink:href', '')
        }
      })
    }

    // Launch screen step
    const launchScreenUploaderInput = form.querySelector('[name*=launch_screen_image]')

    if (launchScreenUploaderInput) {
      const previewLaunchScreenElement = document.getElementById('preview-launch-with-image')
      const noPreviewLaunchScreenElement = document.getElementById('preview-launch-without-image')

      launchScreenUploaderInput.addEventListener('change', event => {
        const imageURL = event.target?._input?.input?.value

        if (imageURL) {
          previewLaunchScreenElement.classList.remove('hidden')
          noPreviewLaunchScreenElement.classList.add('hidden')
          previewLaunchScreenElement.querySelector('image').setAttribute('xlink:href', imageURL)
        } else {
          previewLaunchScreenElement.classList.add('hidden')
          noPreviewLaunchScreenElement.classList.remove('hidden')
          previewLaunchScreenElement.querySelector('image').setAttribute('xlink:href', '')
        }
      })
    }

    // Header image light mode step
    const headerImageUploaderInput = form.querySelector('ds-image-uploader[name*=header_image]')

    if (headerImageUploaderInput) {
      headerImageUploaderInput.addEventListener('change', event => {
        const imageURL = event.target?._input?.input?.value
        const previewHeaderLightMode = document.getElementById('header-image-light-mode')

        if (imageURL) {
          previewHeaderLightMode.setAttribute('href', imageURL)
        } else {
          previewHeaderLightMode.setAttribute('href', TRANSPARENT_IMAGE)
        }
      })
    }

    // Header image dark mode step
    const darkModeHeaderImageUploaderInput = form.querySelector('ds-image-uploader[name*=dark_mode_header_image]')

    if (darkModeHeaderImageUploaderInput) {
      const previewHeaderDarkMode = document.getElementById('header-image-dark-mode')

      darkModeHeaderImageUploaderInput.addEventListener('change', event => {
        const imageURL = event.target?._input?.input?.value

        if (imageURL) {
          previewHeaderDarkMode.setAttribute('href', imageURL)
        } else {
          previewHeaderDarkMode.setAttribute('href', TRANSPARENT_IMAGE)
        }
      })
    }

    // Header image step
    if (form.querySelector('#header_image_included')) {
      form.querySelector('#header_image_included').addEventListener('change', () => {
        const headerUpoloadingInputs = document.getElementById('header-uploading-inputs')

        headerUpoloadingInputs.classList.toggle('hidden')
      })
    }

    // TV Branging
    // TV color schema
    const tvColorSchemaOptions = form.querySelectorAll('input[name*=tv_color_theme]')

    if (tvColorSchemaOptions) {
      Array.from(tvColorSchemaOptions).forEach((element) => {
        element.addEventListener('change', event => {
          const svgView = document.getElementById('tv-preview')
          const colorPickerInput = document.querySelector('ds-color-picker-input')

          if (event.target.value === 'light') {
            svgView.classList.remove('dark')
            svgView.classList.add('light')
            colorPickerInput.setAttribute('background', '#fff')
            colorPickerInput.handleLocalValueChange()
            Array.from(document.querySelectorAll('#first-gradient stop, #second-gradient stop')).forEach((el) => el.setAttribute('stop-color', 'white'))
          } else {
            svgView.classList.remove('light')
            svgView.classList.add('dark')
            colorPickerInput.setAttribute('background', '#000')
            colorPickerInput.handleLocalValueChange()
            Array.from(document.querySelectorAll('#first-gradient stop, #second-gradient stop')).forEach((el) => el.setAttribute('stop-color', 'black'))
          }
        })
      })
    }

    const accentTvColorInput = form.querySelector('[name*=tv_accent_color]')

    if (accentTvColorInput) {
      accentTvColorInput.addEventListener('change', () => {
        const svgView = document.getElementById('tv-preview')

        svgView.style = `--accent-color: ${accentTvColorInput.value}`
      })
    }

    // TV channel icon
    const appTvIconUploaderInput = form.querySelector('[name*=tv_channel_icon]')

    if (appTvIconUploaderInput) {
      const previewElement = document.getElementById('preview-icon')
      const noPreviewElement = document.getElementById('no-preview-icon')

      appTvIconUploaderInput.addEventListener('change', event => {
        const imageURL = event.target?._input?.input?.value

        if (imageURL) {
          previewElement.classList.remove('hidden')
          noPreviewElement.classList.add('hidden')
          previewElement.querySelector('image').setAttribute('xlink:href', imageURL)
        } else {
          previewElement.classList.add('hidden')
          noPreviewElement.classList.remove('hidden')
          previewElement.querySelector('image').setAttribute('xlink:href', '')
        }
      })
    }

    // TV top shelf banner
    const appTvTopShelfBannerUploaderInput = form.querySelector('[name*=tv_top_shelf_banner]')

    if (appTvTopShelfBannerUploaderInput) {
      const previewElement = document.getElementById('banner-preview')
      const noPreviewElement = document.getElementById('no-banner-preview')

      appTvTopShelfBannerUploaderInput.addEventListener('change', event => {
        const imageURL = event.target?._input?.input?.value

        if (imageURL) {
          previewElement.classList.remove('hidden')
          noPreviewElement.classList.add('hidden')
          previewElement.querySelector('image').setAttribute('xlink:href', imageURL)
        } else {
          previewElement.classList.add('hidden')
          noPreviewElement.classList.remove('hidden')
          previewElement.querySelector('image').setAttribute('xlink:href', '')
        }
      })
    }

    // TV launch screen
    const appTvLaunchScreenUploaderInput = form.querySelector('[name*=tv_launch_screen]')

    if (appTvLaunchScreenUploaderInput) {
      const previewElement = document.getElementById('launch-screen-preview')
      const noPreviewElement = document.getElementById('no-launch-screen-preview')

      appTvLaunchScreenUploaderInput.addEventListener('change', event => {
        const imageURL = event.target?._input?.input?.value

        if (imageURL) {
          previewElement.classList.remove('hidden')
          noPreviewElement.classList.add('hidden')
          previewElement.querySelector('image').setAttribute('xlink:href', imageURL)
        } else {
          previewElement.classList.add('hidden')
          noPreviewElement.classList.remove('hidden')
          previewElement.querySelector('image').setAttribute('xlink:href', '')
        }
      })
    }

    // TV login background
    const appTvLoginBackgroundUploaderInput = form.querySelector('[name*=tv_login_background]')

    if (appTvLoginBackgroundUploaderInput) {
      const previewElement = document.getElementById('login-background-preview')
      const noPreviewElement = document.getElementById('no-login-background-preview')

      appTvLoginBackgroundUploaderInput.addEventListener('change', event => {
        const imageURL = event.target?._input?.input?.value

        if (imageURL) {
          previewElement.classList.remove('hidden')
          noPreviewElement.classList.add('hidden')
          previewElement.querySelector('image').setAttribute('xlink:href', imageURL)
        } else {
          previewElement.classList.add('hidden')
          noPreviewElement.classList.remove('hidden')
          previewElement.querySelector('image').setAttribute('xlink:href', '')
        }
      })
    }

    // TV logo preview
    const appTvLogoUploaderInput = form.querySelector('[name*=tv_transparent_header_logo]')

    if (appTvLogoUploaderInput) {
      const previewElement = document.getElementById('logo-preview')

      appTvLogoUploaderInput.addEventListener('change', event => {
        const imageURL = event.target?._input?.input?.value

        if (imageURL) {
          previewElement.classList.remove('hidden')
          previewElement.querySelector('image').setAttribute('xlink:href', imageURL)
        } else {
          previewElement.classList.add('hidden')
          previewElement.querySelector('image').setAttribute('xlink:href', '')
        }
      })
    }

    // App store
    // App store subtitle
    const appStoreSubtitle = form.querySelector('[name*=app_store_subtitle]')

    if (appStoreSubtitle) {
      appStoreSubtitle.addEventListener('input', event => {
        document.getElementById('app-store-subtitle').innerHTML = truncate(event.target.value, { length: 25 })
      })
    }

    // App store short description
    const appStoreShortDescription = form.querySelector('[name*=app_store_short_description]')

    if (appStoreShortDescription) {
      appStoreShortDescription.addEventListener('input', event => {
        document.getElementById('app-store-short-description').innerHTML = event.target.value
      })
    }

    // App store long description
    const appStoreLongDescription = form.querySelector('[name*=app_store_long_description]')

    if (appStoreLongDescription) {
      appStoreLongDescription.addEventListener('input', event => {
        document.getElementById('app-store-long-description').innerHTML = event.target.value
      })
    }

    // App store onboarding screen option
    const appStoreOnboardingScreenOption = form.querySelector('[name*=onboarding_screen_option]')

    if (appStoreOnboardingScreenOption) {
      const headerImageBlock = document.getElementById('onboarding-screen-header-image')
      const customSlidesBlock = document.getElementById('onboarding-screen-custom-slides')

      appStoreOnboardingScreenOption.addEventListener('change', () => {
        headerImageBlock.classList.toggle('hidden', appStoreOnboardingScreenOption.value === 'multiple')
        customSlidesBlock.classList.toggle('hidden', appStoreOnboardingScreenOption.value === 'single')
      })
    }

    if (form.querySelector('#onboarding_header_image_included')) {
      form.querySelector('#onboarding_header_image_included').addEventListener('change', () => {
        const onboardingHeaderUpoloadingInput = document.getElementById('onboarding-header-uploading-input')

        onboardingHeaderUpoloadingInput.classList.toggle('hidden')
      })
    }

    const customSlideTogglers = form.querySelectorAll('.custom-slide-toggler')

    if (customSlideTogglers) {
      Array.from(customSlideTogglers).forEach(element => {
        element.addEventListener('click', () => {
          const parent = element.closest('.onboarding-screen-slide-item')
          if (!parent?.classList.contains('slide-item-opened')) {
            form.querySelector('.onboarding-screen-slide-item.slide-item-opened').classList.remove('slide-item-opened')
            parent.classList.add('slide-item-opened')
          }
        })
      })
    }

    // Access
    // Content rating
    form.addEventListener('change', event => {
      if (event.target.classList.contains('rating-item')) {
        const ratingNoneItem = document.getElementById('rating-none')

        if (event.target === ratingNoneItem) {
          Array.from(form.querySelectorAll('.rating-item'))
            .filter(el => el !== ratingNoneItem)
            .forEach(el => { el.checked = false })
        } else {
          ratingNoneItem.checked = false
        }
      }
    })

    // Developer accounts
    // Android api key JSON file uploading
    const jsonUploader = document.getElementById('json-uploader')
    if (jsonUploader) {
      jsonUploader.addEventListener('success', event => {
        const [file] = event.detail
        if (file) {
          jsonUploader.previousElementSibling.value = file.uploadURL
          jsonUploader.nextElementSibling.querySelector('a').setAttribute('href', file.uploadURL)
        }
      })
    }

    // Step appearing auto-scroll helper
    const APP_HEADER_HEIGHT = 57
    const BUILDER_HEADER_HEIGHT = 140
    const TOP_OFFSET = APP_HEADER_HEIGHT + BUILDER_HEADER_HEIGHT

    const isElementInViewport = el => {
      const rect = el.getBoundingClientRect()

      return (
        (rect.top - TOP_OFFSET) >= 0
        && rect.left >= 0
        && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      )
    }

    const currentlyOpenedStep = form.parentElement.previousElementSibling
    if (currentlyOpenedStep && !isElementInViewport(currentlyOpenedStep)) {
      window.scrollTo({ top: currentlyOpenedStep.offsetTop - TOP_OFFSET, behavior: 'smooth' })
    }

    // Autofocus helper
    setTimeout(() => {
      const isFormEditable = !form.closest('.readonly-step')

      if (isFormEditable) {
        const typingElement = form.querySelector('ds-input, textarea')
        const typingElementInput = typingElement?.input || typingElement

        if (typingElementInput) {
          typingElementInput.focus()
          typingElementInput.setSelectionRange(-1, -1)
        }
      }
    }, 100)
  }
}
