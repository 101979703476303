import { Controller } from '@hotwired/stimulus'
import copyToClipboard from 'common/utils/copy'

export default class extends Controller {
  static targets = ['checkbox', 'moreActionsButton']

  static values = { id: String, deletable: Boolean }

  setSelected(value) {
    if (value) {
      this.element.classList.add('bg-gray-100')
      this.moreActionsButtonTarget.classList.add('invisible')
      this.checkboxTarget.checked = true
    } else {
      this.element.classList.remove('bg-gray-100')
      this.checkboxTarget.checked = false
      this.moreActionsButtonTarget.classList.remove('invisible')
    }
  }
  copyLink(e) {
    e.preventDefault()

    const container = document.getElementById('flash_messages')

    const message = document.createElement('div')
    message.classList.add('u-flash', 'u-flash-success')
    message.setAttribute('data-controller', 'alert')
    message.setAttribute('data-alert-timeout-value', '5000')
    message.setAttribute('data-action', 'click->alert#close')
    message.setAttribute('data-alert-target', 'alert')

    message.innerText = 'Link has been copied to your clipboard.'

    container.appendChild(message)

    copyToClipboard(e.currentTarget.dataset.url)
  }

  changeCheckbox(e) {
    this.setSelected(e.target.checked)
  }
}
