import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['loading']

  changePage({ params: { page } }) {
    window.history.replaceState(
      {},
      document.title,
      `${document.location.pathname}?page=${page}`
    )
    if (this.hasLoadingTarget) {
      this.loadingTarget.classList.toggle('hidden')
    }
  }
}
