import { FetchRequest } from '@rails/request.js'

const sendTurboStreamRequest = (element, id, searchQuery, url, method, params = {}) => {
  if (method === 'get') {
    const parsedUrl = new URL(url.includes('://') ? url : window.location.origin + url)
    parsedUrl.searchParams.append('q', searchQuery)
    parsedUrl.searchParams.append('id', id)

    if (params) {
      Object.keys(params).forEach(key => {
        parsedUrl.searchParams.append(key, params[key])
      })
    }

    new FetchRequest(method, parsedUrl.pathname + parsedUrl.search,
      {
        responseKind: 'turbo-stream'
      }).perform()
  } else {
    new FetchRequest(method, url,
      {
        responseKind: 'turbo-stream',
        body: JSON.stringify({ id, q: searchQuery, ...params })
      }).perform()
  }
}

const sendFormSubmitRequest = (element, id, searchQuery, formSelector, params = {}) => {
  const formElement = formSelector === 'closest' ? element.closest('form') : document.querySelector(formSelector)

  if (!formElement) {
    return
  }

  const formData = new FormData(formElement)
  formData.append('q', searchQuery)
  formData.append('id', id)

  if (params) {
    Object.keys(params).forEach(key => {
      formData.append(key, params[key])
    })
  }

  formElement.requestSubmit()
}

const sendExternalSearchRequest = ({ url, formSelector, type, method, params, element, id, q }) => {
  switch (type) {
    case 'turbo-stream':
      sendTurboStreamRequest(element, id, q, url, method, params)
      break
    case 'form':
      sendFormSubmitRequest(element, id, q, formSelector, params)
      break
    default:
      console.error('Unknown external search type:', type)
      break
  }
}

const useExternalSearch = (controller) => {
  return (id, q) => {
    sendExternalSearchRequest({
      url: controller.externalSearchUrlValue,
      formElement: controller.externalSearchFormValue,
      type: controller.externalSearchTypeValue,
      method: controller.externalSearchMethodValue,
      params: controller.externalSearchParamsValue,
      element: controller.element,
      id,
      q
    })
  }
}

export default useExternalSearch
