import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['bulkDeleteDialog']

  hideTableToolbar() {
    this.beamTableController.setToolbarToolsVisible(false)
    this.beamTableController.setCheckboxesChecked(false)
    this.beamTableController.selectAllCheckboxTarget.checked = false

    this.bulkDeleteDialogTarget.close()
  }

  showModal() {
    if (this.beamTableController.querySelectedCount > 1) {
      this.bulkDialogModalController.setContent('Are you sure you want to delete this funnels?')
    } else {
      this.bulkDialogModalController.setContent('Are you sure you want to delete this funnel?')
    }

    this.bulkDeleteDialogTarget.showModal()
  }

  get beamTableController() {
    return this.application.getControllerForElementAndIdentifier(this.element.querySelector('[data-controller="beam--table"]'), 'beam--table')
  }

  get bulkDialogModalController() {
    return this.application.getControllerForElementAndIdentifier(this.bulkDeleteDialogTarget, 'beam--modal')
  }
}
