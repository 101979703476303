import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['filterForm', 'searchInput', 'filterStatus']

  connect() {
  }

  selectFilter() {
    this.filterFormTarget.requestSubmit()
  }

  clearFilter() {
    this.filterStatusTarget.value = []

    setTimeout(() => {
      this.filterFormTarget.requestSubmit()
    }, 0)
  }

  clearSearch(e) {
    e.preventDefault()
    e.target.classList.add('hidden')

    this.searchInputTarget.value = ''

    this.filterFormTarget.requestSubmit()
  }
}
