import { Controller } from '@hotwired/stimulus'
import { debounce } from 'lodash'

export default class extends Controller {
  static targets = [
    'tableForm',
    'loader'
  ]

  static values = {
    url: String,
    csvExportUrl: String
  }

  initialize() {
    this.submit = debounce(this.submit.bind(this), 300)
  }

  cleanEmptyKeys(searchParams) {
    const result = new URLSearchParams({})

    searchParams.forEach((value, key) => {
      if (value && value !== 'undefined') {
        result.append(key, value)
      }
    })

    return result
  }

  cleanFilterBadges(url, event) {
    if (event.target.dataset.filterBadgeName) {
      switch (event.target.dataset.filterBadgeName) {
        case 'subscription':
          url.searchParams.delete('subscription_plan_id')
          break
        case 'status':
          url.searchParams.delete('status')
          break
        case 'option':
          url.searchParams.delete('option')
          break
        default:
          url.searchParams.delete(`[${event.target.dataset.filterBadgeName}]`)
      }
    }
  }

  _metaKeys = {
    format: true,
    authenticity_token: true,
    utf8: true
  }

  searchParamsWithoutMetaKeys(searchParams) {
    const result = new URLSearchParams({})

    searchParams.forEach((value, key) => {
      if (!this._metaKeys[key]) {
        result.append(key, value)
      }
    })

    return result
  }

  get tableFormParams() {
    const formData = new FormData(this.tableFormTarget)
    return this.cleanEmptyKeys(new URLSearchParams(formData)).toString()
  }

  tableButtonParams(event) {
    const { key, value } = event.target.dataset
    const searchParams = new URLSearchParams()

    searchParams.append(key, value)
    return this.cleanEmptyKeys(searchParams).toString()
  }

  async submit(event) {
    const queryParams = `?${this.tableFormParams}&${this.tableButtonParams(event)}`
    const url = new URL(`${this.urlValue}${queryParams}`)

    // Clicking on a filter badge removes the filter from params.
    this.cleanFilterBadges(url, event)

    url.searchParams.set('format', 'turbo_stream')

    const searchParamsString = this.searchParamsWithoutMetaKeys(url.searchParams).toString()

    if (searchParamsString !== this.searchParamsString) {
      this.searchParamsString = searchParamsString

      this._showLoader()

      await fetch(url)
        .then(response => response.text())
        .then(html => {
          Turbo.renderStreamMessage(html)
          url.searchParams.delete('format')
          url.searchParams.delete('authenticity_token')
          url.searchParams.delete('utf8')
          history.replaceState(null, null, url.search.length > 0 ? url.search : '?')
          this._hideLoader()
        })
    }
  }

  _showLoader = () => {
    this.loaderTarget.showLoader()
  }

  _hideLoader = () => {
    this.loaderTarget.hideLoader()
  }

  async exportCsv(event) {
    const queryParams = `?${this.tableFormParams}&${this.tableButtonParams(event)}`

    const url = new URL(`${this.csvExportUrlValue}${queryParams}`)
    url.searchParams.set('format', 'turbo_stream')

    this._showLoader()
    await fetch(url)
      .then(response => response.text())
      .then(html => {
        Turbo.renderStreamMessage(html)
      })
      this._hideLoader()
  }
}
