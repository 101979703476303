import { Controller } from '@hotwired/stimulus'
import MoneyChart from 'bullet/utils/MoneyChart'

export default class extends Controller {
  static values = {
    labels: Array,
    values: {
      type: Array,
      default: [
        10000000,
        12000000,
        11000000,
        14000000,
        13500000,
        16000000,
        15900000,
        17100000,
        17200000,
        17300000,
        17400000,
        18200000
      ]
    },
    currency: String,
    canvas: String,
  }

  connect() {
    this.chart = new MoneyChart(this.labelsValue, this.valuesValue, this.currencyValue, this.canvasValue)
  }
}
