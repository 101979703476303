import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'submit', 'spinner', 'submitText', 'stripeMessage']

  static values = {
    publicKey: String,
    clientSecret: String,
  }

  connect() {
    this.setupIntentCompleted = false
    // eslint-disable-next-line no-undef
    this.stripe = Stripe(this.publicKeyValue, { locale: 'en' })

    const options = {
      clientSecret: this.clientSecretValue,
      appearance: {
        theme: 'stripe',
        labels: 'above',
        fonts: [{
          cssSrc: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&amp;display=swap',
        }],
        appearance: {
          theme: 'stripe',
          labels: 'above',
          variables: {
            fontFamily: '"Inter", Helvetica, Arial, sans-serif',
            fontSizeSm: '1.0rem',
            fontSmooth: 'auto',
            colorText: '#1a202c',
            colorTextPlaceholder: '#a0afc0',
          },
          rules: {
            '.Label': {
              marginBottom: '0.5rem',
              height: '24px',
            },
          },
        },
      },
    }

    this.elements = this.stripe.elements(options)
    this.paymentElement = this.elements.create('payment', {
      fields: {
        billingDetails: 'auto',
      },
      terms: { card: 'never' },
    })

    this.paymentElement.on('ready', () => {
      this.setLoading(false)
    })

    this.paymentElement.mount('#payment-element')
  }

  save(event) {
    this.setLoading(true)

    if (this.setupIntentCompleted) {
      return
    }

    event.stopImmediatePropagation()
    event.preventDefault()

    const { elements } = this
    this.stripe.confirmSetup({
      elements,
      redirect: 'if_required',
    }).then(result => {
      if (result.error) {
        const { error } = result
        if (error.type === 'card_error' || error.type === 'validation_error') {
          this.showMessage(error.message)
        } else {
          this.showMessage('An unexpected error occured.')
        }

        this.setLoading(false)
      } else {
        const paymentMethodField = document.getElementById('payment_method')
        paymentMethodField.value = result.setupIntent.payment_method
        this.setupIntentCompleted = true
        this.formTarget.requestSubmit()
      }
    }).catch(error => {
      this.showMessage(error.message)
      this.setLoading(false)
    })
  }

  showMessage(messageText) {
    this.stripeMessageTarget.classList.remove('hidden')
    this.stripeMessageTarget.textContent = messageText

    setTimeout(() => {
      this.stripeMessageTarget.classList.add('hidden')
      this.stripeMessageTarget.textContent = ''
    }, 4000)
  }

  // Show a spinner on payment submission
  setLoading(isLoading) {
    if (isLoading) {
      this.submitTarget.disabled = true
      this.spinnerTarget.classList.remove('hidden')
      this.submitTextTarget.classList.add('hidden')
    } else {
      this.submitTarget.disabled = false
      this.spinnerTarget.classList.add('hidden')
      this.submitTextTarget.classList.remove('hidden')
    }
  }
}
