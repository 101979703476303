import { Controller } from '@hotwired/stimulus'
import API from 'libs/api'

export default class extends Controller {
  static values = { url: String }

  async toggleChecklist() {
    await API.put(this.urlValue)
  }

  closeChecklist() {
    document.querySelector('#onboarding-checklist-widget').classList.add('!translate-x-full')
    document.querySelector('#bullet-container').classList.remove('lg:pr-80')
    setTimeout(() => {
      document.querySelector('#onboarding-checklist-widget').classList.add('opacity-0')
    }, 300)
    this.toggleChecklist()
  }

  openChecklist() {
    if (document.querySelector('.onboarding-checklist')) {
      document.querySelector('.onboarding-checklist').scrollIntoView({ behavior: 'smooth' })
    }

    if (document.querySelector('#onboarding-checklist-widget')) {
      document.querySelector('#onboarding-checklist-widget').classList.remove('!translate-x-full', 'opacity-0')
      document.querySelector('#bullet-container').classList.add('lg:pr-80')
      this.toggleChecklist()
    }
  }
}
