import Controller from 'libs/controller'
import {
  MediaPermissionsErrorType,
  requestMediaPermissions
} from 'mic-check'

export default class extends Controller {
  static targets = ['containerElement', 'messageElement']

  connect() {
    if (!this.containerElementTarget.classList.contains('hidden')) {
      return
    }

    requestMediaPermissions()
      .then(() => {
        // can successfully access camera and microphone streams
        this.containerElementTarget.classList.add('hidden')
      })
      .catch(error => {
        const { type } = error

        let message = null

        if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
          // browser does not have permission to access camera or microphone
          const intro = 'Seems like your browser cannot access your camera or microphone. '
          const instructions1 = 'Please check the System settings '
          const instructions2 = 'and make sure your browser has permissions for camera and microphone.'
          message = intro + instructions1 + instructions2
        } else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
          // user didn't allow app to access camera or microphone
          const intro = 'Seems like you have not given access to your camera or microphone. '
          const instructions = 'Please check your browser permissions and reload the page.'
          message = intro + instructions
        } else if (type === MediaPermissionsErrorType.CouldNotStartVideoSource) {
          // camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)
          // (mostly Windows specific problem)
          message = 'Seems like the camera is in use by another app. Please close other apps and try again.'
        } else {
          // generic errors are covered here. Usually it happens when camera or mirophone
          // are not available (when laptop is closed, for example)
          const intro = 'It was not possible to access your camera or microphone. '
          const instructions = 'Please make sure camera and microphone are available.'
          message = intro + instructions
        }

        this.messageElementTarget.textContent = message
        this.containerElementTarget.classList.remove('hidden')
      })
  }
}
