import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    token: String,
    url: String
  }

  LiveKit
  async connect() {
    this.LiveKit = await import('@package/livekit')
    this.LiveKit.render(this.element, {
      token: this.tokenValue,
      url: this.urlValue
    })
  }

  disconnect() {
    this.LiveKit.destroy(this.element)
  }
}
