import { Controller } from '@hotwired/stimulus'
import dayjs from 'dayjs'
import UTC from 'dayjs/plugin/utc'
import AdvancedFormat from 'dayjs/plugin/advancedFormat'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(UTC)
dayjs.extend(AdvancedFormat)
dayjs.extend(LocalizedFormat)

export default class extends Controller {
  static targets = [
    'releaseOptionsScheduled', 'scheduledAtPicker', 'timeZoneWarning', 'storeTimeZoneOffset',
    'submitButtonEnabled', 'submitButtonDisabled',
    'linkCategoryValue', 'deepLinkValue', 'subscriptionsFilter', 'bundlesFilter', 'leadsFilter','sendToBlock',
    'leadTypeBlock', 'youtubeLeadsFilter', 'trialsFilter'
  ]

  connect() {
    this.scheduledAtPickerTarget.style.display = this.releaseOptionsScheduledTarget.checked ? null : 'none'
    this.changeSubmitText()
    this.showTimeZoneWarning()
  }

  selectReleaseStage(event) {
    this.scheduledAtPickerTarget.style.display = event.target.id === 'schedule' ? null : 'none'
    this.changeSubmitText()
  }

  changeSubmitText() {
    if (this.releaseOptionsScheduledTarget.checked) {
      this.submitButtonEnabledTarget.textContent = 'Save'
      this.submitButtonDisabledTarget.textContent = 'Saving...'
    } else {
      this.submitButtonEnabledTarget.textContent = 'Send Now'
      this.submitButtonDisabledTarget.textContent = 'Sending...'
    }
  }

  showTimeZoneWarning() {
    const browserTimeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone
    if (!browserTimeZoneName) return

    const browserTimeZoneOffset = dayjs().tz(browserTimeZoneName).format('Z')
    const storeTimeZoneOffset = dayjs().tz(this.data.get('storeTimeZone')).format('Z')

    if (browserTimeZoneOffset === storeTimeZoneOffset) return

    this.storeTimeZoneOffsetTarget.text = storeTimeZoneOffset
    this.timeZoneWarningTarget.style.display = null
  }

  linkCategoryChanged(e) {
    const selectedCategory = e.target.value
    // if calls also when a link is selected so we need to ignore that events
    // select events values are array
    if (typeof selectedCategory !== 'string') return

    this.linkCategoryValueTargets.forEach(target => {
      if (target.id === selectedCategory) {
        target.classList.remove('hidden')
      } else {
        target.classList.add('hidden')
      }
    })
  }

  syncDeepLinkId(e) {
    this.deepLinkValueTarget.value = e.target.value
  }

  recipientsTypeChanged() {
    const selectedRecipientsType = this.sendToBlockTarget.value

    if (selectedRecipientsType === 'for_subscribers') {
      this.trialsFilterTarget.classList.add('hidden')
      if (this.hasBundlesFilterTarget) { this.bundlesFilterTarget.classList.add('hidden') }
      if (this.hasLeadsFilterTarget) { this.leadsFilterTarget.classList.add('hidden') }
      this.subscriptionsFilterTarget.classList.remove('hidden')
    } else if (selectedRecipientsType === 'for_bundle_owners') {
      this.trialsFilterTarget.classList.add('hidden')
      this.subscriptionsFilterTarget.classList.add('hidden')
      if (this.hasLeadsFilterTarget) { this.leadsFilterTarget.classList.add('hidden') }
      if (this.hasBundlesFilterTarget) { this.bundlesFilterTarget.classList.remove('hidden') }
    } else if (selectedRecipientsType === 'for_leads') {
      this.trialsFilterTarget.classList.add('hidden')
      this.subscriptionsFilterTarget.classList.add('hidden')
      if (this.hasBundlesFilterTarget) { this.bundlesFilterTarget.classList.add('hidden') }
      if (this.hasLeadsFilterTarget) { this.leadsFilterTarget.classList.remove('hidden') }
    } else if (selectedRecipientsType === 'for_trialing') {
      this.subscriptionsFilterTarget.classList.add('hidden')
      if (this.hasBundlesFilterTarget) { this.bundlesFilterTarget.classList.add('hidden') }
      if (this.hasLeadsFilterTarget) { this.leadsFilterTarget.classList.add('hidden') }
      this.trialsFilterTarget.classList.remove('hidden')
    } else {
      this.trialsFilterTarget.classList.add('hidden')
      this.subscriptionsFilterTarget.classList.add('hidden')
      if (this.hasBundlesFilterTarget) { this.bundlesFilterTarget.classList.add('hidden') }
      if (this.hasLeadsFilterTarget) { this.leadsFilterTarget.classList.add('hidden') }
    }

    this.reloadTotalRecipients()
  }

  leadsTypeChanged() {
    const selectedLeadsType = this.leadTypeBlockTarget.value

    this.youtubeLeadsFilterTarget.classList.toggle('hidden', !(selectedLeadsType === 'youtube_funnels'))

    this.reloadTotalRecipients()
  }

  reloadTotalRecipients() {
    const recipientsTotalFrame = document.getElementById('recipients_total')
    const recipientsType = this.sendToBlockTarget.value
    const subscriptionStatuses = document.getElementById('subscription_statuses_select').value
    let leadsType = ''

    if (this.hasLeadTypeBlockTarget) { leadsType = this.leadTypeBlockTarget.value }

    let productsId = recipientsType === 'for_bundle_owners' ? 'bundles_products_ids_select' : 'products_ids_select'
    if (recipientsType == 'for_trialing') { productsId = 'trials_products_ids_select' }

    const products = document.getElementById(productsId).value

    let leads = ''
    const leadIdsSelect = document.getElementById('leads_ids_select')
    if (leadIdsSelect) { leads = leadIdsSelect.value }

    const params = `?statuses=${subscriptionStatuses}&products=${products}&recipients_type=${recipientsType}&leads_types=${leadsType}&leads=${leads}`
    const src = `/bullet/marketings/push_notifications/recipients_total${params}`
    recipientsTotalFrame.src = src
  }
}
