import { Controller } from '@hotwired/stimulus'

/*
  NOTICE:
  Use this controller for hide element after timeout:
  <span class="mr-3 text-green-400 animate-in fade-in duration-150"
        data-controller="helpers--timeout-hide"
        data-helpers--timeout-hide-timeout-value="5000">Successfully updated</span>
*/

export default class extends Controller {
  static values = {
    timeout: { type: Number, default: 1000 },
  }

  connect() {
    setTimeout(() => {
      this.element.classList.add('hidden')
    }, this.timeoutValue)
  }
}
