import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  createTarget(target, args = {}) {
    const element = args.element || 'div'
    const htmlOptions = args.htmlOptions || {}
    const data = args.data || {}
    const innerHTML = args.innerHTML || ''
    const DOMElement = document.createElement(element)

    Object.keys(data).forEach((identifier) => {
      DOMElement.setAttribute(`data-${identifier}`, data[identifier])
    })

    Object.keys(htmlOptions).forEach((options) => {
      DOMElement.setAttribute(options, htmlOptions[options])
    })

    DOMElement.setAttribute(`data-${this.identifier}-target`, `${target}`)
    DOMElement.innerHTML = innerHTML
    return DOMElement
  }

  /**
   * Get instance of remote controller
   * if element id not passed, will be eq to controller name
   * @param controller
   * @param id
   */
  connectRemote(controller, id = null) {
    const elId = id || controller
    const el = document.getElementById(elId)
    return this.application.getControllerForElementAndIdentifier(el, controller)
  }
}
