import { Controller } from '@hotwired/stimulus'
import API from 'libs/api'

export default class extends Controller {
  static targets = ['alert']

  async export(event) {
    await API.get(event.params.url)
    this.alertTarget.show()
  }

  show() {
    this.alertTarget.show()
  }
}
