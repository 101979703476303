import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'clear']

  connect() {
    if (this.inputTarget.value) {
      this.clearTarget.classList.remove('hidden');
    }
    this.inputTarget.addEventListener('input', this.handleInput.bind(this));
  }

  disconnect() {
    this.inputTarget.removeEventListener('input', this.handleInput.bind(this));
  }

  clear() {
    this.inputTarget.value = '';
    this.inputTarget.dispatchEvent(new Event('input'));
    this.clearTarget.classList.add('hidden');
  }

  handleInput() {
    if (this.inputTarget.value) {
      this.clearTarget.classList.remove('hidden');
    } else {
      this.clearTarget.classList.add('hidden');
    }
  }
}
