import { Controller } from '@hotwired/stimulus'
import { debounce } from 'lodash'
import { stringifyFormState, isEventChangesForm } from '../../../../libs/form-state'
import { FetchRequest } from '@rails/request.js'
import copyToClipboard from 'common/utils/copy'

export default class extends Controller {
  static targets = ['submit', 'toolbar']

  static values = {
    lockWindow: Boolean,
    checkBouncedEmailUrl: String,
    contentSearchUrl: String,
  }

  _initFormState = ''

  _handleFormChange = debounce(() => {
    if (this.element.getAttribute('aria-busy') === 'true') return

    const currentState = stringifyFormState(this.element)
    const isChanged = this._initFormState !== currentState

    this.submitTarget.disabled = !isChanged

    if (this.submitTarget.disabled) {
      if (this.submitTarget.innerText !== 'All changes saved!') {
        this.submitTarget.innerText = 'All changes saved!'
      }
    } else if (this.submitTarget.innerText !== 'Save changes') {
      this.submitTarget.innerText = 'Save changes'
    }
  }, 300)

  handleFormChange = e => {
    if (isEventChangesForm(e)) {
      this._handleFormChange()
    }
  }

  onDsFormElementInitialized(e) {
    if (e && e.target && e.target.name === '__skip-form-state-comparer') {
      return
    }

    this._initFormState = stringifyFormState(this.element)
  }

  connect() {
    this.onTurboSubmitStart = this.onTurboSubmitStart.bind(this)
    this.element.addEventListener('turbo:submit-start', this.onTurboSubmitStart)

    this.onTurboSubmitEnd = this.onTurboSubmitEnd.bind(this)
    this.element.addEventListener('turbo:submit-end', this.onTurboSubmitEnd)

    this.onDsFormElementInitialized = debounce(this.onDsFormElementInitialized, 600).bind(this)
    this.onDsFormElementInitialized()

    this.contentSearch = debounce(this.contentSearch, 200).bind(this)

    this.onDocumentScroll()

    this.onDocumentScroll = this.onDocumentScroll.bind(this)
    document.addEventListener('scroll', this.onDocumentScroll)

    if (this.lockWindowValue) {
      this.setupWindowLock()
    }
  }

  contentSearch(e) {
    new FetchRequest('post', this.contentSearchUrlValue, { responseKind: 'turbo-stream', body: JSON.stringify({ q: e.detail }) }).perform()
  }

  setupWindowLock() {
    window.onbeforeunload = () => (this.submitTarget.disabled ? undefined : true)
  }

  disconnect() {
    this.element.removeEventListener('turbo:submit-end', this.onTurboSubmitEnd)
    this.element.removeEventListener('turbo:submit-start', this.onTurboSubmitStart)

    document.removeEventListener('scroll', this.onDocumentScroll)

    if (this.sortable) {
      this.sortable.destroy();
    }
  }

  onDocumentScroll() {
    if (window.scrollY > 10) {
      this.toolbarTarget.classList.add('bg-white', 'border-b')
    } else {
      this.toolbarTarget.classList.remove('bg-white', 'border-b')
    }
  }

  onTurboSubmitStart() {
    this.submitTarget.disabled = true
    this.submitTarget.innerText = 'Saving...'
    this.element.setAttribute('aria-busy', 'true')
  }

  onTurboSubmitEnd(e) {
    if (!e.detail.success) {
      this.submitTarget.innerText = 'Save changes'
      this.submitTarget.disabled = false
      this.element.removeAttribute('aria-busy')
    }
  }

  copyEmail(e) {
    e.preventDefault()

    const container = document.getElementById('flash_messages')

    const message = document.createElement('div')
    message.classList.add('u-flash', 'u-flash-success')
    message.setAttribute('data-controller', 'alert')
    message.setAttribute('data-alert-timeout-value', '5000')
    message.setAttribute('data-action', 'click->alert#close')
    message.setAttribute('data-alert-target', 'alert')

    message.innerText = 'Email has been copied to your clipboard.'

    container.appendChild(message)

    copyToClipboard(e.currentTarget.dataset.email)
  }

  async runBouncedEmailCheck(e) {
    e.preventDefault()

    if (e.currentTarget.classList.contains('text-gray-500')) return

    e.currentTarget.classList.remove('text-primary')
    e.currentTarget.classList.add('text-gray-500')
    e.currentTarget.innerHTML = 'Checking...'

    const f = new FetchRequest('post', this.checkBouncedEmailUrlValue, { responseKind: 'turbo-stream' })

    await f.perform()
  }
}
