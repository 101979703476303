import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['descriptionLengthLeft']

  countDescriptionCharacters(e) {
    const { value } = e.target

    this.descriptionLengthLeftTarget.textContent = e.target.maxLength - value.length
  }
}
