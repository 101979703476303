import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.form = this.element.closest('form')
  }

  submit() {
    this.form.requestSubmit()
  }

  export() {
    const currentFilterParams = new URLSearchParams(window.location.search)
    const formUrl = new URL(this.form.action)
    formUrl.search = currentFilterParams.toString()
    this.form.action = formUrl.toString()
    this.submit()
  }
}
