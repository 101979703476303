import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'selectAll', 'items', 'listToolbox',
    'listToolboxToolsSelectedLabel', 'videoListDrawerTemplate', 'nonDeletableDeleteButton', 'deletableDeleteButton'
  ]

  selectAll(e) {
    // eslint-disable-next-line no-return-assign,no-param-reassign
    this.itemsControllers.forEach(x => x.setSelected(e.target.checked))
    if (this.selectedCounts() === 0 && e.target.checked === true) {
      return
    }
    this.setToolbox(e.target.checked)

    // eslint-disable-next-line max-len
    if (this.itemsControllers.filter(x => x.checkboxTarget.checked === true).filter(x => x.deletableValue === false).length !== 0) {
      this.nonDeletableDeleteButtonTarget.classList.remove('hidden')
      this.deletableDeleteButtonTarget.classList.add('hidden')
    } else {
      this.deletableDeleteButtonTarget.classList.remove('hidden')
      this.nonDeletableDeleteButtonTarget.classList.add('hidden')
    }
  }

  changeCheckbox(e) {
    if (e.target.checked && this.selectAllTarget.checked === false) {
      this.selectAllTarget.checked = true
      this.setToolbox(true)
    } else if (e.target.checked === false
      && this.itemsControllers.filter(x => x.checkboxTarget.checked === true).length === 0) {
      this.selectAllTarget.checked = false
      this.setToolbox(false)
    }

    // eslint-disable-next-line max-len
    if (this.itemsControllers.filter(x => x.checkboxTarget.checked === true).filter(x => x.deletableValue === false).length !== 0) {
      this.nonDeletableDeleteButtonTarget.classList.remove('hidden')
      this.deletableDeleteButtonTarget.classList.add('hidden')
    } else {
      this.deletableDeleteButtonTarget.classList.remove('hidden')
      this.nonDeletableDeleteButtonTarget.classList.add('hidden')
    }

    this.listToolboxToolsSelectedLabelTarget.innerHTML = this.selectedLabelText
  }

  createModal() {
    this.element.querySelectorAll('ds-modal').forEach(x => x.remove())

    const modal = document.createElement('ds-modal')
    modal.setAttribute('size', 'tiny')
    modal.setAttribute('position', 'right')
    modal.setAttribute('closable', 'true')
    modal.setAttribute('show-on-mount', 'true')

    modal.innerHTML = this.videoListDrawerTemplateTarget.innerHTML

    this.element.appendChild(modal)

    return modal
  }

  addTo(e) {
    e.preventDefault()

    this.createModal()

    const form = e.currentTarget.closest('form')
    form.querySelectorAll('input[name="video_ids[]"]').forEach(x => x.remove())

    if (e.currentTarget.dataset.videoId) {
      const id = document.createElement('input')
      id.setAttribute('name', 'video_ids[]')
      id.setAttribute('value', e.currentTarget.dataset.videoId)
      id.setAttribute('type', 'hidden')
      form.appendChild(id)
    } else {
      this.itemsControllers.filter(x => x.checkboxTarget.checked === true).forEach(x => {
        const id = document.createElement('input')
        id.setAttribute('name', 'video_ids[]')
        id.setAttribute('value', x.checkboxTarget.value)
        id.setAttribute('type', 'hidden')
        form.appendChild(id)
      })
    }

    form.requestSubmit()
  }

  destroyBatch(e) {
    e.preventDefault()

    const form = e.currentTarget.closest('form')

    form.querySelectorAll('input[name="video_ids[]"]').forEach(x => x.remove())

    this.itemsControllers.filter(x => x.checkboxTarget.checked === true).forEach(x => {
      const id = document.createElement('input')
      id.setAttribute('name', 'video_ids[]')
      id.setAttribute('value', x.checkboxTarget.value)
      id.setAttribute('type', 'hidden')
      form.appendChild(id)
    })

    this.selectAllTarget.checked = false
    this.setToolbox(false)
    form.requestSubmit()
  }

  setToolbox(visible) {
    if (visible) {
      this.listToolboxTarget.querySelector('input[type="checkbox"]').checked = true
      this.listToolboxTarget.classList.add('bg-gray-100')
      this.listToolboxToolsSelectedLabelTarget.innerHTML = this.selectedLabelText
    } else {
      this.listToolboxTarget.classList.remove('bg-gray-100')
    }
  }

  selectedCounts() {
    return this.itemsControllers.filter(x => x.checkboxTarget.checked === true).length
  }

  get selectedLabelText() {
    const count = this.selectedCounts()

    return `${count} ITEM${count > 1 ? 'S' : ''} SELECTED`
  }

  get itemsControllers() {
    // eslint-disable-next-line max-len
    return this.itemsTargets.map(x => this.application.getControllerForElementAndIdentifier(x, 'contents--videos--list-item'))
  }
}
