import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['link']

  static values = { url: String }

  async changeToken() {
    this.linkTarget.innerText = 'Please wait...'
    this.linkTarget.classList.remove('cursor-pointer')
    const url = new URL(this.urlValue)
    await fetch(url)
      .then(response => response.text())
      .then(html => {
        // eslint-disable-next-line no-undef
        Turbo.renderStreamMessage(html)
      })
  }
}
