import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['items']

  connect() {
    setTimeout(() => this.enqueue())
  }

  enqueue() {
    this.itemsControllers.forEach(controller => {
      if (!controller.startUploadValue) {
        controller.upload()
      }
    })
  }

  get itemsControllers() {
    return this.itemsTargets.map(x => this.application.getControllerForElementAndIdentifier(x, 'video-uploader-item'))
  }
}
