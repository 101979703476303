import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ['previewForm', 'landingEditor', 'previewContainer', 'previewBtn']

  static values = {
    previewUrl: String
  }

  connect() {
    window.addEventListener('message', this.onMessage);
  }

  disconnect() {
    window.removeEventListener('message', this.onMessage);
  }

  onMessage = event => {
    if (event.data.type === 'setHeightTo') {
      const padding = 50
      this.previewContainerTarget.querySelector('iframe').style.height = `${event.data.value + padding}px`
    }
  }

  preview(e) {
    e.preventDefault()

    if (this.previewBtnTarget.innerText === 'Back to edit') {
      this.landingEditorTarget.classList.remove('hidden')
      this.previewContainerTarget.classList.add('hidden')
      this.previewBtnTarget.textContent = 'Preview'
      return
    }

    const heading = document.querySelector('#funneled_attributes_heading').value
    const body = document.querySelector('#funneled_attributes_body').value
    const image = document.querySelector('#funneled_attributes_header_image_url > img').getAttribute('src')
    e.currentTarget.setAttribute('loading', 'true')

    const r = new FetchRequest('post', this.previewUrlValue, { responseKind: 'turbo-stream', body: JSON.stringify({ heading, body, header_image_url: image }) })

    r.perform().then(() => {
      this.landingEditorTarget.classList.add('hidden')
      this.previewContainerTarget.classList.remove('hidden')
      this.previewBtnTarget.removeAttribute('loading')

      this.previewBtnTarget.textContent = 'Back to edit'
    })
  }
}
