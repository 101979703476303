import _debug from 'debug'
import API from 'libs/api'
import { STATUS } from '../constants'
import { EventBus } from '../EventBus'
import { retry, wait } from '../utils/retry'

const fakeProgress = async (callback) => {
  for (let index = 1; index < 6; index++) {
    await wait(100)
    callback(index*20)
  }
  return true
}

export class DropboxItem extends EventBus {
  status = STATUS.INITIALIZED
  progress = 0
  meta = undefined
  debug = undefined
  loader = undefined

  constructor(file) {
    super()

    this.meta = {
      filename: file.name,
      size: 0,
    }

    this.debug = _debug(`upload:[${file.name}]`)
    this.debug('Initialized')
  }

  async setMeta(data) {
    this.meta = {
      ...this.meta,
      ...data
    }

    this.debug('Metadata updated', this.meta)
  }

  cancel = () => {
    this.debug('Upload has been canceled')
    this.emit('cancel')
    window.Sentry?.captureMessage('Upload has been canceled', { extra: this.meta })

    if (this.status === STATUS.INITIALIZED) {
      this.status = STATUS.CANCELED
    }

    this.status = STATUS.CANCELED

    this.loader?.resolve()
  }


  getEncodingData = async () => {
    const { id, type } = this.meta
    const { data } = await retry(() => API.post(`/bullet/contents/uploads/${id}/start`, { type }))
    return data
  }

  upload = () => {

    const promise = fakeProgress(progress => this.emit('progress', progress))
      .then(() => this.getEncodingData())
      .then(() => {
        this.status = STATUS.UPLOADED
        this.emit('success', this.meta)
      })

    this.loader = { promise }
  }
}
