export const stringifyFormState = (form) => {
  const data = new FormData(form)
  const dataObj = {}
  const hashRegex = /([a-zA-Z_]+)\[\]\[([a-zA-Z_]+)\]/; // key[][id]
  for (const [key, value] of data.entries()) {
    if (key !== '__skip-form-state-comparer') {
      const isArray = key.endsWith('[]') // key[]
      const isHash = key.match(hashRegex)
      if (isHash) {
        dataObj[key] = dataObj[key] || []
        dataObj[key].push(value)
      } else if (isArray) {
        const formattedKey = key.replace('[]', '')
        dataObj[formattedKey] = dataObj[formattedKey] || []
        dataObj[formattedKey].push(value)
      } else {
        dataObj[key] = value
      }
    }
  }

  return JSON.stringify(dataObj)
}

export const isEventChangesForm = (e) => {
  return e === 'internal' || (e.target && e.target.name && e.target.name !== '__skip-form-state-comparer')
}
