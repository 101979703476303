import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button', 'code']

  showCode() {
    this.buttonTarget.remove()
    this.codeTarget.classList.remove('hidden')
  }
}
