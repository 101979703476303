import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'clearFilterButton', 'filterForm', 'filterSelect', 'sortSelect',
    'searchInput'
  ]

  connect() {
  }

  submitForm() {
    window.requestAnimationFrame(() => { this.filterFormTarget.requestSubmit() })
  }

  clearFilter() {
    this.filterSelectTarget.value = []
    this.submitForm()
  }
}
