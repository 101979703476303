<template>
  <b-date-input
    :value="fromUnix(value)"
    :placeholder="placeholder"
    :disabled="disabled"
    :timezone="timezone"
    range
    :dropdown-position="dropdownPosition"
    @apply="applyDates"
    @input="changeCurrentDate"
  >
    <template #default="{ toggleDropdown }">
      <div class="bg-white border border-gray-300 dark:bg-gray-800 dark:border-gray-700">
        <section
          class="b-date-picker flex items-stretch border-b border-gray-300 dark:border-gray-700"
        >
          <b-calendar-quick-select
            v-if="withQuickOptions"
            class="border-r border-gray-300 dark:border-gray-700"
            :style="{ width: '192px' }"
            :timezone="timezone"
            range
            :past="quickPast"
            :future="quickFuture"
            :options="quickCustomOptions"
            @input="changeCurrentDate"
          />
          <b-calendar
            :value="currentValue"
            :style="{ width: '328px' }"
            :timezone="timezone"
            range
            @input="changeCurrentDate"
          />
        </section>
        <section class="flex items-center justify-end p-6">
          <b-button
            type="button"
            variant="fill"
            color="primary"
            class="mr-2"
            @click="applyDates(toggleDropdown)"
          >
            Apply
          </b-button>
          <b-button
            type="button"
            @click="clearDates(toggleDropdown)"
          >
            Clear Dates
          </b-button>
        </section>
      </div>
    </template>
  </b-date-input>
</template>

<script>
import isEqual from 'lodash/isEqual'
import dayjs from 'dayjs'
import UTC from 'dayjs/plugin/utc'
import Timezone from 'dayjs/plugin/timezone'
import { fromUnix, toUnix } from 'common/utils/date'
import BButton from 'common/components/BButton'
import BDateInput from 'common/components/BDateInput'
import BCalendar from 'common/components/BCalendar'
import BCalendarQuickSelect from 'common/components/BCalendarQuickSelect'

dayjs.extend(UTC)
dayjs.extend(Timezone)

export default {
  name: 'BDateRangePicker',
  components: {
    BButton,
    BDateInput,
    BCalendar,
    BCalendarQuickSelect,
  },
  props: {
    value: {
      type: [Number, Array],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Select date...',
    },
    withQuickOptions: {
      type: Boolean,
      defalut: false,
    },
    quickPast: {
      type: Boolean,
      default: true,
    },
    quickFuture: {
      type: Boolean,
      default: false,
    },
    quickCustomOptions: {
      type: Array,
      default: null,
    },
    timezone: {
      type: String,
      default: dayjs.tz.guess(),
    },
    dropdownPosition: {
      type: String,
      default: 'down-right',
    },
  },
  data: () => ({
    currentValue: null,
  }),
  watch: {
    value: {
      immediate: true,
      handler() {
        if (!this.value) return

        this.currentValue = this.fromUnix(this.value)
      },
    },
  },
  methods: {
    fromUnix,
    toUnix,
    applyDates(cb) {
      const prevValue = this.value?.map(val => parseInt(val)) || null
      const newValue = this.toUnix(this.currentValue, this.timezone)

      if (isEqual(prevValue, newValue)) return

      this.$emit('input', newValue)

      if (!cb) return

      cb()
    },
    clearDates(cb) {
      if (this.currentValue) {
        this.currentValue = null
      }

      this.$emit('input', null)

      if (!cb) return

      cb()
    },
    changeCurrentDate(val) {
      if (!val) {
        this.clearDates()
        return
      }

      this.currentValue = val
    },
  },
}
</script>

<style scoped>
.b-date-picker {
  height: 340px;
}
</style>
