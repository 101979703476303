import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['selectInput', 'customInput']

  toggleInput() {
    const isCustomSelected = this.selectInputTarget.value && this.selectInputTarget.value[0] === 'custom'
    this.customInputTarget.classList.toggle('hidden', !isCustomSelected)
    this.customInputTarget.value = ''
  }
}
