import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', event => {
      if (event.target.closest('.help-trigger')) {
        document.querySelector('[data-intercom="appsHelp"]').click()
      }
    })
  }
}
