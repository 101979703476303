import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'startDate', 'endDate',
    'titleInput', 'descriptionInput', 'imgInput', 'imgError',
    'previewTitle', 'previewDescription', 'previewImg', 'previewDates',
    'previewPlaylistItems', 'previewPlaylistItemsLink'
  ]

  formatDate(timestamp, endTimestamp) {
    const startDate = new Date(timestamp * 1000)
    const endDate = endTimestamp ? new Date(endTimestamp * 1000) : null
    const timeZone = this.data.get('storeTimeZone')

    const startMonth = startDate.toLocaleString('en-US', { month: 'long', timeZone })
    const startDay = startDate.toLocaleString('en-US', { day: '2-digit', timeZone })

    let formattedDate = `${startMonth} ${startDay}`

    if (endDate) {
      const endMonth = endDate.toLocaleString('en-US', { month: 'long', timeZone })
      const endDay = endDate.toLocaleString('en-US', { day: '2-digit', timeZone })

      formattedDate += ` → ${endMonth} ${endDay}`
    }

    return formattedDate
  }

  timeframeChange(e) {
    this.startDateTarget.value = e.target.start === 0 ? '' : e.target.start
    this.startDateTarget.dispatchEvent(new Event('input'))

    this.endDateTarget.value = e.target.end === 0 ? '' : e.target.end
    this.endDateTarget.dispatchEvent(new Event('input'))

    if (e.target.start === 0) {
      this.previewDatesTarget.textContent = this.previewDatesTarget.dataset.defaultValue
    } else {
      const formattedDate = this.formatDate(e.target.start, e.target.end)
      const [month, day] = formattedDate.split(' ')

      this.previewDatesTarget.textContent = formattedDate
    }
  }

  titleChanged(e) {
    let value

    if (e.target.value.length === 0) {
      value = this.previewTitleTarget.dataset.defaultValue
    } else {
      value = e.target.value
    }

    this.previewTitleTarget.textContent = value
  }

  bodyChanged(e) {
    let value

    if (e.target.value.length === 0) {
      value = this.previewDescriptionTarget.dataset.defaultValue
    } else {
      value = e.target.value
    }

    this.previewDescriptionTarget.innerHTML = value
  }

  coverChanged(e) {
    this.previewImgTarget.src = e.detail[0].uploadURL
    this.imgErrorTarget.classList.add('hidden')
  }

  updateCollectionSelectOptions(e) {
    const url = new URL(e.target.dataset.collectionSelectUrl)
    url.searchParams.set('format', 'turbo_stream')
    url.searchParams.set('selected_collection_id', e.target.value)

    fetch(url)
      .then(response => response.text())
      .then(html => {
        window.Turbo.renderStreamMessage(html)
      })
  }

  updateCollectionVideos(e) {
    const url = new URL(e.target.dataset.collectionVideosUrl)

    url.searchParams.set('format', 'turbo_stream')
    url.searchParams.set('selected_collection_id', e.target.value)

    fetch(url)
      .then(response => response.text())
      .then(html => {
        window.Turbo.renderStreamMessage(html)
      })
  }

  togglePreviewPlaylistItems(e) {
    e.preventDefault()

    this.previewPlaylistItemsTarget.classList.toggle('hidden')

    if (this.previewPlaylistItemsTarget.classList.contains('hidden')) {
      this.previewPlaylistItemsLinkTarget.text = 'Show all'
    } else {
      this.previewPlaylistItemsLinkTarget.text = 'Hide all'
    }
  }
}
