import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  openItemsManagingModal(event) {
    const turboFrameFormURL = event.target.closest('[data-items-form-src]')?.dataset?.itemsFormSrc

    if (turboFrameFormURL) {
      const modalDiv = document.createElement('div')

      modalDiv.innerHTML = `
        <ds-modal position="right" size="auto" show-on-mount="true" no-background="true" destroy-on-close="true">
          <turbo-frame id="modal-items-form" src="${turboFrameFormURL}">
            <div class="flex justify-center items-center h-full bg-white" style="width: 467px;">
              <ds-spinner size="m"></ds-spinner>
            </div>
          </turbo-frame>
        </ds-modal>
      `

      document.body.appendChild(modalDiv.firstElementChild)
    }
  }

  // Expand day items
  expandDayItems(event) {
    event.stopPropagation()
    const container = event.target.closest('td')

    container.querySelector('.expander').classList.add('hidden')
    container.querySelector('.expandable-container').classList.remove('hidden');
  }

  collapseDayItems(event) {
    event.stopPropagation()
    const container = event.target.closest('td')

    container.querySelector('.expander').classList.remove('hidden')
    container.querySelector('.expandable-container').classList.add('hidden');
  }
}
