import { Controller } from '@hotwired/stimulus'
import { truncate } from 'lodash'

export default class extends Controller {
  connect() {
    // Step 2, additional logic for «App name»
    const appNameInput = document.getElementById('app-name-input')

    if (appNameInput) {
      appNameInput.addEventListener('input', event => {
        document.getElementById('svg-app-name').innerHTML = truncate(event.target.value, { length: 10 })
      })

      appNameInput.addEventListener('change', event => {
        event.target.form.requestSubmit();
      })
    }

    // Enrich «Next step» buttons with additional logic
    const form = this.element
    const previewerTurboFrame = document.getElementById('previewer')
    const nextStepButton = this.element.querySelector('[type="submit"]')

    if (nextStepButton) {
      nextStepButton.addEventListener('click', (event) => {
        form.addEventListener('turbo:submit-end', (event) => {
          const { href } = nextStepButton.dataset
          previewerTurboFrame.src = href
          window.history.replaceState({}, document.title, href)
        })
      })
    }
  }
}
