import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['placeholderSelectedItemsCounter', 'placeholderItemPreview', 'placeholderDefault']

  static values = {
    selectedCount: { type: Number, default: 0 },
    multiple: Boolean,
  }

  setPlaceholderItemsCounter(count) {
    if (this.multipleValue === false) {
      return
    }

    if (this.hasPlaceholderSelectedItemsCounterTarget === false) {
      return
    }

    if (count === 0) {
      this.placeholderSelectedItemsCounterTarget.classList.add('hidden')
      this.placeholderDefaultTarget.classList.remove('hidden')
    } else {
      this.placeholderSelectedItemsCounterTarget.innerHTML = count > 1 ? `${count} items selected` : '1 item selected'
      this.placeholderSelectedItemsCounterTarget.classList.remove('hidden')

      this.placeholderDefaultTarget.classList.add('hidden')
    }
  }

  setPlaceholderItemPreview(previewHTML) {
    if (this.multipleValue) {
      return
    }

    if (this.hasPlaceholderItemPreviewTarget === false) {
      return
    }

    if (previewHTML === '' || previewHTML === null || previewHTML === undefined) {
      this.placeholderItemPreviewTarget.classList.add('hidden')
      this.placeholderDefaultTarget.classList.remove('hidden')
    }

    this.placeholderItemPreviewTarget.classList.remove('hidden')
    this.placeholderDefaultTarget.classList.add('hidden')

    this.placeholderItemPreviewTarget.innerHTML = previewHTML
  }

  updatePlaceholderState(selectedItemsCount) {
    this.selectedCountValue = selectedItemsCount

    if (this.multipleValue) {
      this.setPlaceholderItemsCounter(this.selectedCountValue)
    } else {
      this.listController?.selectedItems?.forEach(item => {
        this.setPlaceholderItemPreview(item.previewTarget.innerHTML)
      })
    }
  }
}
