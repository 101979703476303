import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'errorText']

  connect() {
    this.validate()
  }

  validate() {
    const hasInvalidCharacters = this.inputTarget.value.match(/[\p{Emoji_Presentation}\u2713]/gu)

    if (hasInvalidCharacters) {
      this.inputTarget.classList.add('border', 'border-red-600')
      this.errorTextTarget.classList.remove('hidden')
    } else {
      this.inputTarget.classList.remove('border', 'border-red-600')
      this.errorTextTarget.classList.add('hidden')
    }
  }
}
