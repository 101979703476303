import { Controller } from '@hotwired/stimulus'

// controller for table of contents highlighting (toc)

// should be attached on the parent element which includes toc
// navigation target should be set on navigation container, e.g. ul

export default class extends Controller {
  static targets = ['navigation']

  activeSectionId = null

  connect() {
    this.handleHashChange = () => { this.highlightTocElement(window.location.hash) }
    window.addEventListener('hashchange', this.handleHashChange)

    this.observer = new IntersectionObserver(
      this.scrollCallback.bind(this),
      {
        rootMargin: '-40% 0px -50% 0%',
        threshold: 0
      }
    )

    this.articleSections.forEach(s => this.observer.observe(s))
  }

  disconnect() {
    this.observer?.disconnect()
    window.removeEventListener('hashchange', this.handleHashChange)
  }

  scrollCallback(entries) {
    if (entries.length > 1) return

    if (entries[0].isIntersecting) {
      const id = entries[0].target.getAttribute('aria-labelledby')
      this.highlightTocElement(`#${id}`)
    }
  }

  highlightTocElement(href) {
    const collection = Array.from(this.navigationElements)

    collection.forEach(link => {
      link.parentElement.classList.toggle('active', link.getAttribute('href') === href)
    })
  }

  get navigationElements() {
    return this.navigationTarget.getElementsByTagName('a')
  }

  get articleSections() {
    return this.element.querySelectorAll('section[aria-labelledby]')
  }
}
