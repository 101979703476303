/* eslint-disable no-param-reassign */
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'clear']

  static values = {
    filterParam: String
  }

  triggerClearFilter() {
    const clearFilter = new CustomEvent('clear-filter', {
      detail: { filterParam: this.filterParamValue },
    })

    window.dispatchEvent(clearFilter)
  }

  clearButton() {
    if (!this.hasClearTarget) return

    if (this.checkboxTargets.find(input => input.checked)) {
      this.clearTarget.classList.toggle('hidden-item', false)
    } else {
      this.clearTarget.classList.toggle('hidden-item', true)
    }
  }

  clearCheckboxes(event) {
    const { filterParam } = event.detail

    if (this.filterParamValue === filterParam) {
      this.clearTarget.classList.toggle('hidden-item', true)
    }

    this.checkboxTargets.forEach(input => {
      if (this.filterParamValue === filterParam) {
        input.checked = false
      }
    })

    this.element.closest('form').requestSubmit()
  }
}
