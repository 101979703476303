import { Controller } from '@hotwired/stimulus'
import * as Sentry from '@sentry/browser'

export default class extends Controller {
  static targets = ['stripeMessage']

  static values = {
    publicKey: String,
    clientSecret: String,
    action: String
  }

  connect() {
    this.dispatchStripeEvent('initialization:started')

    // eslint-disable-next-line no-undef
    this.stripe = Stripe(this.publicKeyValue, { locale: 'en' })

    const options = {
      clientSecret: this.clientSecretValue,
      fonts: [{
        cssSrc: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&amp;display=swap'
      }],
      appearance: {
        theme: 'stripe',
        labels: 'above',
        variables: {
          fontFamily: '"Inter", Helvetica, Arial, sans-serif',
          fontSizeSm: '1.0rem',
          fontSmooth: 'auto',
          colorDanger: '#f63c3c',
          colorText: '#1a202c',
          colorTextPlaceholder: '#a0afc0'
        },
        rules: {
          '.Label': {
            marginBottom: '0.5rem'
          },
          '.Error': {
            fontSize: '0.875rem'
          },
          '.Input--invalid': {
            boxShadow: '0'
          }
        }
      },
    }

    this.elements = this.stripe.elements(options)
    this.paymentElement = this.elements.create('payment', {
      fields: {
        billingDetails: 'auto',
      },
      terms: { card: 'never' },
    })

    this.paymentElement.on('ready', () => { this.onReady() })
    this.paymentElement.on('loaderror', error => { this.onError(error) })
    this.paymentElement.mount('#payment-element')
  }

  disconnect() {
    document.removeEventListener('stripe:save-card', this.saveCard)
  }

  onReady() {
    this.saveCard = e => this.onSaveCard(e)
    document.addEventListener('stripe:save-card', this.saveCard)

    this.dispatchStripeEvent('initialization:end')
  }

  onError(error) {
    Sentry.captureException(error)
  }

  onSaveCard(event) {
    if (this.paymentMethod) {
      this.dispatchStripeEvent('payment-method-received', { paymentMethod: this.paymentMethod })
      return
    }

    event.stopImmediatePropagation()
    event.preventDefault()

    const { elements } = this
    this.stripe.confirmSetup({ elements, redirect: 'if_required' })
      .then(result => {
        if (result.error) {
          const { error } = result
          if (error.type === 'card_error' || error.type === 'validation_error') {
            this.stripeError = error.message
          } else {
            this.stripeError = 'An unexpected error occured.'
          }
          this.showMessage(error.message)
          this.dispatchStripeEvent('payment-error-received', { error })
        } else {
          this.stripeError = null
          this.paymentMethod = result.setupIntent.payment_method
          this.dispatchStripeEvent('payment-method-received', { paymentMethod: this.paymentMethod })
        }
      }).catch(error => {
        this.showMessage(error.message)
      })
  }

  showMessage(messageText) {
    this.stripeMessageTarget.classList.remove('hidden')
    this.stripeMessageTarget.textContent = messageText

    setTimeout(() => {
      this.stripeMessageTarget.classList.add('hidden')
      this.stripeMessageTarget.textContent = ''
    }, 4000)
  }

  dispatchStripeEvent(eventName, eventData) {
    const data = { detail: eventData || {} }
    const event = new CustomEvent(`stripe:${eventName}`, data)
    document.dispatchEvent(event)
  }
}
