import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['section', 'sectionTab', 'step']

  toggleStepBody(e) {
    // Prevent clicks on the "Learn more" link from togglign the step body
    if (e.target.tagName === 'A') return;

    // Toggle the 'step-active' class on the step identifier provided in params
    // we're not using e.currentTarget because this method should be callable from outside
    this.stepTargets.forEach((step) => {
      if (step.dataset.stepIdentifier === e.params.identifier) {
        step.classList.toggle('step-active')
      } else {
        step.classList.remove('step-active')
      }
    })
  }

  selectSection(e) {
    this.sectionTargets.forEach((section) => {
      if (parseInt(section.dataset.sectionIdentifier) === parseInt(e.params.identifier)) {
        section.classList.remove('hidden')
      } else {
        section.classList.add('hidden')
      }
    })

    this.sectionTabTargets.forEach((sectionTab) => {
      if (parseInt(sectionTab.dataset.sectionTabIdentifier) === parseInt(e.params.identifier)) {
        sectionTab.classList.add('bg-ds-subtle')
        sectionTab.classList.remove('bg-white')
      } else {
        sectionTab.classList.remove('bg-ds-subtle')
        sectionTab.classList.add('bg-white')
      }
    })
  }

  skipStep(e) {
    // Later on we could make this open the next step additionallity
    // Would need to check if the next step is disabled or completed
    // Also would need to decide if it jumps to the next section or not
    this.toggleStepBody(e)
  }
}
