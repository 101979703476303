/* eslint-disable no-param-reassign */
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['warningHint', 'checkbox']

  previouslyChecked = false

  connect() {
    this.previouslyChecked = this.checkboxTarget.checked
  }

  handleDurationChange(event) {
    if (event.target.value === '365') {
      this.disableCheckbox()
    } else {
      this.enableCheckbox()
    }
  }

  disableCheckbox() {
    this.previouslyChecked = this.checkboxTarget.checked
    this.warningHintTarget.classList.remove('hidden')
    this.checkboxTarget.disabled = true
    this.checkboxTarget.checked = false
  }

  enableCheckbox() {
    this.warningHintTarget.classList.add('hidden')
    this.checkboxTarget.disabled = false
    this.checkboxTarget.checked = this.previouslyChecked
  }
}
