import { Controller } from '@hotwired/stimulus'
import copyToClipboard from 'copy-to-clipboard'

export default class extends Controller {
    static values = {
      waitlistUrl: String
    }

  connect() {
    this.alignPadding()
  }

  copyUrl(e) {
    e.preventDefault()

    this.createFlashMessage()

    copyToClipboard(e.currentTarget.value)
    window.posthog.capture('onboarding_checklist:copy_website_url_click')
    window.amplitude.getInstance().logEvent('[Onboarding Checklist] Click on copy URL')
  }

  copyWaitlistUrl(e) {
    e.preventDefault()

    this.createFlashMessage()

    copyToClipboard(this.waitlistUrlValue)

    window.amplitude.getInstance().logEvent('[Onboarding Checklist] Click on copy waitlist URL')
  }

  createFlashMessage() {
    const container = document.getElementById('flash_messages')

    const message = document.createElement('div')
    message.classList.add('u-flash', 'u-flash-success')
    message.setAttribute('data-controller', 'alert')
    message.setAttribute('data-alert-timeout-value', '5000')
    message.setAttribute('data-action', 'click->alert#close')
    message.setAttribute('data-alert-target', 'alert')

    message.innerText = 'URL has been copied to your clipboard.'

    container.appendChild(message)
  }

  alignPadding() {
    if (document.querySelector('#bullet-container').classList.contains('lg:pr-80')) {
      document.querySelector('#bullet-container').classList.remove('lg:pr-80')
    }
  }
}
