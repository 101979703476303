import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'field', 'clearSearchIcon']

  clear(event) {
    event.preventDefault()

    this.fieldTarget.value = ''
    this.formTarget.requestSubmit()
    this.clearSearchIconTarget.classList.add('invisible')
  }

  toggleClearIcon(event) {
    if (event.target.value.length === 0) {
      this.clearSearchIconTarget.classList.add('invisible')
    } else {
      this.clearSearchIconTarget.classList.remove('invisible')
    }
  }
}
