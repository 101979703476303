import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  static values = {
    url: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    }
  }

  objectToGetParams = object => {
    const params = Object.entries(object)
      .filter(([, value]) => value !== undefined && value !== null)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)

    return params.length > 0 ? `?${params.join('&')}` : ''
  }

  getBoxPositionOnWindowCenter = (width, height) => ({
    left: window.outerWidth / 2 + (window.screenX || window.screenLeft || 0) - width / 2,
    top: window.outerHeight / 2 + (window.screenY || window.screenTop || 0) - height / 2,
  });

  isMobileOrTablet = () => /(android|iphone|ipad|mobile)/i.test(navigator.userAgent)

  copyUrl = () => {
    this.inputTarget.select()
    document.execCommand('copy')
  }

  windowOpen = (url, { height = 400, width = 550, ...configRest }) => {
    const config = {
      height,
      width,
      location: 'no',
      toolbar: 'no',
      status: 'no',
      directories: 'no',
      menubar: 'no',
      scrollbars: 'yes',
      resizable: 'no',
      centerscreen: 'yes',
      chrome: 'yes',
      ...this.getBoxPositionOnWindowCenter(width, height),
      ...configRest,
    }
    const shareDialog = window.open(
      url,
      '',
      Object.keys(config)
        .map(key => `${key}=${config[key]}`)
        .join(', '),
    )
    return shareDialog
  }

  shareInSocials = (event, link, width = 550, height = 400) => {
    event.preventDefault()
    this.windowOpen(link, { height, width })
  }

  shareInFacebook = event => {
    const link = `https://www.facebook.com/sharer/sharer.php${this.objectToGetParams({ u: this.urlValue })}`
    this.shareInSocials(event, link)
  }

  shareInTwitter = event => {
    const link = `https://twitter.com/intent/tweet${this.objectToGetParams({ url: this.urlValue, text: this.titleValue })}`
    this.shareInSocials(event, link)
  }

  shareInPinterest = event => {
    const link = `https://pinterest.com/pin/create/button/${this.objectToGetParams({ url: this.urlValue, media: this.imageValue, description: this.titleValue })}`
    this.shareInSocials(event, link, 1000, 730)
  }

  shareInLinkedin = event => {
    const link = `https://linkedin.com/shareArticle${this.objectToGetParams({ url: this.urlValue, mini: 'true', title: this.titleValue })}`
    this.shareInSocials(event, link, 750, 600)
  }

  shareInTelegram = event => {
    const link = `https://telegram.me/share/url${this.objectToGetParams({ url: this.urlValue, text: this.titleValue })}`
    this.shareInSocials(event, link)
  }

  shareInWhatsapp = event => {
    const text = this.titleValue ? `${this.titleValue}:: ${this.urlValue}` : this.urlValue
    const link = `https://${this.isMobileOrTablet() ? 'api' : 'web'}.whatsapp.com/send${this.objectToGetParams({ text })}`
    this.shareInSocials(event, link)
  }
}
