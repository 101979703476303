import { Controller } from '@hotwired/stimulus'
import algoliasearch from 'algoliasearch/lite'
import Vue from 'vue'
import BulletSearch from 'bullet/components/BulletSearch'
import API from 'libs/api'
import BigArrowIcon from 'assets/icons/arrow-right-big.svg?inline'
import UsersIcon from 'assets/icons/users.svg?inline'
import FolderIcon from 'assets/icons/folder.svg?inline'

const ALGOLIA_KEYS = ['0T560JKJO0', '3fc08f1cc55057528f1b16680692045c']

export default class extends Controller {
  static targets = ['container']

  static values = {
    role: String,
  }

  connect() {
    this.index = algoliasearch(...ALGOLIA_KEYS).initIndex(process.env.FRONTEND_BUILD === 'production' ? 'search_production' : 'search')

    document.addEventListener('keydown', this.handleKeypress)
  }

  disconnect() {
    document.removeEventListener('keydown', this.handleKeypress)
  }

  handleKeypress = event => {
    const { key, metaKey, ctrlKey } = event

    if (key === 'k' && (metaKey || ctrlKey)) {
      event.preventDefault()
      this.show()
    }
  }

  show() {
    const div = document.createElement('div')
    const container = this.containerTarget

    container.appendChild(div)

    const widget = new Vue({
      render: h => h(BulletSearch, {
        props: {
          role: this.element.dataset.role,
          providers: [
            {
              key: 'algolia',
              label: 'Navigation',
              icon: BigArrowIcon,
              request: query => this.index
                .search(query)
                .then(({ hits }) => Promise.resolve(['algolia', hits])),
            },
            {
              key: 'user',
              label: 'Users',
              icon: UsersIcon,
              mapResponse: data => {
                const provider = data.type === 'User' ? 'user' : 'content'
                return { ...data, provider }
              },
              request: query => API
                .get('/bullet/search', { params: { query } })
                .then(({ data }) => Promise.resolve(['user', data])),
            },
            {
              key: 'content',
              label: 'Content',
              icon: FolderIcon,
            },
          ],
        },
        on: {
          select(item) {
            if (item.provider === 'articles') {
              window.open(item.url, '_blank')
            } else {
              document.location.href = item.url
            }
          },
          close() {
            widget.$destroy()
            container.innerHTML = ''
          },
        },
      }),
    })
    widget.$mount(div)
  }
}
