<template>
  <b-dropdown
    :close-on-content-click="false"
    :dropdown-position="dropdownPosition"
    @close="onDropdownClose"
  >
    <template #control="{ toggleDropdown }">
      <button
        class="flex items-center py-2 pl-3 pr-2 border rounded border-gray-300 focus:border-primary dark:bg-gray-800 dark:text-white dark:border-gray-700 transition-all duration-200 overflow-hidden"
        :class="{
          'border-gray-300': !hasErrors,
          'border-red-500': hasErrors,
          'text-gray-900 bg-white': !disabled,
          'text-gray-500 bg-gray-100': disabled,
        }"
        type="button"
        :style="{ 'min-width': '280px' }"
        :disabled="disabled"
        @click="toggleDropdown"
      >
        <svg-sprite-icon
          class="flex-0 text-gray-500 pointer-events-none mr-2"
          :width="24"
          :height="24"
          :icon="require('assets/icons/calendar.svg')"
        />
        <div
          class="flex-1 mr-auto text-base text-left focus:border-primary truncate"
          :class="{
            'text-gray-900': !hasErrors,
            'text-red-500': hasErrors,
            'cursor-pointer': !disabled,
            'cursor-default': disabled,
          }"
        >
          <template v-if="!hasValue">
            {{ placeholder }}
          </template>
          <template v-else>
            {{ inputValue }}
          </template>
        </div>
        <button
          v-if="hasValue"
          class="flex-0 w-6 h-6 ml-2 text-gray-500"
          :class="{ 'cursor-pointer': !disabled, 'cursor-default': disabled }"
          type="button"
          :disabled="disabled"
          @click.stop="onClickClear"
        >
          <svg-sprite-icon :icon="require('assets/icons/close.svg')" />
        </button>
      </button>
    </template>
    <template #default="{ toggleDropdown }">
      <slot
        :value="value"
        :change-value="onChangeValue"
        :toggle-dropdown="toggleDropdown"
      />
    </template>
  </b-dropdown>
</template>

<script>
import dayjs from 'dayjs'
import UTC from 'dayjs/plugin/utc'
import AdvancedFormat from 'dayjs/plugin/advancedFormat'
import Timezone from 'dayjs/plugin/timezone'
import BDropdown from 'common/components/BDropdown'
import SvgSpriteIcon from 'common/components/SvgSpriteIcon'

dayjs.extend(UTC)
dayjs.extend(AdvancedFormat)
dayjs.extend(Timezone)

export default {
  name: 'BDateInput',
  components: {
    BDropdown,
    SvgSpriteIcon,
  },
  props: {
    value: {
      type: [Number, Date, Array],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Select Date',
    },
    hasErrors: {
      type: Boolean,
      default: false,
    },
    withTime: {
      type: Boolean,
      default: false,
    },
    timezone: {
      type: String,
      default: dayjs.tz.guess(),
    },
    dropdownPosition: BDropdown.props.dropdownPosition,
  },
  computed: {
    hasValue() {
      const { range, value } = this

      if (range) {
        return value?.length > 0
      }

      return !!value
    },
    inputValue() {
      const { value } = this

      if (!value) return ''

      if (Array.isArray(value)) {
        return this.value.map(d => this.formatDate(d)).join(' – ')
      }

      return this.formatDate(this.value)
    },
  },
  methods: {
    formatDate(date) {
      const { withTime, range, timezone } = this
      const dateTemplate = withTime && !range
        ? 'ddd, MMM D, YYYY hh:mma (z)'
        : 'ddd, MMM D, YYYY'

      return this.insertDateDelimeter(dayjs(date).tz(timezone).format(dateTemplate))
    },
    insertDateDelimeter(date) {
      return date.replace(/(^.+\d{4})(\s)(.+$)/, '$1 at $3')
    },
    onChangeValue(val) {
      this.$emit('input', val)
    },
    onClickClear() {
      if (this.disabled) return

      this.$emit('input', null)
    },
    onDropdownClose() {
      this.$emit('apply')
    },
  },
}
</script>
