import { Controller } from '@hotwired/stimulus'
import { debounce, lowerCase } from 'lodash'
/* eslint no-undef: off */

export default class extends Controller {
  static targets = [
    'tableForm',
    'drawerForm',
    'filtersModal',
    'loader'
  ]

  static values = {
    url: String,
    productFiltersUrl: String,
    productSearchUrl: String,
    clearDrawerUrl: String,
    csvExportUrl: String,
  }

  _searchParamsString = ''

  initialize() {
    this.submit = debounce(this.submit.bind(this), 300)
    this.searchProducts = debounce(this.searchProducts.bind(this), 300)
  }

  cleanEmptyKeys(searchParams) {
    const result = new URLSearchParams({})

    searchParams.forEach((value, key) => {
      if (value && value !== 'undefined') {
        result.append(key, value)
      }
    })

    return result
  }

  _metaKeys = {
    format: true,
    authenticity_token: true,
    utf8: true
  }

  searchParamsWithoutMetaKeys(searchParams) {
    const result = new URLSearchParams({})

    searchParams.forEach((value, key) => {
      // eslint-disable-next-line no-underscore-dangle
      if (!this._metaKeys[key]) {
        result.append(key, value)
      }
    })

    return result
  }

  get tableFormParams() {
    const formData = new FormData(this.tableFormTarget)
    return this.cleanEmptyKeys(new URLSearchParams(formData)).toString()
  }

  get drawerFormParams() {
    const formData = new FormData(this.drawerFormTarget)
    return this.cleanEmptyKeys(new URLSearchParams(formData)).toString()
  }

  cleanFilterBadges(url, event) {
    if (event.target.dataset.filterBadgeName) {
      const badgeName = lowerCase(event.target.dataset.filterBadgeName)
      const badgeValue = lowerCase(event.target.dataset.filterBadgeValue)
      switch (badgeName) {
        case 'status':
          url.searchParams.delete('status[]')
          break
        case 'created':
          url.searchParams.delete('[created_at][from]')
          url.searchParams.delete('[created_at][to]')
          break
        case 'paid':
          url.searchParams.delete('[paid_at][from]')
          url.searchParams.delete('[paid_at][to]')
          break
        case 'product':
          url.searchParams.delete('[product_type]')
          // Deleting child filters: product [content, subscription, bundle] and author as well.
          url.searchParams.delete(`[${badgeValue}_id]`)
          url.searchParams.delete('[author_id]')
          break
        case 'coupon':
          url.searchParams.delete('[coupon_id]')
          break
        case 'bundle':
        case 'subscription':
        case 'content':
        case 'author':
          url.searchParams.delete(`[${badgeName}_id]`)
          break
        case 'paid out':
          url.searchParams.delete('[paid_out]')
          break
        case 'min price':
          url.searchParams.delete('min_price')
          break
        case 'max price':
          url.searchParams.delete('max_price')
          break
        default:
          url.searchParams.delete(`[${badgeName}]`)
      }
    }
  }

  async submit(event) {
    this.filtersModalTarget.close()

    const queryParams = `?${this.tableFormParams}&${this.drawerFormParams}`

    const url = new URL(`${this.urlValue}${queryParams}`)

    // Clearing the status filter when pressed the Clear btn.
    if (event.target.dataset.clearStatus) { url.searchParams.delete('status[]') }

    // Clicking on a filter badge removes the filter from params.
    this.cleanFilterBadges(url, event)

    // Adding the pagination into params.
    if (event.target.dataset.pageId) { url.searchParams.set('page', event.target.dataset.pageId) }

    url.searchParams.set('format', 'turbo_stream')

    const searchParamsString = this.searchParamsWithoutMetaKeys(url.searchParams).toString()

    if (searchParamsString !== this.searchParamsString) {
      this.searchParamsString = searchParamsString

      // eslint-disable-next-line no-underscore-dangle
      this._showLoader()

      await fetch(url)
        .then(response => response.text())
        .then(html => {
          Turbo.renderStreamMessage(html)
          url.searchParams.delete('format')
          url.searchParams.delete('authenticity_token')
          url.searchParams.delete('utf8')
          history.replaceState(null, null, url.search.length > 0 ? url.search : '?')
          // eslint-disable-next-line no-underscore-dangle
          this._hideLoader()
          window.scrollTo(0, 0)
          if (event.target.name === '[search]') {
            // Focus search box if search triggered submit function
            setTimeout(() => {
              const searchElement = document.getElementById(event.target.id)
              searchElement.focus()
              searchElement.moveCursorToEnd()
            }, 50)
          }
        })
    }
  }

  _showLoader = () => {
    this.loaderTarget.showLoader()
  }

  _hideLoader = () => {
    this.loaderTarget.hideLoader()
  }

  async clearDrawerFilters(event) {
    const url = new URL(`${this.clearDrawerUrlValue}?${this.tableFormParams}`)
    url.searchParams.set('format', 'turbo_stream')

    await fetch(url)
      .then(response => response.text())
      .then(html => {
        Turbo.renderStreamMessage(html)
      })
  }

  async loadProductFilters(event) {
    const url = new URL(this.productFiltersUrlValue)
    url.searchParams.set('[product_type]', event.target.value)
    url.searchParams.set('format', 'turbo_stream')

    await fetch(url)
      .then(response => response.text())
      .then(html => {
        Turbo.renderStreamMessage(html)
      })
  }

  async searchProducts(event) {
    const url = new URL(this.productSearchUrlValue)
    const searchValue = event.target.value
    const searchType = event.target.dataset.searchType
    if (searchType === 'coupon') {
      url.searchParams.set('[coupon_search]', searchValue)
    }
    if (searchType === 'author') {
      url.searchParams.set('[author_search]', searchValue)
    }
    if (searchType === 'product') {
      url.searchParams.set('[product_search]', searchValue)
      url.searchParams.set('[product_type]', event.target.dataset.productType)
    }

    url.searchParams.set('format', 'turbo_stream')

    await fetch(url)
      .then(response => response.text())
      .then(html => {
        Turbo.renderStreamMessage(html)
      })
  }

  async exportCsv(event) {
    const queryParams = `?${this.tableFormParams}&${this.drawerFormParams}`

    const url = new URL(`${this.csvExportUrlValue}${queryParams}`)
    url.searchParams.set('format', 'turbo_stream')

    await fetch(url)
      .then(response => response.text())
      .then(html => {
        Turbo.renderStreamMessage(html)
      })
  }

  // We want to prevent form submissions on press enter to prevent
  // second request when searching as the search inputs make bounced
  // requests on typing.
  // Also, on search inputs within ds-selectors, the default action when
  // pressing the enter key is to submit the form through a POST request.
  // The form works using a GET request so this default action ends up with
  // a blank drawer.
  // Find +info: https://stimulus.hotwired.dev/reference/actions#global-events
  preventSubmission(event) {
    if (event.keyCode == 13) {
      event.preventDefault()
    }
  }
}
