import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['geoVueWidget', 'blocked', 'form']

  async geoVueWidgetTargetConnected(element) {
    this.renderGeoVueWidget(
      ...await Promise.all([
        (await import('vue')).default,
        (await import('bullet/components/Geo')).default,
        element
      ]),
    )
  }

  disconnect() {
    this.widget?.$destroy()
  }

  close(e) {
    e.currentTarget.closest('ds-modal').close()
  }

  renderGeoVueWidget(Vue, Component, target) {
    let value = this.blockedTargets.map(x => x.value)

    this.widget = new Vue({
      render: h => h(Component, {
        props: {
          value,
        },
        on: {
          input: e => {
            value = e

            this.setBlockedCountry(value)
            this.widget.$forceUpdate()
          },
        }
      }),
    })

    this.widget.$mount(target)
  }

  setBlockedCountry(countries) {
    this.blockedTargets.forEach(x => x.remove())

    countries.forEach(code => {
      const e = document.createElement('input')
      e.setAttribute('type', 'hidden')
      e.setAttribute('name', 'blocked_countries[]')
      e.setAttribute('value', code)
      e.setAttribute('data-contents--collections--geo-target', 'blocked')

      this.formTarget.appendChild(e)
    })
  }
}
