import { Controller } from '@hotwired/stimulus'
import API from 'libs/api'

export default class extends Controller {
  static values = {
    url: String,
    link: String,
  }

  async schedulePartnerOnboardingStatusWorker() {
    await API.get(this.urlValue)
    window.location.replace(this.linkValue)
  }
}
