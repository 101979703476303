import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'input', 'button']

  connect() {
    this.buttonTarget.disabled = false

    // disable form submiting on enter for each input
    this.inputTargets.forEach(input => {
      input.addEventListener('keydown', e => {
        if (e.key === 'Enter') {
          e.preventDefault()
          this.formSubmit()
        }
      })
    })

    // enable form submiting on enter for form only
    this.formTarget.addEventListener('keydown', e => {
      const hasModifier = e.metaKey || e.ctrlKey || e.shiftKey || e.altKey

      if (e.key === 'Enter' && !hasModifier) {
        if (!e.defaultPrevented) {
          this.formSubmit()
        }
      }
    })

    // disable button on form submit start
    this.formTarget.addEventListener('turbo:submit-start', () => {
      this.buttonTarget.disabled = true
    })

    // unlock button on form submit end
    this.formTarget.addEventListener('turbo:submit-end', () => {
      this.buttonTarget.disabled = false
    })
  }

  formSubmit() {
    if (this.buttonTarget.disabled) { return false }

    this.buttonTarget.disabled = true
    this.formTarget.requestSubmit()
  }
}
