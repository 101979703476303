import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['downloadableCheckbox', 'confirmationModal', 'confirmationCheckbox', 'confirmButton']

  handleDownloadableChange(e) {
    if (e.currentTarget.checked) {
      this.confirmationModalTarget.open()
    }
  }

  handleConfirmationCheckboxChange(e) {
    this.confirmButtonTarget.disabled = Boolean(!e.currentTarget.checked)
  }

  checkConfirmation() {
    this.downloadableCheckboxTarget.checked = Boolean(this.confirmationCheckboxTarget.checked)
    this.confirmButtonTarget.disabled = true
    this.confirmationCheckboxTarget.checked = false
  }

  cancel() {
    this.downloadableCheckboxTarget.click()
    this.confirmationCheckboxTarget.checked = false
    this.confirmButtonTarget.disabled = true
  }

}
