import { Controller } from '@hotwired/stimulus'

const EMAIL_REGEX = /^[a-z0-9]+@[a-z]+\.[a-z]{2,}$/

export default class extends Controller {
  connect() {
    this.validateDeveloperAccountName()

    this.element.addEventListener('input', this.validateDeveloperAccountName)
  }

  get label() {
    return this.element.parentElement.querySelector('label')
  }

  get input() {
    return this.element
  }

  get errorBox() {
    return this.element.parentElement.querySelector('.error-container')
  }

  get submitButton() {
    return this.element.closest('form').querySelector('ds-button[type="submit"]')
  }

  get isFormSubmittable() {
    return this.submitButton
  }

  // A common mistake from customers: when adding the developer account name,
  // they add their email, and not their actual account name.
  // But OTT need account name, not email, so we validate the case.
  validateDeveloperAccountName = () => {
    if (this.isFormSubmittable) {
      if (EMAIL_REGEX.test(this.input.value.trim())) {
        this.label.classList.add('text-ds-danger-emphasis')
        this.errorBox.innerText = 'Please enter the account name, not your email'
        this.submitButton.setAttribute('disabled', 'true')
      } else {
        this.label.classList.remove('text-ds-danger-emphasis')
        this.errorBox.innerText = ''
        this.submitButton.removeAttribute('disabled')
      }
    }
  }
}
