import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'uploader',
    'progress',
    'customImageRadio',
  ]

  onSuccess = ({ detail }) => {
    const [uploadedImage] = detail

    if (!uploadedImage) return

    const { uploadURL } = uploadedImage

    this.element.value = uploadURL
    this.customImageRadioTarget.value = uploadURL
  }

  connect() {
    this.uploaderTarget.addEventListener('success', this.onSuccess)
  }

  disconnect() {
    this.uploaderTarget.removeEventListener('success', this.onSuccess)
  }
}
