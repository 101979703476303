import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  connect() {
    this.element.addEventListener('close', () => this.delayAgreement())
  }

  async delayAgreement() {
    if (this.requestIsDelayed) return

    new FetchRequest('put', `${this.agreementsBaseURL}/delay`).perform()
  }

  async completeAgreement() {
    new FetchRequest('put', `${this.agreementsBaseURL}/complete`).perform()
    this.closeAgreementNoticeAndModal()
  }

  get modalElement() {
    return this.element
  }

  get noticeElement() {
    const { noticeElementId } = this.element.dataset
    return document.getElementById(noticeElementId)
  }

  closeAgreementNoticeAndModal() {
    this.modalElement.close()
    this.removeNoticeSmoothly()
  }

  removeNoticeSmoothly() {
    const element = this.noticeElement;
    const duration = 500;

    const startTime = performance.now();
    const startHeight = element.clientHeight;

    const animate = (currentTime) => {
      const progress = (currentTime - startTime) / duration;
      const easedProgress = 1 - Math.pow(1 - progress, 3);
      const newHeight = Math.max(0, startHeight * (1 - easedProgress));

      element.style.height = `${newHeight}px`;

      if (progress < 1) {
        requestAnimationFrame(animate);
      } else {
        element.parentNode.removeChild(element);
      }
    };

    requestAnimationFrame(animate);
  }

  get requestIsDelayed() {
    return this.modalElement.dataset.isDelayed === 'true'
  }

  get agreementsBaseURL() {
    return `/bullet/apps/agreements/signing_requests/${this.element.dataset.requestId}`
  }
}
