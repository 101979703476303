import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {

  static targets = ['dripModalTemplate']

  remove(e) {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm('Are you sure you want to delete this playlist item?')) {
      return
    }
    const { id, url } = e.currentTarget.dataset
    if (url) {
      new FetchRequest('delete', url, { responseKind: 'turbo-stream', body: JSON.stringify({ playlist_item_id: id }) }).perform()
    }

    this.element.remove()
  }

  showDripSettings(e) {
    e.preventDefault()
    this.showController.createModal(this.dripModalTemplateTarget.innerHTML, 'collection_edit_modal', 'medium', true)
  }

  get showController() {
    const container = this.element.closest('div[data-controller="contents--collections--show"]')
    return this.application.getControllerForElementAndIdentifier(container, 'contents--collections--show')
  }

  get formController() {
    return this.application.getControllerForElementAndIdentifier(this.formElement, 'contents--collections--form')
  }

  get formElement() {
    return this.element.closest('form')
  }
}
