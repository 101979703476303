import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form'];

  connect() {
    this.element.addEventListener('turbo:submit-end', (event) => {
      if (event.detail.success) {
        if (this.formTarget.dataset.newTabRedirectTo) {
          window.open(this.formTarget.dataset.newTabRedirectTo, '_blank')
        }

        window.location.replace(this.formTarget.dataset.redirectTo)
      }
    })
  }
}
