/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button', 'player']
  static values = {
    id: String
  }

  connect() {
    this.buttonTarget.addEventListener('click', this.play.bind(this))
  }

  play() {
    this.playerTarget.style.display = 'block'

    window._wq = window._wq || []
    window._wq.push({ id: this.idValue, onReady(video) { video.play() } })
  }
}
