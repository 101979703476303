import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'selectAllCheckbox', 'row', 'toolbar', 'toolbarTools', 'selectedCounterLabel', 'optionsMenu']

  static values = { selectedCount: Number, trSelectedClass: String }

  // Events
  selectAll(e) {
    this.setCheckboxesChecked(e.currentTarget.checked)
    this.setToolbarToolsVisible(e.currentTarget.checked)
    this.appendSelectedCheckboxesToToolbarForms()
  }

  toggleSelect(e) {
    this.setRowSelected(e.currentTarget.closest('tr'), e.currentTarget.checked)

    this.selectAllCheckboxTarget.checked = this.querySelectedCount !== 0
    this.setToolbarToolsVisible(this.querySelectedCount !== 0)
    this.appendSelectedCheckboxesToToolbarForms()
  }

  // HTML modifiers
  setRowSelected(tr, selected) {
    if (selected) {
      this.setOptionsMenuVisible(tr, false)
      tr.classList.add(this.trSelectedClassValue)
    } else {
      this.setOptionsMenuVisible(tr, true)
      tr.classList.remove(this.trSelectedClassValue)
    }
  }

  setOptionsMenuVisible(tr, visible) {
    if (visible) {
      this.optionsMenuTargets.find(x => x.closest('tr') === tr)?.classList.remove('invisible')
      return
    }

    this.optionsMenuTargets.find(x => x.closest('tr') === tr)?.classList.add('invisible')
  }

  setToolbarToolsVisible(visible) {
    if (visible) {
      this.toolbarTarget.classList.add(this.trSelectedClassValue)
      this.toolbarToolsTarget.classList.remove('hidden')
    } else {
      this.toolbarToolsTarget.classList.add('hidden')
      this.toolbarTarget.classList.remove(this.trSelectedClassValue)
    }

    this.setRowSelected(this.toolbarToolsTarget.closest('tr'), visible)
    this.setCounterLabel(this.querySelectedCount)
  }

  setCheckboxesChecked(checked) {
    this.checkboxTargets.forEach(x => {
      x.checked = checked
    })

    this.rowTargets.forEach(tr => {
      this.setRowSelected(tr, checked)
    })
  }

  setCounterLabel(count) {
    this.selectedCounterLabelTarget.textContent = `${count} ${count > 1 ? 'items' : 'item'} selected`
  }

  appendSelectedCheckboxesToToolbarForms() {
    const forms = this.toolbarToolsTarget.querySelectorAll('form')
    forms.forEach(form => {
      this.appendSelectedCheckboxesToForm(form)
    })
  }

  appendSelectedCheckboxesToForm(form) {
    form.querySelectorAll('input[name="selected[]"]').forEach(input => {
      input.remove()
    })

    this.checkboxTargets.forEach(checkbox => {
      if (checkbox.checked) {
        const input = document.createElement('input')
        input.type = 'hidden'
        input.name = 'selected[]'
        input.value = checkbox.value
        form.appendChild(input.cloneNode())
      }
    })
  }

  // Queries
  get querySelectedCount() {
    return this.checkboxTargets.filter(x => x.checked).length
  }
}
