import { Controller } from '@hotwired/stimulus'
import { debounce, isEmpty } from 'lodash'

export default class extends Controller {
  connect() {
    this.element.addEventListener("turbo:before-fetch-request", (event) => {
      const { fetchOptions: { headers } } = event.detail

      headers.Accept = ["text/vnd.turbo-stream.html", headers.Accept].join(", ")
    })

    this.element.addEventListener('keydown', (event) => {
      if (!['ArrowDown', 'ArrowUp', 'Enter', 'Escape'].includes(event.key)) return

      event.preventDefault()
      const input = document.getElementById('items-search')
      const searchResultsBlock = input.nextElementSibling.firstElementChild
      const searchResultsSize = searchResultsBlock.querySelectorAll('a').length

      if (document.activeElement == input && searchResultsSize > 0) {
        if (event.key == 'ArrowDown') {
          const first = searchResultsBlock.firstElementChild
          const firstActive = searchResultsBlock.querySelector('.hovered')

          if (firstActive) {
            firstActive.classList.remove('hovered')
            if (firstActive.nextElementSibling) {
              firstActive.nextElementSibling.classList.add('hovered')
            } else {
              first.classList.add('hovered')
            }
          } else {
            first.classList.add('hovered')
          }
        }

        if (event.key == 'ArrowUp') {
          const last = searchResultsBlock.lastElementChild
          const firstActive = searchResultsBlock.querySelector('.hovered')

          if (firstActive) {
            firstActive.classList.remove('hovered')
            if (firstActive.previousElementSibling) {
              firstActive.previousElementSibling.classList.add('hovered')
            } else {
              last.classList.add('hovered')
            }
          } else {
            last.classList.add('hovered')
          }
        }

        if (event.key == 'Enter') {
          const firstActive = searchResultsBlock.querySelector('.hovered')

          if (firstActive) {
            firstActive.click()
          }
        }

        if (event.key == 'Escape') {
          document.activeElement.blur()
        }
      }
    })
  }

  initialize() {
    this.searchItems = debounce(this.searchItems, 1000).bind(this)
  }

  resolveSearchInputChange(event) {
    const searchQuery = event.target.value

    if (isEmpty(searchQuery)) {
      document.getElementById('items-result').innerHTML = ''
    } else {
      if (event.type != 'change') {
        this.searchItems()
      }
    }
  }

  searchItems() {
    document.getElementById('items-form').requestSubmit()
  }
}
