import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'clearFilterButton', 'filterCheckbox', 'filterForm', 'filterSelect',
    'searchInput', 'startDateInput', 'startDatePicker'
  ]

  connect() {
  }

  dateFilterChanged(e) {
    this.startDateInputTarget.value = e.target.value
    this.filterFormTarget.requestSubmit()
  }

  submitForm() {
    window.requestAnimationFrame(() => { this.filterFormTarget.requestSubmit() })
  }

  clearFilter() {
    this.filterSelectTarget.value = []
    this.submitForm()
  }

  clearDateFilter() {
    this.startDateInputTarget.value = null
    this.startDatePickerTarget.value = null
    this.filterFormTarget.requestSubmit()
  }
}
