import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['settingsGroup', 'form']

  handleSubmitButton(e) {
    e.preventDefault()
    this.formTarget.requestSubmit()
  }

  hideInputs() {
    this.settingsGroupTarget.classList.add('hidden')
  }

  showInputs() {
    this.settingsGroupTarget.classList.remove('hidden')
  }
}
