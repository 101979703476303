import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'sliderTotal', 'slider', 'bubble', 'sliderContainer', 'monthlyTooltip']

  static values = {
    sliders: {},
    questionsString: String,
    questions: Array
  }

  connect() {
    this.questionsValue = JSON.parse(this.questionsStringValue)

    this.questionsValue.forEach((question) => {
      this.slidersValue[question.key] = {
        value: question.value,
        question: question,
        updateValue: function(value) {
          this.value = value
          this.updateHTML(value)
        },
        updateHTML: (value) => {
          const slider = document.querySelector(`.uscalculator [data-slider-key="${question.key}"]`)
          const bubble = slider.closest('.slidecontainer').querySelector('[data-behavior="slider-bubble"]')

          slider.value = value
          bubble.querySelector('span').innerHTML = question.template.replace('x', value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
          bubble.style.left = ((value - question.min) / (question.max - question.min) * 100) + '%'
        },
      }
    })

    this.sliderTargets.forEach((slider)=> {
      this.slidersValue[slider.dataset.sliderKey].updateValue(slider.value)
      this.calculateRevenue()

      slider.oninput = (event) => {
        this.slidersValue[slider.dataset.sliderKey].updateValue(event.target.value) 
        this.calculateRevenue()
      }

      this.sliderContainerTargets.forEach(function(container) {
        container.classList.remove('hidden')
      })
    })
  }

  calculateRevenue() {
    const subscribers = Math.round(this.slidersValue.total_audience.value * this.slidersValue.paid_audience.value / 100)
    const monthlyRevenue = Math.round(subscribers * this.slidersValue.subscription_price.value - this.slidersValue.monthly_uscreen_fee.value - this.slidersValue.subscriber_uscreen_fee.value * subscribers)
    const total = monthlyRevenue * 12
 
    this.sliderTotalTarget.innerHTML = '$' + total.toLocaleString("en-US")
    this.monthlyTooltipTarget.text = 'Average monthly income would be $' + monthlyRevenue.toLocaleString("en-US")
  }
}
