import { Controller } from '@hotwired/stimulus'
import Uploader from 'libs/video-uploader'
import { post } from '@rails/request.js'

export default class extends Controller {
  static targets = [
    'finishForm', 'progress',
    'uploadLink', 'status', 'resumeFileInput',
    'editVideoButton', 'cancelButton', 'resumeButton', 'deleteButton']

  static values = {
    id: String,
    ticketId: String,
    uploadLink: String,
    startUpload: Boolean,
    filename: String,
    noUploader: Boolean,
    size: Number,
    type: String
  }

  stopListenItem = undefined

  item = undefined

  connect() {
    if (this.startUploadValue) {
      this.upload()
    }
  }

  disconnect() {
    if (this.stopListenItem) this.stopListenItem()
  }

  onCancel(event) {
    // eslint-disable-next-line no-param-reassign
    event.currentTarget.hidden = true
    this.item?.cancel()
    this.statusTarget.innerHTML = 'Canceled'
    this.progressTarget.hidden = true
    this.deleteButtonTarget.hidden = false
  }

  onDelete() {
    this.element.remove()
  }

  onRetry() {
    if (this.typeValue === 'dropbox') {
      this.retryDropbox()
    } else if (this.typeValue === 'video_upload') {
      this.resumeFileInputTarget.click()
    }
  }

  retryDropbox() {
    const type = document.createElement('input')
    type.setAttribute('name', 'type')
    type.setAttribute('value', 'dropbox')

    this.modalController.filesTarget.appendChild(type)

    const retry = document.createElement('input')
    retry.setAttribute('name', 'retry')
    retry.setAttribute('value', this.idValue)

    this.modalController.filesTarget.appendChild(retry)

    Uploader.cacheDropboxItems([{ name: this.filenameValue, url: this.uploadLinkValue, bytes: this.sizeValue }])
      .forEach(x => {
        const filename = document.createElement('input')
        filename.setAttribute('name', 'files[][filename]')
        filename.setAttribute('value', x.name)

        const lastModified = document.createElement('input')
        lastModified.setAttribute('name', 'files[][url]')
        lastModified.setAttribute('value', x.url)

        const size = document.createElement('input')
        size.setAttribute('name', 'files[][size]')
        size.setAttribute('value', x.bytes)

        this.modalController.filesTarget.appendChild(filename)
        this.modalController.filesTarget.appendChild(lastModified)
        this.modalController.filesTarget.appendChild(size)
      })

    this.modalController.formTarget.requestSubmit()
  }

  onResumeSelect(event) {
    event.preventDefault()

    const source = event.dataTransfer || event.target

    const files = Uploader.cacheSourceItems(source.files)
    if (!files.length) return

    const type = document.createElement('input')
    type.setAttribute('name', 'type')
    type.setAttribute('value', 'video_upload')

    this.modalController.filesTarget.appendChild(type)

    const retry = document.createElement('input')
    retry.setAttribute('name', 'retry')
    retry.setAttribute('value', this.idValue)

    this.modalController.filesTarget.appendChild(retry)

    files.forEach(x => {
      const filename = document.createElement('input')
      filename.setAttribute('name', 'files[][filename]')
      filename.setAttribute('value', x.name)

      const lastModified = document.createElement('input')
      lastModified.setAttribute('name', 'files[][last_modified]')
      lastModified.setAttribute('value', x.lastModified)

      const size = document.createElement('input')
      size.setAttribute('name', 'files[][size]')
      size.setAttribute('value', x.size)

      this.modalController.filesTarget.appendChild(filename)
      this.modalController.filesTarget.appendChild(lastModified)
      this.modalController.filesTarget.appendChild(size)
    })

    this.modalController.formTarget.requestSubmit()
  }

  upload() {
    if (this.noUploaderValue) return

    this.item = Uploader.connect({
      id: this.idValue,
      filename: this.filenameValue,
      type: this.typeValue
    })

    if (!this.item) return

    this.setProgress(this.item.progress)
    this.stopListenItem = this.item.listen(this.handleUpdate)
  }

  setProgress(value) {
    this.progressTarget.style.setProperty('--width', `${Math.ceil(value)}%`)
  }

  get modalController() {
    const dsModal = document.querySelector('ds-modal[data-controller="video-uploader-modal"]')
    return this.application.getControllerForElementAndIdentifier(dsModal, 'video-uploader-modal')
  }

  handleUpdate = (eventName, value) => {
    switch (eventName) {
      case 'started':
        this.element.scrollIntoView()
        break
      case 'error':
        if (this.hasEditVideoButtonTarget) this.editVideoButtonTarget.hidden = true
        if (this.hasCancelButtonTarget) this.cancelButtonTarget.hidden = true

        this.resumeButtonTarget.hidden = false
        this.deleteButtonTarget.hidden = false
        this.statusTarget.innerHTML = 'Upload Failed. Please try again.'
        if (value.includes('Maximum file size is 70 Gb')) {
          this.statusTarget.innerHTML = value
        }
        this.statusTarget.classList.remove('text-gray-600')
        this.statusTarget.classList.add('text-red-600')
        this.progressTarget.hidden = true
        break
      case 'progress':
        this.setProgress(value)
        break
      case 'success':
        Promise.resolve().then(() => {
          post(
            this.finishFormTarget.getAttribute('action'),
            {
              body: Object.fromEntries(new FormData(this.finishFormTarget)),
              headers: {
                'Accept': 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml',
                'Turbo-Frame': 'bullet_modal'
              },
            }
          )
          // this.finishFormTarget.requestSubmit()
        })
        break
      default:
        break
    }
  }
}
