import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    limit: { type: Number, default: 140 },
  }

  static targets = ['textarea', 'counter']

  connect() {
    this.textareaTarget.addEventListener('input', this.updateCounter.bind(this))
    this.updateCounter()
  }

  updateCounter() {
    const count = this.textareaTarget.value.length

    if (count > this.limitValue) {
      this.textareaTarget.value = this.textareaTarget.value.substring(0, this.limitValue)
      this.counterTarget.textContent = 0
      return
    }

    this.counterTarget.textContent = this.limitValue - count
  }
}
