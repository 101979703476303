import { STATUS } from './constants'
import { EventBus } from './EventBus'
import { wait } from './utils/retry'
export class Queue extends EventBus {
  items = []
  isProcessing = false

  constructor() {
    super()
  }

  push(...items) {
    this.items.push(...items)
    if (this.isProcessing) return
    this.process(this.getNextVideo())
  }

  remove(item) {
    const index = this.items.indexOf(item)
    if (!~index) return
    this.items.slice(index, 1)
  }

  getNextVideo() {
    return this.items
      .sort((a, b) => a.meta.size - b.meta.size)
      .find(i => i.status === STATUS.INITIALIZED)
  }

  async process(item) {
    this.isProcessing = true
    if (!item) {
      this.emit('ended')
      return this.isProcessing = false
    }

    try {
      if (item.loader) await item.loader.promise
      else await item.upload()
    } catch (e) {
      this.remove(item)
      this.emit('error', e)
    }

    await wait(2000)
    this.process(this.getNextVideo())
  }
}
