import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['roleCheckbox']

  connect() {
    this.checkPublisherRelatedRoles()
    this.checkContentManagerRelatedRoles()
  }

  checkPublisherRelatedRoles() {
    const publisherCheckbox = this.#findRoleCheckbox('user[roles][publisher]')

    this.roleCheckboxTargets.forEach(element => {
      if (publisherCheckbox.name === element.name) return

      element.checked = publisherCheckbox.checked || element.dataset.initialCheck === 'true'
      element.disabled = publisherCheckbox.checked
    })
  }

  checkContentManagerRelatedRoles() {
    const contentManagerCheckbox = this.#findRoleCheckbox('user[roles][content_manager]')
    const videoUploadManagerCheckbox = this.#findRoleCheckbox('user[roles][video_upload_manager]')
    videoUploadManagerCheckbox.checked = contentManagerCheckbox.checked || videoUploadManagerCheckbox.dataset.initialCheck === 'true'
    videoUploadManagerCheckbox.disabled = contentManagerCheckbox.checked
  }

  #findRoleCheckbox(name) {
    return this.roleCheckboxTargets.find(element => element.name === name)
  }
}
