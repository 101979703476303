// TEMP SOLUTION
// remove after update to version of turbo >= 7.2.0-beta.1
// since current version doesn't allow GET requests with
// turbo_stream on the links

import { Controller } from '@hotwired/stimulus'
import { get, post } from '@rails/request.js'

export default class extends Controller {
  getTurboStream(event) {
    event.preventDefault()

    get(event.currentTarget.href, {
      contentType: 'text/vnd.turbo-stream.html',
      responseKind: 'turbo-stream'
    })
  }

  postTurboStream(event) {
    event.preventDefault()

    post(event.currentTarget.href, {
      contentType: 'text/vnd.turbo-stream.html',
      responseKind: 'turbo-stream'
    })
  }
}
