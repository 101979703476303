import { Controller } from '@hotwired/stimulus';
import { emit } from './utils/event'

export default class extends Controller {

  static targets = [
    'list',
    'item',
    'empty', 'emptyFilter', // The label saying "No results for {filter}" if nothing is found. Whole element is 'empty' and the {filter} is 'emptyFilter'
    'toggleAllButton', 'checkbox' // Multichoice-specific
  ]

  static values = {
    multichoice: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    }
  }

  connect() {
    this.selected = {}
  }

  selectOption(item, isSelected) {
    if (!this.multichoiceValue) {
      this.element.querySelectorAll('.is-selected').forEach(el => el.classList.remove('is-selected'));
      item.classList.add('is-selected');
    } else {
      let checkbox = item.querySelector('input[type="checkbox"]');
      checkbox.checked = isSelected;
      item.classList[isSelected ? 'add' : 'remove']('is-selected');
    }

    const value = item.getAttribute('data-option-value');
    emit(this.element, 'option-selected', { detail: { value, isSelected } })
  }

  handleOptionClick(event) {
    event.preventDefault();
    event.stopPropagation();

    const item = event.target.closest('[data-beam--option-list-target=item]');
    if (!item) return;

    this.selectOption(item, !item.classList.contains('is-selected'));
  }

  filter(event) {
    const filter = event.target.value.toLowerCase();
    let hasResults = false;

    this.itemTargets.forEach(el => {
      if (el.getAttribute('data-option-text').includes(filter)) {
        el.classList.remove('hidden')
        hasResults = true;
      } else {
        el.classList.add('hidden');
      }
    })

    if (hasResults) {
      this.emptyTarget.classList.add('hidden');
    } else {
      this.emptyFilterTarget.textContent = filter;
      this.emptyTarget.classList.remove('hidden');
    }
  }

  changeAll(isSelected) {
    this.itemTargets.forEach(el => {
      this.selectOption(el, isSelected);
    });
  }

  handleKeyDown(event) {
    if (event.keyCode === 13 || event.keyCode === 32) {
      const element = event.target.closest('[data-beam--option-list-target="item"]');
      if (element) {
        this.handleOptionClick({ target: element });
        event.preventDefault();
      }
    }
  }

  handleToggleAll(event) {
    this.checkedValue = !this.checkedValue
    this.changeAll(this.checkedValue);

    // find by [data-role=content] inside the button and change the text
    const buttonContent = this.toggleAllButtonTarget.querySelector('[data-role=content]');
    buttonContent.textContent = this.checkedValue ? 'Deselect all' : 'Select all';
  }
}
