let loaded = false

const loadScript = () => new Promise((resolve, reject) => {
  if (loaded || window.Dropbox) return resolve()

  const dropBoxScript = document.createElement('script')
  dropBoxScript.onload = () => {
    if (window.Dropbox.isBrowserSupported()) {
      loaded = true
      resolve()
    } else {
      reject()
    }
  }

  dropBoxScript.setAttribute('src', 'https://www.dropbox.com/static/api/2/dropins.js')
  dropBoxScript.setAttribute('id', 'dropboxjs')
  dropBoxScript.setAttribute('data-app-key', 'j0w43la2wg6t4lt')
  document.head.appendChild(dropBoxScript)
})

const selectFiles = () => new Promise((resolve, reject) => {
  if (!window.Dropbox) reject()

  window.amplitude?.getInstance().logEvent('[Content] Click Dropbox button')
  window.Dropbox.choose({
    linkType: 'direct',
    multiselect: true,
    extensions: ['video'],
    success: files => {
      resolve(
        files.map(file => ({
          url: file.link,
          name: file.name,
          bytes: file.bytes,
        }))
      )
    }
  })
})

export const selectFromDropbox = async () => {
  await loadScript()
  return (await selectFiles()) || []
}
