import { Controller } from '@hotwired/stimulus'

// This controller listens to the turbo:before-stream-render event to add or remove classes
// when the elements are about to be added or removed.
//
// Inspired from: https://edforshaw.co.uk/hotwire-turbo-stream-animations
// Tip: use the classes from https://github.com/jamiebuilds/tailwindcss-animate
//
// To turn off animation, add a specific element to the DOM and pass it to the
// controller's root element using data-skip-animation-when-id.
//
// Example of use:
//
// <div data-controller="animation" data-skip-animation-when-id="specific_element">
//   <div
//     id="content_1"
//     data-stream-enter-class="animate-in fade-in"
//     data-stream-exit-class="animate-out fade-out"
//   >
//     Content 1
//   </div>
// </div>
export default class extends Controller {
  connect() {
    this.skipAnimationWhenId = this.element.dataset.skipAnimationWhenId
    this.beforeStreamRender = e => this.onStreamRender(e)
    document.addEventListener('turbo:before-stream-render', this.beforeStreamRender)
  }

  disconnect() {
    document.removeEventListener('turbo:before-stream-render', this.beforeStreamRender)
  }

  onStreamRender(event) {
    const isAnimationSkipped = this.skipAnimationWhenId && document.getElementById(this.skipAnimationWhenId)

    if (isAnimationSkipped) { return }

    if (event.target.firstElementChild instanceof HTMLTemplateElement) {
      const enterAnimationClasses = event.target.templateContent.firstElementChild.dataset.streamEnterClass
      if (enterAnimationClasses) {
        enterAnimationClasses.split(' ').forEach(enterAnimationClass => {
          event.target.templateElement.content.firstElementChild.classList.add(enterAnimationClass)
        })
      }
    }

    const elementToRemove = document.getElementById(event.target.target)

    if (elementToRemove) {
      const streamExitClasses = elementToRemove.dataset.streamExitClass

      if (streamExitClasses) {
        event.preventDefault()

        streamExitClasses.split(' ').forEach(streamExitClass => {
          elementToRemove.classList.add(streamExitClass)
        })

        elementToRemove.addEventListener('animationend', () => {
          event.target.performAction()
        })
      }
    }
  }
}
