/* eslint-disable no-param-reassign */
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['search', 'filter', 'clearAll', 'filterForm']

  connect() {
    this.checkClearAllButton()
  }

  submit() {
    this.checkClearAllButton()
  }

  clearFilters(event) {
    event.preventDefault()
    this.filterTargets.forEach(element => {
      element.value = null
    })
    this.filterFormTarget.requestSubmit()
  }

  checkClearAllButton() {
    const filterWithValue = this.filterTargets.find(e => e.value && e.value !== '' && e.value !== null)

    if (filterWithValue) {
      this.clearAllTarget.classList.toggle('hidden', false)
    } else {
      this.clearAllTarget.classList.toggle('hidden', true)
    }
  }
}
