<template>
  <a
    ref="element"
    class="
      flex items-center border rounded
      p-3 my-1 bg-white transition-colors
      duration-100 ease-in hover:bg-gray-100
    "
    :class="{
      'bg-blue-100': selected
    }"
    :href="item.url"
    @click.prevent="$emit('click', item)"
  >
    <svg-sprite-icon
      v-if="icon"
      :icon="icon"
      :width="20"
      :height="20"
      class="text-gray-500 mr-2 self-start"
    />
    <div class="flex-1">
      <div
        class="text-sm leading-none"
        v-html="highlight(item.title)"
      />
      <div
        v-if="item.description"
        class="text-xs text-gray-500"
        v-html="highlight(item.description)"
      />
    </div>
    <svg-sprite-icon
      :icon="require('assets/icons/chevron-right.svg')"
      :width="14"
      :height="14"
      class="text-grey-900"
    />
  </a>
</template>
<script>
import SvgSpriteIcon from 'common/components/SvgSpriteIcon'

export default {
  components: {
    SvgSpriteIcon,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    selected: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    icon: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
  },
  watch: {
    selected() {
      if (!this.selected) return
      this.$refs.element.scrollIntoView(false)
    },
  },
  methods: {
    highlight(text) {
      return text.replace(
        new RegExp(this.value, 'i'),
        '<span class="text-primary underline">$&</span>',
      )
    },
  },
}
</script>
