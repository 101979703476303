import { Controller } from '@hotwired/stimulus'

const socialToUrlMapping = {
  facebook: url => `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
  twitter: (url, text) => `https://twitter.com/intent/tweet?text=${encodeURIComponent(`${text}\n${url}`)}`
}

export default class extends Controller {
  connect() {
    const text = this.element.dataset.title
      ? `Join me at ${this.element.dataset.title}`
      : 'Join me!'

    this.url = socialToUrlMapping[this.element.dataset.social](this.element.dataset.url, text)

    this.element.setAttribute('href', this.url)
  }

  open(e) {
    e.preventDefault()
    window.open(this.url, '_blank', 'location=yes,height=450,width=600,scrollbars=yes,status=yes')
  }
}
