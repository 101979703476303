import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['emailNotificationModalTemplate', 'newCategoryModalTemplate', 'testPlayerTemplate', 'confirmActionTemplate', 'latencyTemplate', 'deleteForm', 'geoModalTemplate']

  showEmailNotificationModal(e) {
    e.preventDefault()

    this.createModal(this.emailNotificationModalTemplateTarget.innerHTML, 'live_edit_modal', 'large', true)
  }

  showGeoModal() {
    this.createModal(this.geoModalTemplateTarget.innerHTML, 'geo_modal')
  }

  showTestPlayerModal() {
    this.createModal(this.testPlayerTemplateTarget.innerHTML, 'test_player_modal', 'medium', true)
  }

  showNewCategoryModal() {
    this.createModal(this.newCategoryModalTemplateTarget.innerHTML, 'new_category_modal', 'medium')
  }

  showConfirmActionModal() {
    this.createModal(this.confirmActionTemplateTarget.innerHTML, 'confirm_action_modal', 'small', true)
  }

  showLatencyModal(e) {
    e.preventDefault()

    this.createModal(this.latencyTemplateTarget.innerHTML, 'latency_modal', 'small', true)
  }

  createModal(content, id, size = 'large', withCloseIcon = false) {
    document.getElementById(id)?.remove()

    const modal = document.createElement('ds-modal')
    modal.setAttribute('size', size)
    modal.setAttribute('position', 'center')
    modal.setAttribute('closable', 'true')
    modal.setAttribute('show-on-mount', 'true')
    modal.setAttribute('id', id)

    if (withCloseIcon){
      modal.setAttribute('with-close-icon', 'true')
    }

    modal.innerHTML = content

    this.element.appendChild(modal)

    return modal
  }

  deleteVideo(e) {
    e.preventDefault()

    // eslint-disable-next-line no-restricted-globals
    if (confirm('You are about to permanently delete this event and its associated data. This cannot be restored. Are you sure you want to delete this event?')) {
      this.deleteFormTarget.requestSubmit()
    }
  }

  copyLink(e) {
    e.preventDefault()

    const { link } = e.currentTarget.dataset

    navigator.clipboard.writeText(link)
  }

  closeModal(e) {
    e.currentTarget.closest('ds-modal').close()
  }
}
