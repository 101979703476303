/* eslint-disable no-param-reassign */
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    filterParam: String
  }

  triggerClearFilter() {
    const clearFilter = new CustomEvent('clear-filter', {
      detail: { filterParam: this.filterParamValue },
    })

    window.dispatchEvent(clearFilter)
  }
}
