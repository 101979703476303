import { Controller } from '@hotwired/stimulus'
import BDateRangePicker from 'common/components/BDateRangePicker'
import Vue from 'vue'

export default class extends Controller {
  static targets = ['root', 'from', 'to']

  static values = {
    placeholder: {
      type: String,
      default: 'Select date...',
    },
    withQuickOptions: {
      type: Boolean,
      default: false,
    },
    timezone: {
      type: String,
      default: '',
    },
    quickPast: {
      type: Boolean,
      default: true,
    },
    quickFuture: {
      type: Boolean,
      default: false,
    },
    quickCustomOptions: {
      type: Array,
      default: [],
    },
  }

  connect() {
    const {
      fromTarget,
      toTarget,
      placeholderValue,
      withQuickOptionsValue,
      timezoneValue,
      quickPastValue,
      quickFutureValue,
      quickCustomOptionsValue,
    } = this
    const value = [fromTarget, toTarget].map(target => target.value)

    const datePicker = new Vue({
      data: () => ({
        innerValue: value.every(Boolean) ? value.map(v => parseInt(v)) : null,
      }),
      render(h) {
        return h(BDateRangePicker, {
          props: {
            value: this.innerValue,
            placeholder: placeholderValue,
            withQuickOptions: withQuickOptionsValue,
            quickPast: quickPastValue,
            quickFuture: quickFutureValue,
            quickCustomOptions: quickCustomOptionsValue,
            timezone: timezoneValue || undefined,
          },
          on: {
            input: val => {
              this.innerValue = val
              fromTarget.value = this.innerValue?.[0] || null
              toTarget.value = this.innerValue?.[1] || null
            },
          },
        })
      },
    })

    datePicker.$mount(this.rootTarget)
  }
}
