import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['destroy', 'inputsContainer', 'label']

  toggleField(event) {
    if (event.currentTarget.checked) {
      this.inputsContainerTarget.classList.remove('hidden')
      if (this.hasLabelTarget) {
        this.labelTarget.classList.remove('hidden')
      }
      this.destroyTarget.value = 'false'
    } else {
      this.inputsContainerTarget.classList.add('hidden')
      if (this.hasLabelTarget) {
        this.labelTarget.classList.add('hidden')
      }
      this.destroyTarget.value = 'true'
    }
  }
}
