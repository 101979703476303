/* eslint-disable no-param-reassign */
import { Controller } from '@hotwired/stimulus'
import dayjs from 'dayjs'

export default class extends Controller {
  static targets = ['title', 'message', 'titleLengthLeft', 'messageLengthLeft']

  static values = {
    storeTimeZone: String
  }

  connect() {
    this.updateTitleState(this.titleTarget)
    this.updateMessageState(this.messageTarget)
  }

  updatePhonePreview = event => {
    const timestamp = event.target.value
    const postPreviewTime = document.getElementById('phone-preview-time')
    const postPreviewDate = document.getElementById('phone-preview-date')

    if (!timestamp) {
      postPreviewTime.textContent = postPreviewTime.dataset.defaultValue
      postPreviewDate.textContent = postPreviewDate.dataset.defaultValue

      return
    }

    const parsedDate = dayjs.tz(
      dayjs.unix(timestamp), this.storeTimeZoneValue
    )

    postPreviewTime.textContent = parsedDate.format('hh:mm')
    postPreviewDate.textContent = parsedDate.format('MMMM D, dddd')
  }

  updateTitle(event) {
    this.updateTitleState(event.target)
  }

  updateTitleState(target) {
    const pushImgTitle = document.getElementById('push-notification-badge-title')
    const pushFormPreviewTitle = document.getElementById('push-notification-badge-form-preview-title')

    const limitElement = this.titleLengthLeftTarget

    this.setState(target, pushImgTitle, limitElement)
    this.setState(target, pushFormPreviewTitle, limitElement)
  }

  updateMessage(event) {
    this.updateMessageState(event.target)
  }

  updateMessageState(target) {
    const pushImgMessage = document.getElementById('push-notification-badge-message')
    const pushFormPreviewMessage = document.getElementById('push-notification-badge-form-preview-message')

    const limitElement = this.messageLengthLeftTarget

    this.setState(target, pushImgMessage, limitElement)
    this.setState(target, pushFormPreviewMessage, limitElement)
  }

  setState(target, messageElement, limitElement) {
    const input = target.value
    const limit = target.maxLength
    const { emptyState } = target.dataset
    const maxWordLengthUntilOverflow = 24

    let pushMessageText = target.value
    let charactersLeft = limit - input.length

    if (input.length === 0) {
      pushMessageText = emptyState
      charactersLeft = limit
    }

    if (pushMessageText.split(' ').find(word => word.length > maxWordLengthUntilOverflow)) {
      messageElement.classList.toggle('break-all', true)
    } else {
      messageElement.classList.toggle('break-all', false)
    }

    messageElement.textContent = pushMessageText
    limitElement.textContent = charactersLeft
  }
}
