import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['newVideoModalTemplate', 'emailNotificationModalTemplate', 'newCategoryModalTemplate', 'deleteForm', 'geoModalTemplate']

  showGeoModal() {
    this.createModal(this.geoModalTemplateTarget.innerHTML, 'collection_edit_modal')
  }

  showNewCategoryModal() {
    this.createModal(this.newCategoryModalTemplateTarget.innerHTML, 'collection_edit_modal', 'medium')
  }

  showEmailNotificationModal(e) {
    e.preventDefault()

    this.createModal(this.emailNotificationModalTemplateTarget.innerHTML, 'collection_edit_modal')
  }

  showNewVideoModal(e) {
    e.preventDefault()

    this.createModal(this.newVideoModalTemplateTarget.innerHTML, 'collection_edit_modal', 'medium', true)
  }

  createModal(content, id, size = 'large', withCloseIcon = false) {
    document.getElementById(id)?.remove()

    const modal = document.createElement('ds-modal')
    modal.setAttribute('size', size)
    modal.setAttribute('position', 'center')
    modal.setAttribute('closable', 'true')
    modal.setAttribute('show-on-mount', 'true')
    modal.setAttribute('id', id)

    if (withCloseIcon) {
      modal.setAttribute('with-close-icon', 'true')
    }

    modal.innerHTML = content

    this.element.appendChild(modal)

    return modal
  }

  deleteCollection(e) {
    e.preventDefault()

    // eslint-disable-next-line no-restricted-globals
    if (confirm('You are about to permanently delete this collection and its associated data. This cannot be restored. Are you sure you want to delete this collection?')) {
      this.deleteFormTarget.requestSubmit()
    }
  }

  closeModal(e) {
    e.currentTarget.closest('ds-modal').close()
  }
}
