import { SourceItem } from './items/Source'
import { DropboxItem } from './items/Dropbox'
import { Queue } from './Queue'
import { selectFromDropbox } from './dropbox'

const _queue = new Queue()
const _cache = []

const queueCached = (item, data) => {
  item.setMeta(data)
  _cache.splice(_cache.indexOf(item), 1)
  _queue.push(item)
  return item
}

export default {
  selectFromDropbox,

  queue: _queue,

  /**
   * Filter files and stores them in cache for later use
   * @param {(HTMLInputElement.file[] | HTMLInputElement.files)} _files
   * @returns {HTMLInputElement.file[]}
   */
  cacheSourceItems(_files) {
    const files = Array.from(_files)
    const filtered = files.filter(file => file.type.includes('video') || file.name.includes('.mkv'))
    _cache.push(...filtered.map(i => new SourceItem(i)))
    return filtered
  },

  /**
   * Caches dropbox items
   * @param {(HTMLInputElement.file[] | HTMLInputElement.files)} _files
   * @returns {HTMLInputElement.file[]}
   */
  cacheDropboxItems(_files) {
    _cache.push(..._files.map(i => new DropboxItem(i)))
    return _files
  },

  /**
   * Connects to cached or queued `Item`
   * Will move item to queue in case its not there yet
   * Queued items will be automatically uploaded
   * @param {{filename: string, url: string, id: string}} data
   * @returns {(typeof SourceItem | typeof | undefined)} queued Item or undefined
   */
  connect(data) {
    const cached = _cache.find(i => i.meta.filename === data.filename)
    const queued = _queue.items.find(i => i.meta.filename === data.filename)

    if (cached) return queueCached(cached, data)
    if (queued) return queued

    return
  }
}
