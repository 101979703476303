/* eslint-disable no-param-reassign */
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'submit']

  connect() {
    this.onChange()
  }

  onChange() {
    const inputs = [...this.formTarget.querySelectorAll('ds-input')]

    this.formTarget.addEventListener('input', () => {
      requestAnimationFrame(() => {
        this.submitTarget.disabled = !inputs.every(input => !!input.value) || !!inputs.find(y => y.syncError)
      })
    })
  }
}
