import { Controller } from '@hotwired/stimulus'
import { throttle } from 'lodash'

export default class extends Controller {
  static targets = [
    'imageUploader',
    'content',
    'position',
    'positionPreview',
    'video',
    'title',
    'price',
    'linkGrabProgress',
    'player',
    'ecommerce',
    'product'
  ]

  static values = {
    scrollTo: Boolean,
    metadataUrl: String,
    products: Array,
    currency: String
  }

  expand(event) {
    event.preventDefault()

    this.contentTarget.classList.toggle('hidden')

    this.element.scrollIntoView({ behavior: 'smooth' })
  }

  disconnect() {
    this.widget?.$destroy()
    this.playerTarget.removeEventListener('video-command', this.handleVideoCommand)
    this.playerTarget.removeEventListener('timeupdate', this.trackEcommerceProducts)
  }

  connect() {
    this.renderEcommerce()
    this.playerTarget.addEventListener('video-command', this.handleVideoCommand)
    this.playerTarget.addEventListener('timeupdate', this.trackEcommerceProducts, true)

    if (this.scrollToValue) {
      this.element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  // Prevent video from playing after seek
  handleVideoCommand = event => {
    if (event.command === 'seek') {
      requestAnimationFrame(() => this.playerTarget.command('pause'))
    }
  }

  close(e) {
    e.currentTarget.closest('ds-modal').close()
  }

  openImageUploader() {
    this.imageUploaderTarget.openUploadPopup()
  }

  setStartTime() {
    const time = this.playerTarget.state.value.currentTime
    const value = Math.round(time)
    const date = new Date(time * 1000)
    const hours = date.getUTCHours()
    const [hh, mm, ss] = date.toTimeString().split(' ')[0].split(':')
    this.positionTarget.value = value

    this.positionPreviewTarget.innerHTML = (Number(hours) ? [hh, mm, ss] : [mm, ss]).join(':')
    this.playerTarget.command('seek', { time: value })
    this.playerTarget.command('pause')
    this.updatePlayerProduct()
  }

  get playerController() {
    return this.application.getControllerForElementAndIdentifier(this.videoTarget, 'program-video')
  }

  updatePlayerProduct() {
    const hiddenImage = this.element.querySelector("input[name='ecommerce_product[image_url][0]']")
    const link = this.element.querySelector("input[name='ecommerce_product[link]']")
    const title = this.element.querySelector("input[name='ecommerce_product[title]']")
    const price = this.element.querySelector("input[name='ecommerce_product[price]']")
    const cta = this.element.querySelector("input[name='ecommerce_product[cta]']")
    const duration = this.element.querySelector("input[name='ecommerce_product[duration]']")
    const position = this.element.querySelector("input[name='ecommerce_product[position]']")

    this.productsValue = [{
      ...this.productsValue[0],
      link: link.value,
      title: title.value,
      price: parseFloat(price.value),
      cta: cta.value,
      duration: parseInt(duration.value),
      position: parseInt(position.value),
      image_url: hiddenImage ? hiddenImage.value : null
    }]

    this.renderEcommerce()
  }

  async productLinkChange(e) {
    if (e.currentTarget.value === '') {
      return
    }

    this.linkGrabProgressTarget.classList.remove('hidden')

    const response = await fetch(`${this.metadataUrlValue}?${new URLSearchParams({
      link: e.currentTarget.value
    })}`)

    this.linkGrabProgressTarget.classList.add('hidden')

    if (response.ok) {
      const json = await response.json()

      if (json.success) {
        this.imageUploaderTarget.reset()

        if (this.titleTarget.value === '') {
          this.titleTarget.value = json.data.title
        }

        if (parseFloat(this.priceTarget.value) === 0 && json.data.price) {
          this.priceTarget.value = json.data.price
        }

        this.imageUploaderTarget.addURLs(json.data.image_url)

        // on next tick
        setTimeout(() => this.updatePlayerProduct(), 0)
      }
    }
  }

  renderEcommerce() {
    const res = this.productsValue.map(this.renderEcommerceItem).join('')
    this.ecommerceTarget.innerHTML = res
  }

  renderEcommerceItem = item => {
    const currency = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: this.currencyValue,
    })

    return `
      <video-condition 
        class="group" 
        query="currentTime >= ${item.position} && currentTime < ${item.position + item.duration}"
      >
        <a
        data-contents--video-edit--ecommerce-target="product"
        href="${item.link}"
        target="_blank"
        data-test="preview-card"
        class="
          max-w-[70%] max-h-[70%]
          absolute w-max opacity-0 invisible flex 
          ltr:left-1 rtl:left-1 top-1
          md:ltr:left-6 md:rtl:right-6 md:top-6
          items-stretch overflow-hidden
          bg-white rounded shadow md:max-w-[360px]
          ltr:translate-x-6 rtl:-translate-x-6 duration-300
          group-[[matching]]:visible
          group-[[matching]]:opacity-100
          group-[[matching]]:translate-x-0
          group-[[matching]]:transition-all
        "
      >
        ${item.image_url ? `
          <div class="basis-20 md:basis-40 shrink-0 flex items-center justify-center">
            <img
              data-test="image"
              alt="${item.title}"
              class="w-auto h-auto max-w-full max-h-full"
              src="${item.image_url}"
            /></div>` : ''}
        <section class="content w-full p-3 text-sm">
          <p class="mb-1 text-gray-900 dark:text-gray-900">
            ${item.title}
          </p>

          ${Number(item.price) > 0 ? `
            <p data-test="price" class="mb-1 text-gray-600 dark:text-gray-600">
              ${currency.format(item.price)}
            </p>` : ''}

          <ds-button variant="text" data-test="button-cta">
            ${item.cta}
          </ds-button>
        </section>
      </a>
    </video-condition>
    `
  }
}
