import { Controller } from '@hotwired/stimulus'

const segmentsObjToArray = segmentsAsObj => Object
  .entries(segmentsAsObj)
  .filter(([key, val]) => val)
  .map(([key, val]) => key)


const buildSegmentsAsObj = segments => segments.reduce((acc, s) => {
  acc[s] = true
  return acc
}, {})

const buildState = (oldState, newState) => {
  // toggle all on
  if (!oldState.all_members && newState.all_members) {
    return {
      ...newState,
      active_member: true,
      churned_member: true
    }
  }

  // toggle all off
  if (oldState.all_members && !newState.all_members) {
    return {
      ...newState,
      active_member: false,
      churned_member: false
    }
  }

  // all selected
  if (newState.active_member && newState.churned_member) {
    return { ...newState, all_members: true }
  }

  // some unselected
  if (!newState.active_member || !newState.churned_member) {
    return {
      ...newState,
      all_members: false
    }
  }

  return newState
}

export default class extends Controller {
  static targets = ['segments', 'allMembersCheckbox', 'specificSegmentsCheckbox']

  connect() {
    this.state = buildSegmentsAsObj(this.element.value)
  }

  change(event) {
    this.state = buildState(this.state, buildSegmentsAsObj(event.target.value))

    event.target.value = segmentsObjToArray(this.state)
  }
}
