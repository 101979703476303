<template>
  <div
    class="flex w-full leading-3 px-5 py-4 items-center"
    @click="$refs.input.focus()"
  >
    <svg-sprite-icon
      class="mr-3"
      :icon="require('assets/icons/search.svg')"
      :width="14"
      :height="14"
    />
    <input
      ref="input"
      placeholder="What you'd like to find?"
      class="leading-4 border-none flex-1 outline-none text-sm"
      @input="$emit('change', $event)"
    >
    <button
      class="border rounded px-3 py-2 text-sm hover:border-gray-500"
      @click="$emit('close')"
    >
      Esc
    </button>
  </div>
</template>
<script>
import SvgSpriteIcon from 'common/components/SvgSpriteIcon'

export default {
  components: {
    SvgSpriteIcon,
  },
  mounted() {
    this.$refs.input.focus()
  },
}
</script>
