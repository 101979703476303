import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'selectAll', 'items', 'listToolbox',
    'listToolboxToolsSelectedLabel', 'drawerTemplate', 'deletableDeleteButton'
  ]

  selectAll(e) {
    // eslint-disable-next-line no-return-assign,no-param-reassign
    this.itemsControllers.forEach(x => x.setSelected(e.target.checked))
    if (this.selectedCounts() === 0 && e.target.checked === true) {
      return
    }
    this.setToolbox(e.target.checked)
  }

  changeCheckbox(e) {
    if (e.target.checked && this.selectAllTarget.checked === false) {
      this.selectAllTarget.checked = true
      this.setToolbox(true)
    } else if (e.target.checked === false
      && this.itemsControllers.filter(x => x.checkboxTarget.checked === true).length === 0) {
      this.selectAllTarget.checked = false
      this.setToolbox(false)
    }

    this.listToolboxToolsSelectedLabelTarget.innerHTML = this.selectedLabelText
  }

  createModal(ids, batchUrl, bulk = false) {
    this.element.querySelectorAll('ds-modal').forEach(x => x.remove())

    const modal = document.createElement('ds-modal')
    modal.setAttribute('size', 'tiny')
    modal.setAttribute('position', 'right')
    modal.setAttribute('closable', 'true')
    modal.setAttribute('show-on-mount', 'true')

    modal.innerHTML = this.drawerTemplateTarget.innerHTML

    const url = new URL(batchUrl)
    ids.forEach(id => {
      url.searchParams.append('ids[]', id)
    })

    if (bulk) {
      url.searchParams.append('bulk_action', 'true')
    }

    modal.querySelector('turbo-frame').setAttribute('src', url.toString())

    this.element.appendChild(modal)

    return modal
  }

  addTo(e) {
    e.preventDefault()

    if (e.currentTarget.dataset.id) {
      this.createModal([e.currentTarget.dataset.id], e.currentTarget.dataset.url)
    } else {
      const ids = this.itemsControllers.filter(x => x.checkboxTarget.checked === true).map(x => x.checkboxTarget.value)
      this.createModal(ids, e.currentTarget.dataset.url, true)
    }
  }

  destroyBatch(e) {
    e.preventDefault()

    const form = e.currentTarget.closest('form')

    form.querySelectorAll('input[name="ids[]"]').forEach(x => x.remove())

    this.itemsControllers.filter(x => x.checkboxTarget.checked === true).forEach(x => {
      const id = document.createElement('input')
      id.setAttribute('name', 'ids[]')
      id.setAttribute('value', x.checkboxTarget.value)
      id.setAttribute('type', 'hidden')
      form.appendChild(id)
    })

    this.selectAllTarget.checked = false
    this.setToolbox(false)
    form.requestSubmit()
  }

  setToolbox(visible) {
    if (visible) {
      this.listToolboxTarget.querySelector('input[type="checkbox"]').checked = true
      this.listToolboxTarget.classList.add('bg-gray-100')
      this.listToolboxToolsSelectedLabelTarget.innerHTML = this.selectedLabelText
    } else {
      this.listToolboxTarget.classList.remove('bg-gray-100')
    }
  }

  selectedCounts() {
    return this.itemsControllers.filter(x => x.checkboxTarget.checked === true).length
  }

  get selectedLabelText() {
    const count = this.selectedCounts()

    return `${count} ITEM${count > 1 ? 'S' : ''} SELECTED`
  }

  get itemsControllers() {
    // eslint-disable-next-line max-len
    return this.itemsTargets.map(x => this.application.getControllerForElementAndIdentifier(x, 'contents--live-events--list-item'))
  }
}
