import { Controller } from '@hotwired/stimulus'

/*
  NOTICE:
  Useful for pattern like list checkboxes with "[x] select all":
  [] Select all / 15 ITEMS SELECTED [Select all 97 items]
  --------------------------
  [] Item
  [] Item
  [] Item
*/

export default class extends Controller {
  static targets = ['bulk', 'label', 'checkbox', 'selectAllCheckbox', 'removeAllCheckbox']

  static values = {
    defaultLabelText: { type: String, default: 'select all' },
    itemName: { type: String, default: 'item|items' },
    selectLabelText: { type: String, default: '{n} {name} selected' },
    totalCount: Number,
    selectedCount: Number,
  }

  connect() {
    this.bulkTarget.checked = !!this.selectedCountValue
    this.setCounterLabel(this.selectedCountValue)
    this.resetAllBundleCheckboxes()
  }

  _updRemoveAllCheckbox(checked) {
    if (this.hasRemoveAllCheckboxTarget) {
      this.removeAllCheckboxTarget.checked = checked
    }
  }

  selectAllInBundle() {
    const checked = this.totalCountValue !== this.selectedCountValue
    this.bulkTarget.checked = checked
    this.selectAllCheckboxTarget.checked = checked
    this._updRemoveAllCheckbox(!checked)
    this.checkboxTargets.forEach(item => {
      if (!item.disabled) {
        item.checked = checked
      }
    })
    this.selectedCountValue = checked ? this.totalCountValue : 0
    this.setCounterLabel(this.selectedCountValue)
    this.toggleBundleText()
  }

  toggleBundleText() {
    const span = this.element.querySelector("[data-text='bundle']")
    if (span) {
      span.innerText = this.totalCountValue === this.selectedCountValue ? 'Unselect' : 'Select'
    }
  }

  resetAllBundleCheckboxes() {
    this.selectAllCheckboxTarget.checked = false
    this._updRemoveAllCheckbox(false)
    this.toggleBundleText()
  }

  selectAll(e) {
    this.checkboxTargets.forEach(x => {
      if (!x.disabled) {
        x.checked = e.currentTarget.checked
      }
    })
    if (e.currentTarget.checked) {
      this.selectedCountValue = this.checkboxTargets.length
    } else {
      this.selectedCountValue = 0
    }
    this.setCounterLabel(this.selectedCountValue)
    this.resetAllBundleCheckboxes()
  }

  change(e) {
    this.selectedCountValue = this.checkboxTargets.filter(item => item.checked).length
    if (e.currentTarget.checked) {
      this.bulkTarget.checked = true
    } else if (this.selectedCountValue === 0) {
      this.bulkTarget.checked = false
    }
    this.setCounterLabel(this.selectedCountValue)
    this.resetAllBundleCheckboxes()
  }

  setCounterLabel(count) {
    const text = this.selectLabelTextValue
    if (count === 0) {
      this.labelTarget.innerText = this.defaultLabelTextValue
      return
    }
    const forms = this.itemNameValue.split('|')
    let message = text.replace('{n}', count)

    if (forms.length === 2) {
      message = message.replace('{name}', count > 1 ? forms[1] : forms[0])
    }

    this.labelTarget.innerText = message
  }
}
