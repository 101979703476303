import { Controller } from '@hotwired/stimulus'
import { destroy } from '@rails/request.js'

export default class extends Controller {
  static targets = [
    'form',
    'aboutFormSection',
    'progressBar',
    'generatableInput',
    'aiIcon',
    'confirmModal',
    'confirmModalSubmitButton'
  ]

  static values = {
    videoReleaseStage: String,
    videoCreatedAt: Number
  }

  aboutFormSectionTargetConnected() {
    if (['pending', 'in_progress'].includes(this.generationStateValue)) {
      this.disableGeneratableInputs()
    }

    this.syncMetaGenerationProgress()
  }

  generatableInputTargetConnected(target) {
    if (this.generationStateValue === 'done' && this.generatedAttributesValue[target.id]) {
      const inputHandler = this.handleGeneratedAttributeInputChange.bind(this)

      target.addEventListener('input', inputHandler)
      target.addEventListener('paste', inputHandler)
    }
  }

  disableGeneratableInputs() {
    this.generatableInputTargets.forEach(input => {
      input.setAttribute('disabled', true)
      input.setAttribute('placeholder', 'Working on it...')
      input.value = ''
    })
  }

  syncMetaGenerationProgress() {
    if (!this.hasProgressBarTarget) return

    // it's an approximate estimation based on the actual subtitle and ai generation time
    const estimatedGenerationSecs = Math.max(45, this.videoDurationValue * 0.2)
    const createdAtAndNowDiff = (new Date() - new Date(this.videoCreatedAtValue * 1000)) / 1000
    const progress = (createdAtAndNowDiff / estimatedGenerationSecs) * 100
    const displayProgress = Math.min(Math.ceil(progress), 95)

    this.progressBarTarget.style.setProperty('--width', `${displayProgress}%`)

    if (displayProgress < 95) {
      setTimeout(this.syncMetaGenerationProgress.bind(this), 1000)
    }
  }

  cancelGeneration(event) {
    event.preventDefault()

    destroy(event.target.href, {
      contentType: 'text/vnd.turbo-stream.html',
      responseKind: 'turbo-stream'
    })
  }

  handleGeneratedAttributeInputChange(event) {
    const attributeIcon = this.aiIconTargets.find(icon => icon.dataset.generatedAttribute === event.target.id)

    if (event.target.value === this.generatedAttributesValue[event.target.id]) {
      attributeIcon.classList.remove('hidden')
    } else {
      attributeIcon.classList.add('hidden')
    }
  }

  onFormSubmit(event) {
    if (!this.isConfirmModalRequired()) return

    event.preventDefault()

    const buttonText = this.formReleaseStage === 'published' ? 'Yes, publish it' : 'Yes, schedule it'
    this.confirmModalSubmitButtonTarget.innerText = buttonText
    this.confirmModalSubmitButtonTarget.disabled = false
    this.confirmModalTarget.open()
  }

  submitConfirmModal() {
    this.formTarget.requestSubmit()
    this.confirmModalTarget.close()
  }

  isConfirmModalRequired() {
    const isPublishedOrScheduled = this.videoReleaseStageValue !== this.formReleaseStage
      && ['published', 'scheduled'].includes(this.formReleaseStage)

    const isSomeAiIconVisible = this.hasAiIconTarget
      && this.aiIconTargets.some(icon => !icon.classList.contains('hidden'))

    return isPublishedOrScheduled
      && this.generationStateValue === 'done'
      && !this.confirmModalTarget.hasAttribute('aria-expanded')
      && isSomeAiIconVisible
  }

  get formReleaseStage() {
    return new FormData(this.formTarget).get('status')
  }

  get generationStateValue() {
    return this.aboutFormSectionTarget.dataset.metaGenerationState
  }

  get videoDurationValue() {
    return this.aboutFormSectionTarget.dataset.videoDuration || 1800
  }

  get generatedAttributesValue() {
    try {
      return JSON.parse(this.aboutFormSectionTarget.dataset.generatedAttributes)
    } catch (e) {
      return {}
    }
  }
}
