import { Controller } from '@hotwired/stimulus'

import { debounce } from 'lodash'

/*
  NOTICE:
  Use this controller for debounce submit forms, like search:
  <form>
    <%= text_field_tag :search, params[:search],
      data: {
              controller: 'helpers--debounced-submit',
              action: 'input->helpers--debounced-submit#submit'
            }
     }%>
  </form>
*/

export default class extends Controller {
  static values = {
    wait: { type: Number, default: 200 }
  }

  connect() {
    if (this.element.hasAttribute('clearable')) {
      this.element.addEventListener('clear', () => this.submit())
    }
  }

  disconnect() {
    if (this.element.hasAttribute('clearable')) {
      this.element.removeEventListener('clear', () => this.submit())
    }
  }

  initialize() {
    this.submit = debounce(this.submit, this.waitValue).bind(this)
  }

  submit() {
    if (this.element.tagName === 'FORM') {
      this.element.requestSubmit()
    } else {
      this.element.closest('form').requestSubmit()
    }
  }
}
