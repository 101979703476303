export function emit(element, eventName, options = {}) {
  const event = new CustomEvent(eventName, {
    bubbles: true,
    cancelable: false,
    composed: true,
    detail: {},
    ...options
  });
  element.dispatchEvent(event);
  return event;
}
