import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    setTimeout(() => this.countdownFrom(5), 1000)
  }

  countdownFrom(currentValue) {
    const arf = document.getElementById('countdown-value');

    if (arf === null) { return }

    const newValue = currentValue - 1

    if (newValue === 0) {
      const modal = document.getElementById('countdown-message-modal');
      modal.close();

      const mic_btn = document.querySelector('ds-button[data-lk-source="microphone"]')
      const cam_btn = document.querySelector('ds-button[data-lk-source="camera"]')
      const share_btn = document.querySelector('ds-button[data-lk-source="screen_share"]')

      if (mic_btn.dataset.lkEnabled === 'false') { mic_btn.click() }
      if (cam_btn.dataset.lkEnabled === 'false') { cam_btn.click() }
      if (share_btn) { share_btn.classList.toggle('hidden', false) }

      return
    } else {
      arf.innerHTML = newValue

      setTimeout(() => this.countdownFrom(newValue), 1000);
    }
  }
}
