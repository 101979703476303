import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'button'];

  connect() {
    this.toggleButtonState()
  }

  toggleButtonState() {
    if (this.checkboxTarget.checked) {
      this.buttonTarget.disabled = false
    } else {
      this.buttonTarget.disabled = true
    }
  }
}
