import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'submitButton', 'paymentMethodField', 'ccLoader', 'stripePaymentElement']

  static values = {
    submitFormOnButtonClick: Boolean,
    hasDefaultPaymentMethod: Boolean
  }

  connect() {
    console.log(process.env.FRONTEND_BUILD)
    this.stripeLoading = true
    this.formLoading = false
    this.submitFormOnButtonClick = this.submitFormOnButtonClickValue
    this.hasDefaultPaymentMethod = this.hasDefaultPaymentMethodValue
    this.stripeFormEmpty = true

    this.debug = process.env.FRONTEND_BUILD === 'development'

    this.stripeInitStart = e => this.onStripeInitStart(e)
    document.addEventListener('stripe:initialization:started', this.stripeInitStart)

    this.stripeInitEnd = e => this.onStripeInitEnd(e)
    document.addEventListener('stripe:initialization:end', this.stripeInitEnd)

    this.paymentMethodReceive = e => this.onPaymentReceived(e)
    document.addEventListener('stripe:payment-method-received', this.paymentMethodReceive)

    this.paymentErrorReceive = e => this.onStripeError(e)
    document.addEventListener('stripe:payment-error-received', this.paymentErrorReceive)

    this.beforeTurboFrameRequest = e => this.onBeforeTurboFrameRequest(e)
    document.addEventListener('turbo:before-fetch-request', this.beforeTurboFrameRequest)

    this.turboSubmitEnd = e => this.onTurboSubmitEnd(e)
    document.addEventListener('turbo:submit-end', this.turboSubmitEnd)

    this.emptyChanged = e => this.onEmptyChanged(e)
    document.addEventListener('stripe:empty-changed', this.emptyChanged)
  }

  disconnect() {
    document.removeEventListener('stripe:initialization:started', this.stripeInitStart)
    document.removeEventListener('stripe:initialization:end', this.stripeInitEnd)
    document.removeEventListener('stripe:payment-method-received', this.paymentMethodReceive)
    document.removeEventListener('stripe:payment-error-received', this.paymentErrorReceive)
    document.removeEventListener('turbo:before-fetch-request', this.beforeTurboFrameRequest)
    document.removeEventListener('turbo:submit-end', this.turboSubmitEnd)
  }

  onStripeInitStart() {
    this.debugPrint('stripe init started')

    this.stripeLoading = true
    this.updateLoading()
  }

  onStripeInitEnd() {
    this.debugPrint('stripe init end')

    this.stripePaymentElementTarget.classList.toggle('hidden', false)
    this.ccLoaderTarget.classList.toggle('hidden', true)

    this.stripeLoading = false
    this.updateLoading()
  }

  onPaymentReceived(e) {
    this.debugPrint('stripe payment received')
    this.debugPrint(e.detail.paymentMethod)

    this.paymentMethodFieldTarget.value = e.detail.paymentMethod
    this.formTarget.requestSubmit()

    this.stripeLoading = false
    this.updateLoading()
  }

  onStripeError(e) {
    this.debugPrint('stripe error received')
    this.debugPrint(e.detail.error)

    this.stripeLoading = false
    this.updateLoading()
  }

  onBeforeTurboFrameRequest() {
    this.debugPrint('before turbo frame request')

    this.formLoading = true
    this.updateLoading()
  }

  onTurboSubmitEnd() {
    this.debugPrint('turbo submit end')

    this.formLoading = false
    this.updateLoading()
  }

  onEmptyChanged(event) {
    this.stripeFormEmpty = event.detail.empty
  }

  formSubmit(e) {
    e.preventDefault()

    this.stripeLoading = true
    this.updateLoading()

    const savingCardRequired = !this.hasDefaultPaymentMethod || !this.stripeFormEmpty
    if (savingCardRequired) {
      document.dispatchEvent(new CustomEvent('stripe:save-card'))
    }

    if (this.submitFormOnButtonClick || !savingCardRequired) {
      this.formTarget.requestSubmit()
    }
  }

  updateLoading() {
    if (this.formLoading || this.stripeLoading) {
      this.submitButtonTarget.querySelector('.form-loading').classList.toggle('hidden', false)
      this.submitButtonTarget.querySelector('.form-ready').classList.toggle('hidden', true)
      this.submitButtonTarget.disabled = true
    } else {
      this.submitButtonTarget.querySelector('.form-loading').classList.toggle('hidden', true)
      this.submitButtonTarget.querySelector('.form-ready').classList.toggle('hidden', false)
      this.submitButtonTarget.disabled = false
    }
  }

  debugPrint(message) {
    if (this.debug) { console.log(message) }
  }
}
