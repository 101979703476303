import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { url: String, name: String }

  async showFormModal() {
    const currentIntegration = document.getElementById('integration-modal-service-name')
    if (!currentIntegration || currentIntegration.innerText !== this.nameValue) {
      const url = new URL(this.urlValue)
      await fetch(url)
        .then(response => response.text())
        .then(html => {
          // eslint-disable-next-line no-undef
          Turbo.renderStreamMessage(html)
        })
      currentIntegration.innerText = this.nameValue
    }

    document.querySelector('#connect-integration-modal').open()
  }
}
