import { Controller } from '@hotwired/stimulus'
import Vue from 'vue'
import BGroupSelect from 'common/components/BGroupSelect'
import BInput from 'common/components/BInput'

export default class extends Controller {
  static targets = ['wrapperParent', 'wrapperChild', 'placeholder', 'input']

  static values = {
    customTitle: {
      type: String,
      default: 'Custom Link'
    },
    navigationOptionsID: {
      type: String,
      default: 'navigation-options'
    }
  }

  initialize() {
    const {
      wrapperParentTarget,
      wrapperChildTarget,
      placeholderTargets,
    } = this

    placeholderTargets.forEach(target => target.remove())

    this.inputs = {
      parent: { wrapper: wrapperParentTarget, div: document.createElement('div') },
      child: { wrapper: wrapperChildTarget, div: document.createElement('div') }
    }
    this.inputs.parent.wrapper.append(this.inputs.parent.div)
    this.inputs.child.wrapper.append(this.inputs.child.div)

    this.initOptions()
    this.initWidgets()
  }

  initOptions() {
    this.rawOptions = JSON.parse(document.getElementById(this.navigationOptionsIDValue).dataset.navigationOptions)
  }

  initWidgets() {
    const options = [
      { title: 'Custom', items: [] },
      { title: 'Options', items: [] },
      { title: 'Groups', items: [] }
    ]
    this.rawOptions.forEach(option => {
      if (typeof (option.values) === 'object' && !option.values) {
        options[0].items.push({ id: option.title, title: option.title })
      }
      if (typeof (option.values) === 'string') {
        options[1].items.push({ id: option.values, title: option.title })
      }
      if (typeof (option.values) === 'object' && option.values) {
        options[2].items.push({ id: option.title, title: option.title })
      }
    })

    const selectedOption = this.findByUrl(this.inputTarget.value)
    let parentOption = selectedOption && selectedOption.parent

    if (selectedOption && parentOption) {
      this.renderSelectInput('child', this.formatNestedOptions(parentOption))
    } else if (!selectedOption && (this.inputTarget.value || this.inputErrors().length > 0)) {
      this.renderTextInput('child')
      parentOption = this.findByTitle(this.customTitleValue)
    }

    this.renderSelectInput('parent', options, parentOption)
  }

  handleSelectInput(name, val) {
    let option = this.findByUrl(val)

    if (option && option.parent) { // case when sub-element is choosen
      this.inputTarget.value = val
      return
    }

    if (option) { // case when base element is choosen
      this.inputTarget.value = val
      this.inputs.child.wrapper.innerHTML = ''
      if (name === 'parent') {
        this.wrapperChildTarget.classList.add('hidden')
      }
      return
    }

    if (name === 'parent') {
      if (val === this.customTitleValue) { // case then custom is choosen
        this.renderTextInput('child', { renderEmpty: true })
        return
      }

      option = this.findByTitle(val)
      if (option.options.length > 0) { // case then non-empty group is choosen
        this.renderSelectInput('child', this.formatNestedOptions(option))
        return
      }

      // case then empty group is choosen
      this.renderNoticeBlock('child', 'Selected group is empty')
    }
  }

  findByUrl(url, opts = null, parent = null) {
    // eslint-disable-next-line no-restricted-syntax, no-unused-vars
    for (const o of (opts || this.rawOptions)) {
      const value = o.values || o.value

      if (typeof (value) === 'object') {
        const option = this.findByUrl(url, value, { title: o.title, options: value })
        if (option) { return option }
      } else if (value === url) {
        return { title: o.title, parent }
      }
    }
  }

  findByTitle(title) {
    // eslint-disable-next-line no-restricted-syntax, no-unused-vars
    for (const o of this.rawOptions) {
      if (o.title === title) {
        return { title: o.title, options: o.values }
      }
    }
  }

  formatNestedOptions(option) {
    const formatted = { title: option.title || 'Options', items: [] }
    option.options.forEach(o => {
      formatted.items.push({ id: o.value, title: o.title })
    })
    return [formatted]
  }

  inputErrors() {
    return JSON.parse(this.inputTarget.dataset.errors)
  }

  clearInputWrapper(name) {
    this.inputs[name].div = document.createElement('div')
    this.inputs[name].wrapper.innerHTML = ''
    this.inputs[name].wrapper.append(this.inputs[name].div)
  }

  renderNoticeBlock(name, text) {
    if (name === 'child') {
      this.wrapperChildTarget.classList.remove('hidden')
    }
    this.inputs[name].div.innerHTML = `<div class="mt-2">${text}</div>`
  }

  renderSelectInput(name, options, preselect = null) {
    const { inputTarget } = this
    let selectValue = preselect ? preselect.title : inputTarget.value
    this.clearInputWrapper(name)

    if (name === 'child') {
      this.wrapperChildTarget.classList.remove('hidden')
    }

    this.inputs[name].widget = new Vue({
      render: h => h(BGroupSelect, {
        on: {
          input: val => {
            this.handleSelectInput(name, val)
            selectValue = val
            this.inputs[name].widget.$forceUpdate()
          },
        },
        props: {
          label: 'title',
          trackBy: 'id',
          groupValues: 'items',
          groupLabel: 'title',
          value: selectValue,
          placeholder: 'Select website URL',
          options
        }
      }),
    })
    this.inputs[name].widget.$mount(this.inputs[name].div)
  }

  renderTextInput(name, { renderEmpty = false, renderErrors = null, setFocus = false } = {}) {
    const errors = renderErrors || this.inputErrors()
    this.clearInputWrapper(name)

    if (name === 'child') {
      this.wrapperChildTarget.classList.remove('hidden')
    }

    this.inputs[name].widget = new Vue({
      render: h => h(BInput, {
        on: {
          input: val => {
            this.inputTarget.value = val
            if (val.startsWith('http://') && !renderErrors) {
              return this.renderTextInput(name, {
                renderErrors: ['please, use secure HTTPS transfer protocol'],
                setFocus: true
              })
            }
            if (!val.startsWith('http://') && renderErrors) {
              return this.renderTextInput(name, { setFocus: true })
            }
          },
        },
        props: {
          value: renderEmpty ? '' : this.inputTarget.value,
          hasErrors: errors.length > 0,
          errors
        }
      }),
    })
    this.inputs[name].widget.$mount(this.inputs[name].div)

    if (setFocus) {
      this.inputs[name].widget.$el.querySelector('input').focus()
    }
  }
}
