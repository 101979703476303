<template>
  <div class="b-calendar-quick-select overflow-y-auto py-7 px-6">
    <p class="text-base font-medium text-gray-900 dark:text-white mb-3">
      Quick Pick
    </p>
    <ul>
      <li
        v-for="option in renderOptions"
        :key="option.title"
        class="mb-1"
      >
        <button
          type="button"
          class="b-calendar-quick-select-option border-gray-300 text-gray-900 dark:text-white dark:border-gray-700"
          @click="onClickOption(option)"
        >
          {{ option.title }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import UTC from 'dayjs/plugin/utc'
import Timezone from 'dayjs/plugin/timezone'

dayjs.extend(UTC)
dayjs.extend(Timezone)

export const PAST = [
  {
    title: 'Last 6 Months',
    date: dayjs().subtract(6, 'months')
  },
  {
    title: 'Last 3 Months',
    date: dayjs().subtract(3, 'months')
  },
  {
    title: 'Last  Month',
    date: dayjs().subtract(1, 'months')
  },
  {
    title: 'Last Week',
    date: dayjs().subtract(7, 'days')
  },
  {
    title: 'Yesterday',
    date: dayjs().subtract(1, 'days')
  },
]

export const FUTURE = [
  {
    title: 'Tomorrow',
    date: dayjs().add(1, 'days')
  },
  {
    title: 'Next Week',
    date: dayjs().add(7, 'days')
  },
  {
    title: 'Next Month',
    date: dayjs().add(1, 'months')
  },
]

export default {
  name: 'BCalendarQuickSelect',
  props: {
    range: {
      type: Boolean,
      default: false,
    },
    timezone: {
      type: String,
      default: dayjs.tz.guess(),
    },
    past: {
      type: Boolean,
      default: true,
    },
    future: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: null,
    },
  },
  computed: {
    renderOptions() {
      if (Array.isArray(this.options) && this.options.length) return this.options
      let opts = []

      if (this.past) {
        opts = opts.concat(PAST)
      }

      if (!this.range) {
        opts.push({
          title: 'Today',
          date: dayjs()
        })
      }

      if (this.future) {
        opts = opts.concat(FUTURE)
      }

      return opts
    }
  },
  methods: {
    onClickOption(option) {
      const today = dayjs().tz(this.timezone)
      if (this.range) {
        if (option.date.tz(this.timezone).isBefore(dayjs().tz(this.timezone))) {
          this.updateValue([option.date.tz(this.timezone).valueOf(), today.valueOf()])
          return
        }

        this.updateValue([today.valueOf(), option.date.tz(this.timezone).valueOf()])
        return
      }
      this.updateValue(option.date.tz(this.timezone).valueOf())
    },
    updateValue(val) {
      this.$emit('input', val)
    },
  },
}
</script>

<style scoped>
.b-calendar-quick-select-option {
  @apply block w-full px-4 text-center text-sm h-10 leading-10;
}
</style>
