import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    // Dark mode toggler
    this.element.addEventListener('click', event => {
      if (event.target.closest('#dark-mode-toggler')) {
        this.element.classList.toggle('dark-preview')
      }
    })

    // Sticky header
    document.addEventListener('scroll', () => {
      if (window.scrollY > 135) {
        this.element.classList.add('sticky-active')
      } else {
        this.element.classList.remove('sticky-active')
      }
    })

    // Help asking
    this.element.addEventListener('click', event => {
      if (event.target.closest('.help-trigger')) {
        // Since only one element on a page might be a trigger of Intercom bubble
        // So we need to simulate click on this single element
        // Source: https://www.intercom.com/help/en/articles/2785347-when-a-customer-clicks-on-a-website-element
        document.querySelector('[data-intercom="appsHelp"]').click()
      }

      if (event.target.classList.contains('zoom-tv-preview')) {
        const modalDiv = document.createElement('div')
        const svgView = document.getElementById('tv-preview').outerHTML

        modalDiv.innerHTML = `
          <ds-modal size="auto" show-on-mount="true" no-background="true" destroy-on-close="true" with-close-icon close-icon-in-backdrop>
            ${svgView}
          </ds-modal>
        `

        document.body.appendChild(modalDiv.firstElementChild)
      }
    })

    // Onboarding video watch acceptance
    this.element.addEventListener('change', event => {
      if (event.target.id === 'onboarding-acceptance-checkbox') {
        const startAppBuildingButton = document.getElementById('start-app-building-button')

        if (event.target.checked) {
          startAppBuildingButton.removeAttribute('disabled')
        } else {
          startAppBuildingButton.setAttribute('disabled', true)
        }
      }
    })
  }
}
