import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['emailNotificationModalTemplate', 'geoModalTemplate', 'ecommerceModalTemplate', 'replacementModalTemplate', 'deleteForm', 'newCategoryModalTemplate']

  showEmailNotificationModal(e) {
    e.preventDefault()

    this.createModal(this.emailNotificationModalTemplateTarget.innerHTML, 'video_edit_modal')
  }

  showGeoModal() {
    this.createModal(this.geoModalTemplateTarget.innerHTML, 'video_edit_modal')
  }

  showEcommerceModal() {
    this.createModal(this.ecommerceModalTemplateTarget.innerHTML, 'video_edit_modal')
  }

  showReplacementModal() {
    this.createModal(this.replacementModalTemplateTarget.innerHTML, 'video_edit_modal', 'medium')
  }

  showNewCategoryModal() {
    this.createModal(this.newCategoryModalTemplateTarget.innerHTML, 'new_category_modal', 'medium')
  }

  deleteVideo(e) {
    e.preventDefault()

    // eslint-disable-next-line no-restricted-globals
    if (confirm('You are about to permanently delete this video and its associated data. This cannot be restored. Are you sure you want to delete this video?')) {
      this.deleteFormTarget.requestSubmit()
    }
  }

  createModal(content, id, size = 'large') {
    document.getElementById(id)?.remove()

    const modal = document.createElement('ds-modal')
    modal.setAttribute('size', size)
    modal.setAttribute('position', 'center')
    modal.setAttribute('closable', 'true')
    modal.setAttribute('show-on-mount', 'true')
    modal.setAttribute('id', id)

    modal.innerHTML = content

    this.element.appendChild(modal)

    return modal
  }

  closeModal(e) {
    e.currentTarget.closest('ds-modal').close()
  }

  createSubtitleModal(id, editUrl) {
    document.getElementById(id)?.remove()

    const modal = document.createElement('ds-modal')
    modal.setAttribute('size', 'tiny')
    modal.setAttribute('position', 'center')
    modal.setAttribute('closable', 'true')
    modal.setAttribute('show-on-mount', 'true')
    modal.setAttribute('destroy-on-close', 'true')
    modal.setAttribute('with-close-icon', 'true')
    modal.setAttribute('id', `subtitle_modal_${id}`)

    modal.innerHTML = `
      <turbo-frame id="frame_subtitle_${id}" loading="lazy" src="${editUrl}">
      <div class="flex items-center justify-center h-full h-96">
        <ds-spinner></ds-spinner>
      </div>
      </turbo-frame>
    `

    this.element.appendChild(modal)

    return modal
  }
}
