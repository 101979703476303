import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    url: String,
    delay: Number
  }

  connect() {
    this.delay = this.delayValue || 0

    if (this.delay > 0) {
      setTimeout(() => { this.openWindow() }, this.delay * 1000)
    } else {
      this.openWindow()
    }
  }

  openWindow() {
    window.open(this.urlValue, '_blank').focus()
  }
}
