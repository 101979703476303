import { Controller } from '@hotwired/stimulus'

function loadImage(path) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = path
    img.onload = () => {
      resolve(img)
    }
    img.onerror = e => {
      reject(e)
    }
  })
}

export default class extends Controller {
  static targets = ['screenshot']

  connect() {
    this.screenshotTargets.forEach(async screenshot => {
      const mainScreenshot = screenshot.dataset.src
      const fallbackScreenshot = screenshot.dataset.fallback

      if (!mainScreenshot) {
        try {
          const fallbackImg = await loadImage(fallbackScreenshot)
          this.replaceImage(screenshot, fallbackImg.src)

          return
          // eslint-disable-next-line no-empty
        } catch (e) {}
      }

      let timerId
      let attempts = 0

      try {
        const mainImg = await loadImage(mainScreenshot)
        // main screenshot was loaded successfully after the first attempt
        this.replaceImage(screenshot, mainImg.src)
      } catch (error) {
        // loading fallback screenshot because main was unsuccessful
        try {
          const fallbackImg = await loadImage(fallbackScreenshot)
          this.replaceImage(screenshot, fallbackImg.src)
          // eslint-disable-next-line no-empty
        } catch (e) {}

        setTimeout(() => {
          timerId = setInterval(async () => {
            try {
              const mainImg = await loadImage(mainScreenshot)
              // main screenshot was loaded successfully
              // no need to repeat loading
              clearInterval(timerId)
              this.replaceImage(screenshot, mainImg.src)
            } catch (err) {
              if (attempts === 14) {
                // give up to load main screenshot
                clearInterval(timerId)

                return
              }

              attempts++
            }
            // request every next image load attempt in 800ms
          }, 800)
          // request first main image load attempt in 3000ms after initial failed
        }, 3000)
      }
    })
  }

  replaceImage(imgEl, newSource) {
    // eslint-disable-next-line no-param-reassign
    imgEl.src = newSource
    imgEl.classList.remove('hidden')
    imgEl.nextElementSibling.remove()
  }
}
