import { Controller } from '@hotwired/stimulus';
import { createPopper } from '@popperjs/core';
import { emit } from './utils/event'

export default class extends Controller {
  static targets = ['control', 'content']

  static values = {
    /** left, right, center */
    horizontalFloat: {
      type: String,
      default: 'left'
    },
    /** top, bottom */
    verticalFloat: {
      type: String,
      default: 'top'
    },
    distance: {
      type: Number,
      default: 8
    },
    /** focus, hover */
    triggerOnHover: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    arrow: {
      type: Boolean,
      default: false
    }
  }

  popper = null
  controlClickCount = 0
  isOpen = false

  connect() {
    if (this.triggerOnHoverValue) {
      this.element.addEventListener('mouseenter', this.open)
      this.element.addEventListener('mouseleave', this.close)
    } else {
      document.addEventListener('click', this.handleDocumentFocus)
      document.addEventListener('focusin', this.handleDocumentFocus)
    }
  }

  disconnect() {
    document.removeEventListener('click', this.handleDocumentFocus)
    document.removeEventListener('focusin', this.handleDocumentFocus)
  }

  handleDocumentFocus = (e) => {
    if (e.composedPath().includes(this.element)) {
      this.open()
    } else {
      this.close()
    }
  }

  onControlClick = (e) => {
    e?.preventDefault()
    if (this.triggerOnHoverValue) return
    this.controlClickCount += 1
    if (this.controlClickCount > 1) {
      this.close()
    }
  }

  open = () => {
    if (this.disabledValue) return
    if (this.isOpen) return
    this.isOpen = true

    const modifiers = [{
      name: 'offset',
      options: {
        offset: [0, this.distanceValue],
      }
    }]

    if (this.arrowValue) {
      modifiers.push({
        name: 'arrow',
        options: {
          padding: ({ popper, reference }) => popper.width / reference.width
        },
      })
    }

    this.showContent()
    this.popper = createPopper(this.element, this.contentTarget, {
      placement: this.popperPlacement,
      modifiers
    })

    setTimeout(() => window.dispatchEvent(new Event('resize')), 200)
    document.addEventListener('keydown', this.handleKeyDown)

    emit(this.element, 'open')
  }

  get popperPlacement() {
    switch (this.horizontalFloatValue) {
      case 'left':
        return `${this.verticalFloatValue}-start`
      case 'right':
        return `${this.verticalFloatValue}-end`
      case 'center':
        return this.verticalFloatValue
    }
  }

  close = () => {
    if (this.popper) {
      this.popper.destroy()
    }
    this.controlClickCount = 0
    this.hideContent()
    document.removeEventListener('keydown', this.handleKeyDown)
    window.requestAnimationFrame(() => {
      this.isOpen = false
      emit(this.element, 'close')
    })
  }

  handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      this.close()
    }
  }

  showContent = () => {
    this.contentTarget.classList.remove('hidden')
  }

  hideContent = () => {
    this.contentTarget.classList.add('hidden')
  }
}
