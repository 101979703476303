import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['modal', 'text', 'switcher', 'modalButtons']

  static values = {
    initial: String
  }

  connect() {
    this.element.addEventListener('turbo:submit-start', this.onTurboSubmitStart)
  }

  disconnect() {
    this.element.removeEventListener('turbo:submit-start', this.onTurboSubmitStart)
  }

  changeCalendarStatus(event) {
    if (event.target.value === 'true') {
      this.textTarget.innerText = 'enable'
    } else {
      this.textTarget.innerText = 'disable'
    }
    if (this.initialValue !== event.target.value) {
      this.modalTarget.open()
    }
  }

  closeModal() {
    this.modalTarget.close()
  }

  cancelChanges() {
    this.switcherTarget.value = this.initialValue
  }

  onTurboSubmitStart = () => {
    this.modalButtonsTarget.innerHTML = `
      <div class="flex items-baseline">
        <ds-spinner size="xs"></ds-spinner>
        <div class="ml-3 text-gray-500">Saving</div>
      </div>
    `
  }
}
