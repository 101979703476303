import { Controller } from '@hotwired/stimulus'
import Dinero from 'dinero.js'
import { toAmount, toCents } from 'common/filters/money'

export default class extends Controller {
  static values = {
    duration: Number,
    activeNonTrialMembersCount: Number,
    currentMrr: Number,
    currency: String,
    increasingPriceCopy: String,
    decreasingPriceCopy: String,
  }

  static targets = [
    'drawer', 'originalPriceField', 'newPriceField', 'changePriceButton', 'applyButton',
    'saveButtonNotice', 'saveButton', 'confirmationModal', 'mrrPreview', 'emailFields', 'affectedUsers',
    'decreasingPriceNotice', 'emailDeliveryNotice'
  ]

  connect() {
    Dinero.defaultCurrency = this.currencyValue

    this.originalPrice = Number(this.originalPriceFieldTarget.value)
    this.newPrice = Number(this.newPriceFieldTarget.value)
    this.isUpdatingPrice = false
    this.isConfirmed = false
    this.form = this.element
    this.applyTo = 'new_members'
    this.form.addEventListener('submit', this.handleFormSubmit.bind(this))
  }

  openDrawer() {
    this.drawerTarget.open()
  }

  closeDrawer() {
    this.drawerTarget.close()
  }

  handlePriceChange() {
    this.newPrice = Number(this.newPriceFieldTarget.value)

    this.updateMrrDetails()
    this.updateEmailFields()
    this.updateDecreasingPriceNotice()
    this.updateApplyButton()
    this.updateAffectedUsersCopy()
  }

  handleApplyTo(event) {
    this.applyTo = event.target.value
    this.updateEmailFields()
    this.updateDecreasingPriceNotice()
  }

  updateMrrDetails() {
    if (this.newPrice === this.originalPrice) {
      this.mrrPreviewTarget.classList.add('hidden')
    } else {
      const sign = this.newPrice > this.originalPrice ? '+' : '-'

      const durationInMonths = Math.round(this.durationValue / 30.0)
      const projectedMrr = (this.activeNonTrialMembersCountValue * this.newPrice) / durationInMonths
      const mrrDiff = Math.abs(projectedMrr - this.currentMrrValue)

      if (mrrDiff > 0) {
        const formattedDiff = toAmount(toCents(mrrDiff), false)

        this.mrrPreviewTarget.textContent = `${sign}${formattedDiff} ${this.currencyValue}/month`
        this.mrrPreviewTarget.classList.remove('hidden')
      }
    }
  }

  updateEmailFields() {
    const isIncreasingPrice = this.newPrice > this.originalPrice
    const isAffectingNonTrialUsers = this.applyTo === 'all_members' && this.activeNonTrialMembersCountValue

    if (isIncreasingPrice && isAffectingNonTrialUsers) {
      this.emailFieldsTarget.classList.remove('hidden')
      if (this.hasEmailDeliveryNoticeTarget) {
        this.emailDeliveryNoticeTarget.classList.remove('hidden')
      }
    } else {
      this.emailFieldsTarget.classList.add('hidden')
      if (this.hasEmailDeliveryNoticeTarget) {
        this.emailDeliveryNoticeTarget.classList.add('hidden')
      }
    }
  }

  updateDecreasingPriceNotice() {
    const isDecreasingPrice = this.newPrice < this.originalPrice

    if (isDecreasingPrice && this.applyTo === 'all_members') {
      this.decreasingPriceNoticeTarget.classList.remove('hidden')
    } else {
      this.decreasingPriceNoticeTarget.classList.add('hidden')
    }
  }

  updateApplyButton() {
    this.applyButtonTarget.disabled = this.newPrice === this.originalPrice
  }

  updateAffectedUsersCopy() {
    if (this.newPrice >= this.originalPrice) {
      this.affectedUsersTarget.textContent = this.increasingPriceCopyValue
    } else {
      this.affectedUsersTarget.textContent = this.decreasingPriceCopyValue
    }
  }

  apply() {
    if (this.newPrice !== this.originalPrice) {
      this.isUpdatingPrice = true
      this.originalPriceFieldTarget.value = this.newPrice
      this.saveButtonNoticeTarget.classList.remove('hidden')
    } else {
      this.isUpdatingPrice = false
      this.saveButtonNoticeTarget.classList.add('hidden')
    }

    this.closeDrawer()
  }

  confirm() {
    this.isConfirmed = true
    this.form.requestSubmit()
  }

  handleFormSubmit(event) {
    if (this.isUpdatingPrice && !this.isConfirmed) {
      event.preventDefault()
      this.confirmationModalTarget.open()
    }
  }
}
