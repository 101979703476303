import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    csvUrl: String,
    isEmpty: { type: Boolean, default: false }
  }

  connect() {
    const csvExportButton = document.getElementById('export-button')
    if (!csvExportButton) {
      return
    }

    if (this.isEmptyValue) {
      csvExportButton.classList.add('hidden')
    } else {
      csvExportButton.classList.remove('hidden')
    }

    csvExportButton.setAttribute('data-alert-form-url-param', this.csvUrlValue)
  }
}
