import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'submitButton', 'paymentMethodField', 'ccLoader', 'stripePaymentElement']

  connect() {
    this.stripeLoading = true
    this.formLoading = false

    this.stripeInitStart = e => this.onStripeInitStart(e)
    document.addEventListener('stripe:initialization:started', this.stripeInitStart)

    this.stripeInitEnd = e => this.onStripeInitEnd(e)
    document.addEventListener('stripe:initialization:end', this.stripeInitEnd)

    this.paymentMethodReceive = e => this.onPaymentReceived(e)
    document.addEventListener('stripe:payment-method-received', this.paymentMethodReceive)

    this.paymentErrorReceive = e => this.onStripeError(e)
    document.addEventListener('stripe:payment-error-received', this.paymentErrorReceive)

    this.beforeTurboFrameRequest = e => this.onBeforeTurboFrameRequest(e)
    document.addEventListener('turbo:before-fetch-request', this.beforeTurboFrameRequest)

    this.turboSubmitEnd = e => this.onTurboSubmitEnd(e)
    document.addEventListener('turbo:submit-end', this.turboSubmitEnd)
  }

  disconnect() {
    document.removeEventListener('stripe:initialization:started', this.stripeInitStart)
    document.removeEventListener('stripe:initialization:end', this.stripeInitEnd)
    document.removeEventListener('stripe:payment-method-received', this.paymentMethodReceive)
    document.removeEventListener('stripe:payment-error-received', this.paymentErrorReceive)
    document.removeEventListener('turbo:before-fetch-request', this.beforeTurboFrameRequest)
    document.removeEventListener('turbo:submit-end', this.turboSubmitEnd)
  }

  onStripeInitStart() {
    this.stripeLoading = true
    this.updateLoading()
  }

  onStripeInitEnd() {
    this.stripePaymentElementTarget.classList.toggle('hidden', false)
    this.ccLoaderTarget.classList.toggle('hidden', true)

    this.stripeLoading = false
    this.updateLoading()
  }

  onPaymentReceived(e) {
    this.paymentMethodFieldTarget.value = e.detail.paymentMethod
    this.formTarget.requestSubmit()
  }

  onStripeError() {
    this.stripeLoading = false
    this.updateLoading()
  }

  onBeforeTurboFrameRequest() {
    this.formLoading = true
    this.updateLoading()
  }

  onTurboSubmitEnd() {
    this.formLoading = false
    this.updateLoading()
  }

  formSubmit(e) {
    e.preventDefault()

    this.stripeLoading = true
    this.updateLoading()

    document.dispatchEvent(new CustomEvent('stripe:save-card'))
  }

  updateLoading() {
    if (this.formLoading || this.stripeLoading) {
      this.submitButtonTarget.querySelector('.form-loading').classList.toggle('hidden', false)
      this.submitButtonTarget.querySelector('.form-ready').classList.toggle('hidden', true)
      this.submitButtonTarget.disabled = true
    } else {
      this.submitButtonTarget.querySelector('.form-loading').classList.toggle('hidden', true)
      this.submitButtonTarget.querySelector('.form-ready').classList.toggle('hidden', false)
      this.submitButtonTarget.disabled = false
    }
  }
}
