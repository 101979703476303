export class EventBus {
  _watchers = []

  emit(...args) {
    this._watchers.forEach(w => w(...args))
  }

  listen(callback) {
    if (typeof callback !== 'function') {
      throw new Error('Callback have to be a function')
    }

    const fork = (...args) => callback(...args)
    this._watchers.push(fork)
    return () => {
      const index = this._watchers.indexOf(fork)
      if (!~index) return
      this._watchers.slice(index, 1)
    }
  }
}
