import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['text', 'button']

  static values = {
    show: { type: String, default: 'Show' },
    hide: { type: String, default: 'Hide' },
    blur: { type: String, default: 'blur-sm' },
  }

  connect() {
    // field should be blurred by default
    if (!this.textTarget.classList.contains(this.blurValue)) {
      this.textTarget.classList.add(this.blurValue)
    }
    this.buttonTarget.innerText = this.showValue
  }

  toggle() {
    if (this.textTarget.classList.contains(this.blurValue)) {
      this.buttonTarget.innerText = this.hideValue
      this.textTarget.classList.remove(this.blurValue)
    } else {
      this.buttonTarget.innerText = this.showValue
      this.textTarget.classList.add(this.blurValue)
    }
  }
}
