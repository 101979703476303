import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ['modal']

  static values = {
    unbounceEmailUrl: String
  }

  async confirmUpdate() {
    const request = new FetchRequest('patch', this.unbounceEmailUrlValue)
    const response = await request.perform()

    if (response.ok) {
      this.element.close()
      document.getElementById('bounced_email_banner').classList.add('hidden')
    }
  }
}
