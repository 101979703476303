import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['template', 'contentsForm', 'searchForm']

  createModal() {
    this.element.querySelectorAll('ds-modal').forEach(x => x.remove())

    const modal = document.createElement('ds-modal')
    modal.setAttribute('size', 'tiny')
    modal.setAttribute('id', 'contents-drawer-modal')
    modal.setAttribute('position', 'right')
    modal.setAttribute('closable', 'true')
    modal.setAttribute('show-on-mount', 'true')

    modal.innerHTML = this.templateTarget.innerHTML

    this.element.appendChild(modal)

    return modal
  }

  chooseContent() {
    this.createModal()
    this.contentsFormTarget.requestSubmit()
  }

  close(e) {
    e.currentTarget.closest('ds-modal').close()
  }
}
