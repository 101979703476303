import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.element.addEventListener('change', (event) => {
      const additionalApprovalFieldsBlock = document.getElementById('approval-extended')
      const additionalRejectionFieldsBlock = document.getElementById('rejection-extended')

      const isApproval = this.element.querySelector('[value="approval"]').checked
      const isRejection = this.element.querySelector('[value="rejection"]').checked

      if (isApproval) {
        additionalApprovalFieldsBlock.classList.remove('hidden')
        additionalRejectionFieldsBlock.classList.add('hidden')
        additionalApprovalFieldsBlock.removeAttribute('disabled')
        additionalRejectionFieldsBlock.setAttribute('disabled', true)

        const isSpecificDateChosen = this.element.querySelector('[value="specific_date"]').checked

        if (isSpecificDateChosen) {
          const specificDateDatepicker = document.getElementById('specific-date-datepicker')

          specificDateDatepicker.classList.remove('hidden')
          specificDateDatepicker.removeAttribute('disabled')
        } else {
          const specificDateDatepicker = document.getElementById('specific-date-datepicker')

          specificDateDatepicker.classList.add('hidden')
          specificDateDatepicker.setAttribute('disabled', true)
        }
      } else {
        additionalApprovalFieldsBlock.classList.add('hidden')
        additionalRejectionFieldsBlock.classList.remove('hidden')
        additionalApprovalFieldsBlock.setAttribute('disabled', true)
        additionalRejectionFieldsBlock.removeAttribute('disabled')
      }
    })
  }
}
