import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['toggler', 'content']

  static values = {
    state: String,
    behaviour: { type: String, default: 'accordion' }
  }

  connect() {
    if (this.behaviourValue === 'accordion' && this.stateValue === 'expanded') {
      this.contentTarget.classList.remove('hidden')
      this.togglerTarget.querySelector('ds-icon').classList.add('rotate-90')
    } else if (this.behaviourValue === 'append' && this.stateValue === 'expanded') {
      this.showContent()
    }
  }

  toggle(e) {
    if (e) e.preventDefault()

    switch (this.behaviourValue) {
      case 'accordion':
        this.toggleAccordion()
        break
      case 'append':
        this.showContent()
        break
      default:
        this.toggleAccordion()
    }
  }

  showContent() {
    this.togglerTarget.remove()
    this.contentTarget.classList.remove('hidden')
  }

  toggleAccordion() {
    if (this.togglerTarget.querySelector('ds-icon').classList.contains('rotate-90')) {
      this.togglerTarget.querySelector('ds-icon').classList.remove('rotate-90')
      this.contentTarget.classList.add('hidden')
    } else {
      this.togglerTarget.querySelector('ds-icon').classList.add('rotate-90')
      this.contentTarget.classList.remove('hidden')
    }
  }
}
