/* eslint-disable */

// It's a copy of controller from https://github.com/excid3/tailwindcss-stimulus-components library
// Full link to revision: https://github.com/excid3/tailwindcss-stimulus-components/blob/9ea7430160edde319d5ef75b6daf8f0698fecb22/src/modal.js
//
// This example controller works:
//
// <div data-controller="modal" data-modal-allow-background-close="true" data-modal-backdrop-color-value="var(--transparent-dark)">
//   <%= button_tag 'Remove', class: 'u-dropdown-item min-w-full text-gray-900', data: { action: 'click->modal#open dropdown#toggle' } %>
//
//   <!-- Modal Container -->
//   <div data-modal-target="container" data-action="click->modal#closeBackground keyup@window->modal#closeWithKeyboard" class="fixed inset-0 overflow-y-auto flex items-center justify-center z-50 hidden" style="z-index: 9999;">
//     <!-- Modal Inner Container -->
//     <div class="max-h-screen w-full max-w-lg relative">
//       <button data-action="click->modal#close" class="top-0 right-0 rtl:right-auto rtl:left-0 absolute z-10 p-4 text-gray-400">
//         <%= inline_svg 'bullet/close.svg' %>
//       </button>
//
//       <!-- Modal Card -->
//       <div class="bg-white rounded-lg">
//         <div class="p-8">
//           <h2 class="text-xl font-medium">Large Modal Content</h2>
//           <p class="mt-2">This is an example modal dialog box.</p>
//
//           <div class="flex mt-8">
//             <div class="w-full">
//               <%= button_to "Action", any_path(model),
//                                       class: 'u-button u-button-base u-button-fill-danger w-full',
//                                       method: :delete,
//                                       form: { data: { turbo: true } }
//               %>
//             </div>
//             <div class="w-full ml-2">
//               <button class="u-button u-button-base u-button-light-primary w-full" data-action="click->modal#close">Cancel</button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['container']
  static values = {
    backdropColor: { type: String, default: 'rgba(0, 0, 0, 0.8)' },
    restoreScroll: { type: Boolean, default: true }
  }

  connect() {
    // The class we should toggle on the container
    this.toggleClass = this.data.get('class') || 'hidden';

    // The ID of the background to hide/remove
    this.backgroundId = this.data.get('backgroundId') || 'modal-background';

    // The HTML for the background element
    this.backgroundHtml = this.data.get('backgroundHtml') || this._backgroundHTML();

    // Let the user close the modal by clicking on the background
    this.allowBackgroundClose = (this.data.get('allowBackgroundClose') || 'true') === 'true';

    // Prevent the default action of the clicked element (following a link for example) when opening the modal
    this.preventDefaultActionOpening = (this.data.get('preventDefaultActionOpening') || 'true') === 'true';

    // Prevent the default action of the clicked element (following a link for example) when closing the modal
    this.preventDefaultActionClosing = (this.data.get('preventDefaultActionClosing') || 'true') === 'true';
  }

  disconnect() {
    this.close();
  }

  open(e) {
    if (this.preventDefaultActionOpening) {
      e.preventDefault();
    }

    if (e.target.blur) {
      e.target.blur();
    }

    // Lock the scroll and save current scroll position
    this.lockScroll();

    // Unhide the modal
    this.containerTarget.classList.remove(this.toggleClass);

    // Insert the background
    if (!this.data.get("disable-backdrop")) {
      document.body.insertAdjacentHTML('beforeend', this.backgroundHtml);
      this.background = document.querySelector(`#${this.backgroundId}`);
    }
  }

  close(e) {
    if (e && this.preventDefaultActionClosing) {
      e.preventDefault();
    }

    // Unlock the scroll and restore previous scroll position
    this.unlockScroll();

    // Hide the modal
    this.containerTarget.classList.add(this.toggleClass);

    // Remove the background
    if (this.background) { this.background.remove() }
  }

  closeBackground(e) {
    if (this.allowBackgroundClose && e.target === this.containerTarget) {
      this.close(e);
    }
  }

  closeWithKeyboard(e) {
    if (e.keyCode === 27 && !this.containerTarget.classList.contains(this.toggleClass)) {
      this.close(e);
    }
  }

  _backgroundHTML() {
    return `<div id="${this.backgroundId}" class="fixed top-0 left-0 w-full h-full" style="background-color: ${this.backdropColorValue}; z-index: 9998;"></div>`;
  }

  lockScroll() {
    // Add right padding to the body so the page doesn't shift
    // when we disable scrolling
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.paddingRight = `${scrollbarWidth}px`;

    // Save the scroll position
    this.saveScrollPosition();

    // Add classes to body to fix its position
    document.body.classList.add('fixed', 'inset-x-0', 'overflow-hidden');

    // Add negative top position in order for body to stay in place
    document.body.style.top = `-${this.scrollPosition}px`;
  }

  unlockScroll() {
    // Remove tweaks for scrollbar
    document.body.style.paddingRight = null;

    // Remove classes from body to unfix position
    document.body.classList.remove('fixed', 'inset-x-0', 'overflow-hidden');

    // Restore the scroll position of the body before it got locked
    if(this.restoreScrollValue) {
      this.restoreScrollPosition();
    }

    // Remove the negative top inline style from body
    document.body.style.top = null;
  }

  saveScrollPosition() {
    this.scrollPosition = window.pageYOffset || document.body.scrollTop;
  }

  restoreScrollPosition() {
    if (this.scrollPosition === undefined) return;

    document.documentElement.scrollTop = this.scrollPosition;
  }
}
