import { Controller } from '@hotwired/stimulus'
import { isNumber } from 'lodash'

export default class extends Controller {
  static targets = ['screenRadio', 'singleImageIncluded']

  static values = {
    screenOption: String,
    logo: String,
    singleImage: String,
  }

  initialize() {
    this.activeSlide = 1
    this.currentUploaderUrl = this.singleImageValue || this.logoValue || ''
    this.showCurrentSlide()
  }

  connect() {
    this.setScreenOptionStyleClass(this.screenOptionValue)

    this.screenRadioTarget?.addEventListener('change', event => this.onChangeScreenRadioBox(event))

    const singleScreenUploader = this.element.querySelector('ds-image-uploader[name*=onboarding_header_image]')
    singleScreenUploader?.addEventListener('success', event => this.onLoadSingleImage(event))

    const singleScreenCloseButtonUploader = this.element.querySelector('#onboarding-header-uploading-input')?.
    querySelector('.area-image-uploader-close-button')
    singleScreenCloseButtonUploader?.addEventListener('click', () => this.onRemoveSingleImage())

    this.singleImageIncludedTarget?.addEventListener('change', event => this.onChangeIncludedSingleImage(event))

    this.element.querySelectorAll('.custom-slide-toggler')?.forEach(element => {
      element.addEventListener('click', () => this.setActiveSlide(element))
    })

    this.element.querySelectorAll('.onboarding-screen-slide-item')?.forEach(element => {
      element.querySelector('.custom_slide_title').addEventListener('input', event => this.onChangeSlideTitle(event))
      element.querySelector('.custom_slide_description').addEventListener('input', event => this.onChangeSlideDescription(event))
      element.querySelector('.custom_slide_image').addEventListener('success', event => this.onLoadSlideImage(event))
      element.querySelector('.custom_slide_image').querySelector('.area-image-uploader-close-button').addEventListener('click', () => this.onRemoveSlideImage())
    })
  }

  disconnect() {
    this.screenRadioTarget?.removeEventListener('change', event => this.onChangeScreenRadioBox(event))

    const singleScreenUploader = this.element.querySelector('ds-image-uploader[name*=onboarding_header_image]')
    singleScreenUploader?.removeEventListener('success', event => this.onLoadSingleImage(event))

    const singleScreenCloseButtonUploader = this.element.querySelector('#onboarding-header-uploading-input')?.
    querySelector('.area-image-uploader-close-button')
    singleScreenCloseButtonUploader?.removeEventListener('click', () => this.onRemoveSingleImage())

    this.singleImageIncludedTarget?.removeEventListener('change', event => this.onChangeIncludedSingleImage(event))

    this.element.querySelectorAll('.custom-slide-toggler')?.forEach(element => {
      element.removeEventListener('click', () => this.setActiveSlide(element))
    })

    this.element.querySelectorAll('.onboarding-screen-slide-item')?.forEach(element => {
      element.querySelector('.custom_slide_title').removeEventListener('input', event => this.onChangeSlideTitle(event))
      element.querySelector('.custom_slide_description').removeEventListener('input', event => this.onChangeSlideDescription(event))
      element.querySelector('.custom_slide_image').removeEventListener('success', event => this.onLoadSlideImage(event))
      element.querySelector('.custom_slide_image').querySelector('.area-image-uploader-close-button').removeEventListener('click', () => this.onRemoveSlideImage())
    })
  }

  setScreenOptionStyleClass = value => {
    if (value === 'multiple') {
      document.getElementById('svg-onboarding-screen').classList.add('svg-onboarding-screen-multiple')
      document.getElementById('svg-onboarding-screen').classList.remove('svg-onboarding-screen-single')
    } else {
      document.getElementById('svg-onboarding-screen').classList.add('svg-onboarding-screen-single')
      document.getElementById('svg-onboarding-screen').classList.remove('svg-onboarding-screen-multiple')
    }
  }

  onChangeScreenRadioBox = event => {
    if (event.target.value === 'single' || event.target.value === 'multiple') {
      this.setScreenOptionStyleClass(event.target.value)
    }
    if (event.target.value === 'single' && this.singleImageIncludedTarget?.checked
      && document.getElementById('onboarding-screen-preview-from-uploader')
      ?.querySelector('image')?.hasAttribute('xlink:href')) {
      this.toggleSingleImageVisibility(true)
    } else if (event.target.value === 'multiple') {
      this.toggleSingleImageVisibility(false)
    }
  }

  onChangeIncludedSingleImage = event => {
    if (event.target.checked) {
      if (document.getElementById('onboarding-screen-preview-from-uploader')
        ?.querySelector('image')?.hasAttribute('xlink:href')) {
        this.toggleSingleImageVisibility(true)
      }
    } else {
      this.toggleSingleImageVisibility(false)
    }
  }

  onLoadSingleImage = event => {
    if (event.detail[0]?.uploadURL) {
      this.currentUploaderUrl = event.detail[0]?.uploadURL
      document.getElementById('onboarding-screen-preview-from-uploader')?.querySelector('image')?.
      setAttribute('xlink:href', event.detail[0]?.uploadURL)
      this.toggleSingleImageVisibility(true)
    }
  }

  onRemoveSingleImage = () => {
    const previewImage = document.getElementById('onboarding-screen-preview-from-uploader')?.querySelector('image')
    const loadedImage = this.logoValue || ''
    this.currentUploaderUrl = loadedImage
    previewImage?.setAttribute('xlink:href', loadedImage)
    if (!loadedImage) {
      this.toggleSingleImageVisibility(false)
    }
  }

  toggleSingleImageVisibility = visibility => {
    const previewImage = document.getElementById('onboarding-screen-preview-from-uploader')?.querySelector('image')
    let image = this.currentUploaderUrl
    if (visibility) {
      previewImage?.setAttribute('xlink:href', image)
      document.getElementById('onboarding-screen-preview-from-uploader').classList.add('is-single-image-visible')
    } else {
      image = this.logoValue || ''
      previewImage?.setAttribute('xlink:href', image)
      document.getElementById('onboarding-screen-preview-from-uploader').classList.remove('is-single-image-visible')
    }
    if (!image) {
      if (document.getElementById('onboarding-screen-no-preview-image').classList.contains('hidden')) {
        document.getElementById('onboarding-screen-no-preview-image').classList.remove('hidden')
      }
    } else if (image && !document.getElementById('onboarding-screen-no-preview-image').classList.contains('hidden')) {
      document.getElementById('onboarding-screen-no-preview-image').classList.add('hidden')
    }
  }

  setActiveSlide = element => {
    const currentSlideIndex = isNumber(Number(element.dataset.sectionIndex)) && Number(element.dataset.sectionIndex) > 0 ? Number(element.dataset.sectionIndex) : 1
    if (currentSlideIndex !== this.activeSlide) {
      this.activeSlide = currentSlideIndex
      this.showCurrentSlide()
    }
  }

  get getDefaultSlideTitle() {
    return `Slide ${this.activeSlide} title`
  }

  get getDefaultSlideDescription() {
    return 'Describe content, benefits, and why users should become a member. Do not include 3rd-party platforms; prices/trial; links to websites, etc.'
  }

  get getDefaultSlideImageText() {
    return `Slide ${this.activeSlide} Image appears here (1125x1725px PNG/JPEG)`
  }

  onChangeSlideTitle = event => {
    this.setSlideTitle(event.target.value)
  }

  onChangeSlideDescription = event => {
    this.setSlideDescription(event.target.value)
  }

  onLoadSlideImage = event => {
    if (Array.isArray(event.detail) && event.detail.length) {
      this.loadSlideImage(event.detail[0]?.uploadURL)
    }
  }

  onRemoveSlideImage = () => {
    this.removeSlideImage()
  }

  setSlideTitle = (title = '') => {
    const previewPart = document.querySelector('.screen-multiple-part')
    const previewPartTitle = previewPart?.querySelector('.screen-multiple-part-title')
    previewPartTitle.innerHTML = title || this.getDefaultSlideTitle
  }

  setSlideDescription = (description = '') => {
    const previewPart = document.querySelector('.screen-multiple-part')
    const previewPartDescription = previewPart?.querySelector('.screen-multiple-part-description')
    previewPartDescription.innerHTML = description || this.getDefaultSlideDescription
  }

  loadSlideImage = (imageUrl = '') => {
    const previewPart = document.querySelector('.screen-multiple-part')
    if (imageUrl) {
      previewPart?.classList.add('screen-multiple-part-with-image')
      previewPart?.querySelector('#screen-multiple-part--preview-image')?.setAttribute('xlink:href', imageUrl)
    }
  }

  removeSlideImage = () => {
    const previewPart = document.querySelector('.screen-multiple-part')
    previewPart.classList.remove('screen-multiple-part-with-image')
    previewPart.querySelector('#screen-multiple-part--preview-image')?.setAttribute('xlink:href', '')
    previewPart.querySelector('#screen-multiple-part--no-image-text').innerHTML = this.getDefaultSlideImageText
  }

  showCurrentSlide = () => {
    if (this.screenRadioTarget.value === 'multiple') {
      const activeSlideElement = this.element.querySelector(`#custom-slide-section-${this.activeSlide}`)

      const title = activeSlideElement?.querySelector(`[name*=custom_slide_${this.activeSlide}_title]`)?.value || ''
      const description = activeSlideElement?.querySelector(`[name*=custom_slide_${this.activeSlide}_description]`)?.value || ''
      const imageUrl = activeSlideElement?.querySelector(`input[name*=custom_slide_${this.activeSlide}_image]`)?.value || ''

      this.setSlideTitle(title)
      this.setSlideDescription(description)

      if (imageUrl) {
        this.loadSlideImage(imageUrl)
      } else {
        this.removeSlideImage()
      }
    }
  }
}
