import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'uploadButton',
    'deleteButton',
    'uploader',
    'preview'
  ]

  static values = {
    noDeleteButton: { type: Boolean, default: false }
  }

  remove() {
    this.deleteButtonTarget.classList.add('hidden')
    this.uploadButtonTarget.classList.remove('hidden')
    this.previewTarget.classList.add('hidden')

    this.uploaderTarget.resetValue()
  }

  onUploadComplete({ detail }) {
    const [uploadedImage] = detail

    if (!uploadedImage) return

    const { uploadURL } = uploadedImage

    this.previewTarget.src = uploadURL
    this.previewTarget.classList.remove('hidden')

    if (!this.noDeleteButtonValue) {
      this.deleteButtonTarget.classList.remove('hidden')
      this.uploadButtonTarget.classList.add('hidden')
    }
  }
}
