import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    comparedColor: {
      type: String,
      default: '#fff'
    },
    checkContrast: {
      type: Boolean,
      default: false
    }
  }

  static targets = ['input', 'valueText', 'goodContrast', 'badContrast']

  connect() {
    if (this.checkContrastValue) {
      this.checkContrast()
    }
  }

  handleChange() {
    this.valueTextTarget.innerHTML = this.inputTarget.value
  }

  checkContrast() {
    const contrastValue = this.getContrastRatio(this.comparedColorValue, this.inputTarget.value)
    const isGoodContrast = contrastValue > 3
    if (isGoodContrast) {
      this.goodContrastTarget.classList.remove('hidden')
      this.badContrastTarget.classList.add('hidden')
    } else {
      this.goodContrastTarget.classList.add('hidden')
      this.badContrastTarget.classList.remove('hidden')
    }
  }

  getContrastRatio(colorA, colorB) {
    const lumA = this.getLuminance(this.hexToRgb(colorA))
    const lumB = this.getLuminance(this.hexToRgb(colorB))

    return (Math.max(lumA, lumB) + 0.05) / (Math.min(lumA, lumB) + 0.05)
  }

  getLuminance(values) {
    const rgb = values.map(v => {
      const val = v / 255
      return val <= 0.03928 ? val / 12.92 : ((val + 0.055) / 1.055) ** 2.4
    })
    return Number((0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2]).toFixed(3))
  }

  hexToRgb(hex) {
    let currentHex = hex
    if (!this.isValidHex(currentHex)) {
      currentHex = '#000'
    }
    const chunkSize = Math.floor((currentHex.length - 1) / 3)
    const hexArr = this.getChunksFromString(currentHex.slice(1), chunkSize)
    const [r, g, b] = hexArr.map(this.convertHexUnitTo256)
    return [r, g, b]
  }

  isValidHex(hex) {
    return /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex)
  }

  getChunksFromString(st, chunkSize) {
    return st.match(new RegExp(`.{${chunkSize}}`, 'g'))
  }

  convertHexUnitTo256(hexStr) {
    return parseInt(hexStr.repeat(2 / hexStr.length), 16)
  }
}
