import { Controller } from '@hotwired/stimulus'
import copyToClipboard from 'common/utils/copy'

export default class extends Controller {
  static values = { url: String }

  copy(e) {
    e.preventDefault()

    const container = document.getElementById('flash_messages')

    const message = document.createElement('div')
    message.classList.add('u-flash', 'u-flash-success')
    message.setAttribute('data-controller', 'alert')
    message.setAttribute('data-alert-timeout-value', '5000')
    message.setAttribute('data-action', 'click->alert#close')
    message.setAttribute('data-alert-target', 'alert')

    message.innerText = 'Link has been copied to your clipboard.'

    container.appendChild(message)

    copyToClipboard(this.urlValue)
  }
}
