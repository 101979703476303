import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['previewTitle', 'previewLogo', 'currentTitle']

  static values = { url: String }

  connect() {
    this.updatePreviewBrandColor()
    this.updateStoreTitle()

    const logoInput = document.querySelector('[name*=logo_url]')

    if (logoInput) {
      logoInput.addEventListener('change', event => {
        const imageURL = event.target?._input?.input?.value
        debugger
        if (imageURL) {
          this.previewLogoTarget.setAttribute('xlink:href', imageURL)
          this.previewTitleTarget.parentElement.classList.add('hidden')
          this.previewLogoTarget.classList.remove('hidden')
        } else {
          this.previewTitleTarget.parentElement.classList.remove('hidden')
          this.previewLogoTarget.classList.add('hidden')
          this.updatePreviewTitle()
        }
      })
    }

    if (document.querySelector('[name=customize_website_step_started]').value === 'false') {
      const inputs = document.querySelectorAll('[name*=logo_url], [name*=title], [name*=subdomain], [name*=primary_color]')

      inputs.forEach(input => {
        input.addEventListener('change', () => {
          if (document.querySelector('[name=customize_website_step_started]').value === 'false') {
            window.amplitude.getInstance().logEvent('[Onboarding Checklist] Customize Website', { status: 'started' })
            window.posthog.capture('onboarding_checklist:customize_website_start')
            document.querySelector('[name=customize_website_step_started]').value = 'true'
          }
        })
      })
    }
  }

  updatePreviewBrandColor() {
    const styleElement = document.getElementById('preview-variables')
    const newColor = document.querySelector('[name*=primary_color]').value

    if (styleElement && newColor) {
      const newStyleContent = `
      :root {
          --preview-primary-color: ${newColor};
      }
    `
      styleElement.innerHTML = newStyleContent
    }
  }

  updatePreviewTitle() {
    if (this.previewTitleTarget) {
      this.previewTitleTarget.innerHTML = this.currentTitleTarget.value
    }
  }

  updateStoreTitle() {
    if (this.currentTitleTarget.value) {
      document.querySelector('#store-title').innerHTML = this.currentTitleTarget.value
    }
  }
}
