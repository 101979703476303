import { Controller } from '@hotwired/stimulus'
import copy from 'common/utils/copy'

export default class extends Controller {
  static targets = ['url']

  static values = {
    url: String,
  }

  copy() {
    copy(this.urlValue)
  }
}
