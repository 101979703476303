import { Controller } from '@hotwired/stimulus'
import dayjs from 'dayjs'
import UTC from 'dayjs/plugin/utc'
import AdvancedFormat from 'dayjs/plugin/advancedFormat'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(UTC)
dayjs.extend(AdvancedFormat)
dayjs.extend(LocalizedFormat)

export default class extends Controller {
  static targets = ['releaseAt', 'releaseStageScheduled', 'timeZoneWarning', 'storeTimeZoneOffset']

  connect() {
    this.releaseAtTarget.style.display = this.releaseStageScheduledTarget.checked ? null : 'none'
    this.showTimeZoneWarning()
  }

  selectReleaseStage(event) {
    this.releaseAtTarget.style.display = event.target.id === 'release_stage_scheduled' ? null : 'none'
  }

  showTimeZoneWarning() {
    const browserTimeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone
    if (!browserTimeZoneName) return

    const browserTimeZoneOffset = dayjs().tz(browserTimeZoneName).format('Z')
    const storeTimeZoneOffset = dayjs().tz(this.data.get('storeTimeZone')).format('Z')

    if (browserTimeZoneOffset === storeTimeZoneOffset) return

    this.storeTimeZoneOffsetTarget.textContent = storeTimeZoneOffset
    this.timeZoneWarningTarget.style.display = null
  }
}
