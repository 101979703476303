import { Controller } from '@hotwired/stimulus'
import debounce from 'lodash/debounce'
import { emit } from './utils/event'
import useExternalSearch from './utils/combobox/external_search_request'

export default class extends Controller {
  static targets = ['item', 'selectAllButton', 'searchField', 'emptyState', 'listWrapper']

  static values = {
    multiple: Boolean,
    disabled: Boolean,
    selectAll: { type: String, default: 'select_all' },
    selectAllTitle: { type: String, default: 'Select all' },
    deselectAllTitle: { type: String, default: 'Deselect' },
    closeOnSelect: Boolean,
    searchable: Boolean,
    searchableAutoFocus: Boolean,
    readonly: Boolean,
    submitOnChange: Boolean,
    submitOnChangeForm: { type: String, default: 'closest' },
    submitOnChangeDebounce: { type: Number, default: 300 },
    externalSearchUrl: String,
    externalSearchType: { type: String, default: 'disabled' },
    externalSearchMethod: { type: String, default: 'post' },
    externalSearchDebounce: { type: Number, default: 300 },
    externalSearchParams: { type: Object, default: {} },
    externalSearchForm: String
  }

  connect() {
    this.dropdownController?.element?.addEventListener('open', this.handleDropdownOpen)
    this.dropdownController?.element?.addEventListener('close', this.handleDropdownClose)

    this.keyboardSelectedItem = null

    this.handleSubmitOnChange = debounce(
      this.handleSubmitOnChange,
      this.submitOnChangeDebounceValue
    ).bind(this)

    if (this.externalSearchUrlValue) {
      this.sendExternalSearchRequest = debounce(
        this.sendExternalSearchRequest,
        this.externalSearchDebounceValue
      ).bind(this)
    }

    // Observer for the list wrapper to update the placeholder state
    // in case of turbo-stream update/appends
    const debouncedObserverCallback = debounce(() => {
      this.comboboxController?.updatePlaceholderState(this.selectedItems.length)
      this.updateSelectAllButtonState()
    }, 50).bind(this)

    this.listWrapperObserver = new MutationObserver(() => {
      debouncedObserverCallback()
    })

    this.listWrapperObserver.observe(this.listWrapperTarget, { attributes: true, childList: true, subtree: true })

    this.performExternalSearch = useExternalSearch(this)
  }

  disconnect() {
    this.dropdownController?.element?.removeEventListener('open', this.handleDropdownOpen)
    this.dropdownController?.element?.removeEventListener('close', this.handleDropdownClose)

    this.element.removeEventListener('keydown', this.handleMoveSelection)

    this.listWrapperObserver?.disconnect()
  }

  sendExternalSearchRequest(q) {
    if (this.externalSearchTypeValue === 'disabled') {
      return
    }

    const id = this.comboboxController?.element?.id

    this.performExternalSearch(id, q)
  }

  handleSubmitOnChange() {
    if (!this.submitOnChangeValue) {
      return
    }

    if (this.submitOnChangeFormValue === 'closest') {
      this.element.closest('form').requestSubmit()
    } else {
      document.getElementById(this.submitOnChangeFormValue).requestSubmit()
    }
  }

  handleMoveSelection = e => {
    if (this.dropdownController?.isOpen) {
      if (e.key === 'ArrowDown') {
        this.keyboardSelectedItem = this.moveSelectionDown(this.keyboardSelectedItem)
        this.keyboardSelectedItem?.focus()
      }

      if (e.key === 'ArrowUp') {
        this.keyboardSelectedItem = this.moveSelectionUp(this.keyboardSelectedItem)
        this.keyboardSelectedItem?.focus()
      }

      if (e.key === 'Enter' || e.key === ' ') {
        if (this.readonlyValue) {
          return
        }
        this.keyboardSelectedItem?.element.click()

        this.formFieldController?.handleChange()
        this.handleSubmitOnChange()
      }
    }
  }

  moveSelectionDown(current) {
    if (current === null) {
      return this.visibleItems[0]
    }

    const next = this.itemController(current.element.nextElementSibling)

    return next?.isVisible ? next : this.visibleItems[0]
  }

  moveSelectionUp(current) {
    if (current === null) {
      return this.visibleItems[this.visibleItems.length - 1]
    }

    const previous = this.itemController(current.element.previousElementSibling)

    return previous?.isVisible ? previous : this.visibleItems[this.visibleItems.length - 1]
  }

  handleDropdownOpen = () => {
    if (this.searchableValue && this.searchableAutoFocusValue) {
      this.searchFieldTarget.focus()
    }

    this.keyboardSelectedItem = null
    this.element.addEventListener('keydown', this.handleMoveSelection)
  }

  handleDropdownClose = () => {
    this.element.removeEventListener('keydown', this.handleMoveSelection)
  }

  handleSelectItem(event) {
    event.preventDefault()
    event.stopPropagation()

    if (this.readonlyValue) {
      return
    }

    const closestItemWithController = event.currentTarget.closest("[data-controller='beam--combobox-list-item']")

    if (this.multipleValue) {
      this.itemController(closestItemWithController).toggle()
    } else {
      const item = this.itemController(closestItemWithController)
      if (!item.disabled) {
        this.itemsController.forEach(x => x.deselect())
        item.select()
        this.comboboxController.setPlaceholderItemPreview(item.previewTarget.innerHTML)
      }
    }

    if (this.closeOnSelectValue) {
      this.dropdownController.close()
    }

    this.formFieldController?.handleChange()
    this.handleSubmitOnChange()

    emit(this.element, 'selected', { detail: { value: this.itemController(closestItemWithController).valueValue, label: this.itemController(closestItemWithController).labelValue } })
  }

  deselectAll() {
    if (this.disabledValue || this.multipleValue === false || this.readonlyValue) {
      return
    }

    this.itemsController.forEach(item => item.deselect())
    this.handleSubmitOnChange()
    this.formFieldController?.handleChange()

    emit(this.element, 'deselected-all')
  }

  selectAll() {
    if (this.disabledValue || this.multipleValue === false || this.readonlyValue) {
      return
    }

    this.itemsController.forEach(item => item.select())
    this.handleSubmitOnChange()
    this.formFieldController?.handleChange()

    emit(this.element, 'selected-all')
  }

  handleSelectAll(e) {
    e.preventDefault()

    if (this.selectAllValue === 'select_all') {
      this.selectAll()
    } else {
      this.deselectAll()
    }
  }

  handleFilter(e) {
    const { value } = e.currentTarget

    if (this.searchableValue === false || this.disabledValue) {
      return
    }

    if (value === '') {
      this.itemsController.forEach(item => item.show())
      this.emptyStateTarget.classList.add('hidden')

      this.sendExternalSearchRequest('')
      return
    }

    if (this.externalSearchTypeValue !== 'disabled') {
      this.sendExternalSearchRequest(value)
    } else {
      let foundCount = 0

      this.itemsController.forEach(item => {
        if (item.labelValue.toLowerCase().includes(value.toLowerCase())) {
          foundCount++
          item.show()
        } else {
          item.hide()
        }
      })

      if (foundCount === 0) {
        this.emptyStateTarget.classList.remove('hidden')
      } else {
        this.emptyStateTarget.classList.add('hidden')
      }
    }
  }

  updateSelectAllButtonState() {
    if (this.disabledValue || this.multipleValue === false) {
      return
    }

    if (this.selectedItems.length > 0) {
      this.selectAllValue = 'deselect_all'
      this.setSelectAllButtonTitle(`${this.deselectAllTitleValue} (${this.selectedItems.length})`)
    } else {
      this.selectAllValue = 'select_all'
      this.setSelectAllButtonTitle(this.selectAllTitleValue)
    }
  }

  setSelectAllButtonTitle(title) {
    this.selectAllButtonTarget.textContent = title
  }

  get dropdownController() {
    const el = this.element.closest("[data-controller='beam--dropdown']")
    return this.application.getControllerForElementAndIdentifier(el, 'beam--dropdown')
  }

  get itemsController() {
    return this.itemTargets.map(item => this.itemController(item)).filter(x => x !== null)
  }

  get selectedItems() {
    return this.itemsController.filter(item => item.selected === true)
  }

  get visibleItems() {
    return this.itemsController.filter(item => item.isVisible)
  }

  itemController(item) {
    return this.application.getControllerForElementAndIdentifier(item, 'beam--combobox-list-item')
  }

  get formFieldController() {
    const el = this.element.closest('[data-controller="beam--form-field"]')
    return this.application.getControllerForElementAndIdentifier(el, 'beam--form-field')
  }

  get comboboxController() {
    const el = this.element.closest('[data-controller="beam--combobox"]')
    return this.application.getControllerForElementAndIdentifier(el, 'beam--combobox')
  }
}
