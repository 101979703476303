import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['item', 'container']

  static values = {
    backdropColor: { type: String, default: 'rgba(0, 0, 0, 0.8)' },
  }

  connect() {
    this.backgroundId = 'drawer-background'
    this.backgroundHtml = `<div data-drawer-target="container" data-action="click->drawer#closeBackground" id="${this.backgroundId}" class="fixed top-0 left-0 w-full h-full z-40" style="background-color: ${this.backdropColorValue}"></div>`
  }

  toggle(event) {
    if (event) { event.preventDefault() }

    const element = this.itemTarget

    element.classList.toggle('translate-x-full')
    element.classList.toggle('translate-x-0')
    document.body.classList.toggle('overflow-hidden')
    document.body.insertAdjacentHTML('beforeend', this.backgroundHtml)

    this.background = document.querySelector(`#${this.backgroundId}`)
  }

  close(event) {
    if (event) { event.preventDefault() }

    const element = this.itemTarget

    element.classList.toggle('translate-x-full')
    element.classList.toggle('translate-x-0')
    document.body.classList.remove('overflow-hidden')

    if (this.background) { this.background.remove() }
  }

  closeBackground(event) {
    if (event.target === this.containerTarget) {
      this.close(event)
    }
  }
}
