<template>
  <multiselect
    class="b-group-select"
    :class="`size-${size}`"
    v-bind="$props"
    :value="value"
    :clear-on-select="false"
    :show-labels="false"
    :group-values="groupValues"
    :placeholder="placeholder"
    @input="onChangeSelect"
  >
    <slot
      slot="singleLabel"
      :option="currentValue"
    >
      {{ currentValue[label] }}
    </slot>
    <template #option="{ option }">
      <div>
        <span v-if="option.$isLabel">
          {{ option.$groupLabel }}
        </span>
        <slot
          v-else
          name="option"
          :option="option"
        >
          {{ option[label] }}
        </slot>
      </div>
    </template>
  </multiselect>
</template>

<script>
import Multiselect, { multiselectMixin } from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { SIZES } from './BGroupSelect.constants'

export default {
  name: 'BGroupSelect',
  components: {
    Multiselect,
  },
  props: {
    /**
     * See core component props https://vue-multiselect.js.org/#sub-props
     */
    ...Multiselect.props,
    ...multiselectMixin.props,
    options: {
      type: Array,
      default: () => [],
    },
    trackBy: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    groupValues: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    size: {
      type: String,
      default: SIZES.base,
      validator: value => Object.values(SIZES).includes(value),
    },
  },
  computed: {
    currentValue() {
      const value = this.options
        .map(item => item[this.groupValues])
        .flat()
        .filter(item => !item.$isLabel)
        .find(item => item[this.trackBy] === this.value)

      return value || {}
    },
  },
  methods: {
    onChangeSelect(val) {
      this.$emit('input', val[this.trackBy])
    },
  },
}
</script>

<style scoped>
.b-group-select {
  @apply text-black;
}

.b-group-select >>> {
  .multiselect__tags {
    @apply border-gray-300 text-base pl-4;
  }

  .multiselect__placeholder {
    @apply text-gray-500 m-0 p-0;
  }

  .multiselect__input::placeholder {
    @apply text-gray-500;
  }

  .multiselect__input,
  .multiselect__single {
    @apply m-0 p-0 leading-6;
  }

  .multiselect__single {
    @apply truncate;
  }

  .multiselect__select {
    z-index: 2;
  }

  .multiselect__option {
    @apply py-3 px-4 leading-6 text-black;
  }

  .multiselect__option--selected {
    @apply bg-transparent font-normal;
  }

  .multiselect__option--highlight {
    @apply bg-gray-100 text-black;
  }

  .multiselect__option--group {
    @apply bg-white !important;
    @apply text-xs font-bold uppercase;
  }
}

.caret {
  @apply absolute p-4 cursor-pointer transition-transform duration-300 top-0 right-0;
}

.multiselect--active .caret {
  @apply transform rotate-180;
}

.size-sm {
  min-height: 2rem;
}

.size-sm >>> {
  .multiselect__tags {
    padding: 0;
    min-height: 2rem;
  }

  .multiselect__select {
    height: 2rem;
    width: 2rem;
  }

  .multiselect__single, .multiselect__input {
    @apply text-sm leading-4;

    padding: calc(0.5rem - 1px) calc(0.5rem - 1px);
  }
}

.size-base >>> .multiselect__tags {
  @apply text-base leading-6;

  padding: calc(0.5rem - 1px) calc(0.75rem - 1px);
}
</style>

<style>
.dark .b-group-select {
  @apply text-white;

  .multiselect__single,
  .multiselect__input {
    @apply bg-transparent;
  }

  .multiselect__placeholder {
    @apply text-gray-500 m-0 p-0;
  }

  .multiselect__tags {
    @apply bg-gray-800 border-gray-700;
  }

  .multiselect__content-wrapper {
    @apply bg-gray-800 border-gray-700;
  }

  .multiselect__input::placeholder {
    @apply text-gray-700;
  }

  .multiselect__option {
    @apply text-white;
  }

  .multiselect__option--highlight {
    @apply bg-gray-700 text-white;
  }

  .multiselect__option--group {
    @apply bg-gray-800 !important;
  }
}
</style>
