import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['chevronUp', 'chevronDown', 'collapseArea']

  static values = {}

  toggleVisibility() {
    this.collapseAreaTarget.classList.toggle('hidden')
    this.chevronUpTarget.classList.toggle('hidden')
    this.chevronDownTarget.classList.toggle('hidden')
  }
}
