import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'modal',
    'actionBar',
    'uploader',
    'placeholder',
    'image',
    'result'
  ]

  openUploader() {
    this.modalTarget.showModal()
  }

  closeUploader() {
    this.modalTarget.close()
  }

  resetUploader() {
    this.uploaderTarget.reset()
    this.hideActions()
  }

  showActions() {
    this.actionBarTarget.classList.remove('hidden')
  }

  hideActions() {
    this.actionBarTarget.classList.add('hidden')
  }

  upload() {
    this.uploaderTarget.upload()
  }

  showPlaceholder() {
    this.placeholderTarget.classList.remove('hidden')
    this.resultTarget.classList.add('hidden')
  }

  hidePlaceholder() {
    this.placeholderTarget.classList.add('hidden')
    this.resultTarget.classList.remove('hidden')
  }

  onLoad(e) {
    const [image] = e.detail
    if (image) {
      this.imageTarget.src = image.uploadURL
      this.hidePlaceholder()
      this.closeUploader()
    }
  }

  removeImage(e) {
    this.uploaderTarget.resetValue()
    this.showPlaceholder()
  }
}
