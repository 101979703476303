import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ['list', 'uploader']

  static values = { createUrl: String }

  connect() {
    this.uploadedSuccess = this.uploadedSuccess.bind(this)
    this.uploaderTarget.addEventListener('success', this.uploadedSuccess)
  }

  uploadedSuccess(e) {
    const forms = e.detail.map(x => ({ public_url: x.uploadURL, size: x.size }))

    forms.forEach(form => {
      const processingPlaceholder = this.addProcessingPlaceholder()

      new FetchRequest('post', this.createUrlValue, {
        body: JSON.stringify({
          form
        })
      }).perform().then(() => processingPlaceholder.remove())
    })
  }

  showEditModal(e) {
    const { id, editUrl } = e.currentTarget.dataset

    this.showController.createSubtitleModal(id, editUrl)
  }

  destroy(e) {
    // eslint-disable-next-line no-restricted-globals
    e.currentTarget.disabled = true
    const { deleteUrl, subtitleId } = e.currentTarget.dataset
    new FetchRequest('delete', deleteUrl).perform()
    document.getElementById(`delete-subtitle-modal-${subtitleId}`).close()
  }

  addProcessingPlaceholder() {
    const div = document.createElement('div')
    div.classList.add('flex', 'justify-between',
      'items-center', 'border',
      'border-gray-200', 'rounded',
      'px-4', 'py-4', 'mb-2')

    div.innerHTML = `
      <div class="flex">
        <ds-spinner exact-size="24px"></ds-spinner>
        <div class="ml-4 flex flex-col">
          <span class="text-ds-muted">Processing</span>
        </div>
      </div>
     `

    this.listTarget.appendChild(div)

    return div
  }

  get showController() {
    const container = this.element.closest('div[data-controller="contents--video-edit--show"]')
    return this.application.getControllerForElementAndIdentifier(container, 'contents--video-edit--show')
  }
}
