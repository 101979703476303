import { Controller } from '@hotwired/stimulus'
import { debounce } from 'lodash'
import { FetchRequest } from '@rails/request.js'
import { stringifyFormState, isEventChangesForm } from '../../../../libs/form-state'
import copyToClipboard from 'common/utils/copy'

export default class extends Controller {
  static targets = [
    'submit', 'toolbar', 'eventLaunchDateAt', 'hiddenCategories', 'tagNameInput', 'tagsList', 'tagsEmptyState', 'subscriptionPlanSelector', 'donationsEnabled'
  ]

  static values = {
    releaseStage: String,
    accessStatus: String,
    lockWindow: Boolean,
    trailerSearchUrl: String,
    featuredVideoSearchUrl: String
  }

  _initFormState = ''

  _handleFormChange = debounce(() => {
    if (this.element.getAttribute('aria-busy') === 'true') return

    const currentState = stringifyFormState(this.element)
    const isChanged = this._initFormState !== currentState

    if (isChanged) {
      if (this.hasViewOnWebsiteTooltipTarget) {
        this.viewOnWebsiteTooltipTarget.querySelector('div[slot="content"]').classList.remove('hidden')
      }
    } else if (this.hasViewOnWebsiteTooltipTarget) {
      this.viewOnWebsiteTooltipTarget.querySelector('div[slot="content"]').classList.add('hidden')
    }

    this.submitTarget.disabled = !isChanged

    if (this.submitTarget.disabled) {
      if (this.submitTarget.innerText !== 'All changes saved!') {
        this.submitTarget.innerText = 'All changes saved!'
      }
    } else if (this.submitTarget.innerText !== 'Save changes') {
      this.submitTarget.innerText = 'Save changes'
    }
  }, 300)

  handleFormChange = e => {
    if (isEventChangesForm(e)) {
      this._handleFormChange()
    }
  }

  onDsFormElementInitialized() {
    this._initFormState = stringifyFormState(this.element)
  }

  connect() {
    this.setAccessStatus(this.accessStatusValue)
    this.onTurboSubmitStart = this.onTurboSubmitStart.bind(this)
    this.element.addEventListener('turbo:submit-start', this.onTurboSubmitStart)

    this.onTurboSubmitEnd = this.onTurboSubmitEnd.bind(this)
    this.element.addEventListener('turbo:submit-end', this.onTurboSubmitEnd)

    this.onDsFormElementInitialized = debounce(this.onDsFormElementInitialized, 300).bind(this)

    this.onDsFormElementInitialized()

    this.onDocumentScroll()

    this.onDocumentScroll = this.onDocumentScroll.bind(this)
    document.addEventListener('scroll', this.onDocumentScroll)

    if (this.lockWindowValue) {
      this.setupWindowLock()
    }

    this.featuredVideoSearch = debounce(this.featuredVideoSearch, 200).bind(this)
    this.trailerSearch = debounce(this.trailerSearch, 200).bind(this)
  }

  trailerSearch(e) {
    new FetchRequest('post', this.trailerSearchUrlValue, { responseKind: 'turbo-stream', body: JSON.stringify({ q: e.detail }) }).perform()
  }

  toggleCategory(e) {
    e.preventDefault()

    const badge = e.currentTarget.querySelector('ds-badge')
    const checkbox = e.currentTarget.querySelector('input')
    checkbox.checked = !checkbox.checked

    const variant = badge.getAttribute('variant')

    if (variant === 'solid') {
      badge.setAttribute('variant', 'light')
    } else {
      badge.setAttribute('variant', 'solid')
    }

    this.handleFormChange('internal')
  }

  showMoreCategories(e) {
    e.preventDefault()

    this.hiddenCategoriesTargets.forEach(el => {
      el.classList.remove('hidden')
    })

    e.currentTarget.classList.add('hidden')
  }

  setupWindowLock() {
    window.onbeforeunload = () => (this.submitTarget.disabled ? undefined : true)
  }

  featuredVideoSearch(e) {
    new FetchRequest('post', this.featuredVideoSearchUrlValue, { responseKind: 'turbo-stream', body: JSON.stringify({ q: e.detail }) }).perform()
  }

  disconnect() {
    this.element.removeEventListener('turbo:submit-end', this.onTurboSubmitEnd)
    this.element.removeEventListener('turbo:submit-start', this.onTurboSubmitStart)

    document.removeEventListener('scroll', this.onDocumentScroll)
  }

  onDocumentScroll() {
    if (window.scrollY > 10) {
      this.toolbarTarget.classList.add('bg-white', 'border-b')
    } else {
      this.toolbarTarget.classList.remove('bg-white', 'border-b')
    }
  }

  eventLaunchDateAtChange(e) {
    this.eventLaunchDateAtTarget.value = e.target.value === 0 ? '' : e.target.value
    this.eventLaunchDateAtTarget.dispatchEvent(new Event('input'))

    this.handleFormChange('internal')
  }

  onTurboSubmitStart() {
    this.submitTarget.disabled = true
    this.submitTarget.innerText = 'Saving...'
    this.element.setAttribute('aria-busy', 'true')
  }

  onTurboSubmitEnd(e) {
    if (!e.detail.success) {
      this.submitTarget.innerText = 'Save changes'
      this.submitTarget.disabled = false
      this.element.removeAttribute('aria-busy')
    }
  }

  addTag() {
    const value = this.tagNameInputTarget.value.trim()

    if (value !== '') {
      value.split(',').forEach(tag => {
        if (this.tagsListTarget.querySelectorAll(`input[value='${tag.trim()}']`).length === 0 && tag.trim() !== '') {
          this.tagsListTarget.appendChild(this.createTagElement(tag.trim()))
          if (!this.isTagsEmptyList) {
            this.tagsEmptyStateTarget.classList.add('hidden')
          }
        }
      })

      this.tagNameInputTarget.value = ''
    }

    this.handleFormChange('internal')
  }

  keydownEnterAddTag(e) {
    if (e.key === 'Enter') {
      e.preventDefault()

      this.addTag()
    }
  }

  removeTag(e) {
    e.currentTarget.closest('ds-badge').remove()

    if (this.isTagsEmptyList) {
      this.tagsEmptyStateTarget.classList.remove('hidden')
    }

    this.handleFormChange('internal')
  }

  changeAccessStatus(event) {
    this.setAccessStatus(event.target.value)
  }

  setAccessStatus(status) {
    if (status === 'free') {
      this.subscriptionPlanSelectorTarget.classList.add('hidden')
    } else {
      this.subscriptionPlanSelectorTarget.classList.remove('hidden')
    }
  }

  changeLiveChatEnabled(e) {
    if (e.currentTarget.checked) {
      this.donationsEnabledTarget.disabled = false
    } else {
      this.donationsEnabledTarget.checked = false
      this.donationsEnabledTarget.disabled = true
    }
  }

  copyStreamUrl(e) {
    e.preventDefault()
    if (e.currentTarget.innerText === 'Copied!') {
      return
    }

    const oldText = e.currentTarget.innerText
    e.currentTarget.classList.add('text-green-500')
    e.currentTarget.innerText = 'Copied!'

    const target = e.currentTarget

    setTimeout(() => {
      target.classList.remove('text-green-500')
      target.innerText = oldText
    }, 1000)

    copyToClipboard(e.currentTarget.dataset.value)
  }

  copyStreamKey(e) {
    e.preventDefault()
    if (e.currentTarget.innerText === 'Copied!') {
      return
    }

    const oldText = e.currentTarget.innerText
    e.currentTarget.classList.add('text-green-500')
    e.currentTarget.innerText = 'Copied!'

    const target = e.currentTarget

    setTimeout(() => {
      target.classList.remove('text-green-500')
      target.innerText = oldText
    }, 1000)

    copyToClipboard(e.currentTarget.dataset.value)
  }

  createTagElement(title) {
    const tag = document.createElement('ds-badge')
    tag.setAttribute('color', 'gray')
    tag.setAttribute('variant', 'light')

    tag.innerHTML = `
      <input type="hidden" name="tags[]" value="${title}" />
      <span>${title}</span>
      <span data-action="click->contents--live-edit--form#removeTag" class="cursor-pointer relative h-full ms-2 empty:hidden before:absolute before:opacity-0 before:bg-black before:inset-[-0.25em] before:transition-opacity hover:before:opacity-10 before:rounded">
        <ds-icon size="16" name='cross-small'></ds-icon>
     </span>
    `

    return tag
  }
}
