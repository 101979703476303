import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  sendTestEmail() {
    const form = document.getElementById('email_template_form')
    const method_input = form.querySelector('input[name=_method][value=put]')
    method_input.value = 'post'
    form.action += '/test'
    form.requestSubmit()
  }
}
