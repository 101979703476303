import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { url: String }

  async connect() {
    const url = new URL(this.urlValue)
    await fetch(url)
      .then(response => response.text())
      .then(html => {
        // eslint-disable-next-line no-undef
        Turbo.renderStreamMessage(html)
      })
  }
}
