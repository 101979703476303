import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  async connect() {
    this.render(
      ...await Promise.all([
        (await import('vue')).default,
        (await import('common/components/BMultiselect')).default,
      ]),
    )
  }

  render(Vue, Multiselect) {
    const target = document.createElement('span')
    const data = this.element.dataset
    let value = this.element.value || ''

    this.widget = new Vue({
      render: h => h(Multiselect, {
        props: {
          itemName: data.itemName ? data.itemName : undefined,
          value: value.includes(',') ? value.split(',') : value,
          options: data.options ? JSON.parse(data.options) : [],
          trackBy: data.trackBy,
          multiple: !!data.multiple,
          hasClearButton: !!data.hasClearButton
        },
        on: {
          input: _value => {
            value = _value
            this.widget.$forceUpdate()
            this.element.value = Array.isArray(value) ? value.join(',') : value
          }
        }
      }),
    })
    this.element.after(target)
    this.widget.$mount(target)
  }

  disconnect() {
    this.widget?.$destroy()
  }
}
