import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'deleteButton',
    'uploader',
    'preview',
    'imageBlock',
    'placeholderBlock'
  ]

  remove(event) {
    this.imageBlockTarget.classList.add('hidden')
    this.uploaderTarget.classList.remove('hidden')
    if (this.hasPlaceholderBlockTarget) { this.placeholderBlockTarget.classList.remove('hidden') }

    this.uploaderTarget.resetValue()

    if (event.params.save) {
      this.element.closest('form').requestSubmit()
    }
  }

  onUploadComplete(event) {
    const [uploadedImage] = event.detail

    if (!uploadedImage) return

    this.setImage(uploadedImage.uploadURL)
    this.uploaderTarget.setValue(uploadedImage.uploadURL)

    if (event.params.save) {
      this.element.closest('form').requestSubmit()
    }
  }

  setImage(url) {
    this.previewTarget.src = url
    this.imageBlockTarget.classList.remove('hidden')
    this.uploaderTarget.classList.add('hidden')
    if (this.hasPlaceholderBlockTarget) { this.placeholderBlockTarget.classList.add('hidden') }
  }

  onUploadPlaceholder(event) {
    event.preventDefault()

    this.setImage(event.params.url)
    this.uploaderTarget.setValue(event.params.url)

    if (event.params.save) {
      this.element.closest('form').requestSubmit()
    }
  }
}
