import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { url: { type: String, default: '' } }

  connect() {
    const url = new URL(this.urlValue)

    setTimeout(() => {
      fetch(url)
        .then(response => response.text())
        .then(html => {
          // eslint-disable-next-line no-undef
          Turbo.renderStreamMessage(html)
        })
    }, 15000)
  }
}
