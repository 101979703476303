import Dinero from 'dinero.js'

// This currencies do not use 1/100 units like cents
const zeroDecimalCurrencies = [
  'MGA', 'BIF', 'CLP', 'PYG', 'DJF', 'RWF', 'GNF', 'UGX',
  'VND', 'VUV', 'JPY', 'XAF', 'KMF', 'KRW', 'XOF', 'XPF',
]

const currenciesThatOptedInForOmittingCents = ['ZAR']

const currencyLocale = {
  NOK: 'no',
  ZAR: 'en-ZA',
  YER: 'ar-YE',
  UGX: 'en-UG',
  UAH: 'uk',
  TZS: 'en-TZ',
  TOP: 'en-TO',
  THB: 'th',
  SZL: 'en-SZ',
  SLL: 'en-SL',
  SEK: 'sv',
  SCR: 'en-SC',
  SAR: 'ar-SA',
  PKR: 'en-PK',
  PHP: 'en-PH',
  PGK: 'en-PG',
  MYR: 'en-MY',
  MWK: 'en-MW',
  KZT: 'ru-KZ',
  KGS: 'ru-KG',
  KES: 'en-KE',
  INR: 'hi-IN',
  HTG: 'fr-HT',
  HRK: 'hr',
  ETB: 'am-ET',
  DOP: 'es-DO',
  DKK: 'da',
  BWP: 'en-BW',
  BGN: 'bg',
  BDT: 'bn-BD',
  AWG: 'nl-AW',
  ANG: 'nl-AN',
  AED: 'ar-AE',
  CZK: 'cs-CZ',
  TRY: 'tr',
  NGN: 'en-NG',
  MZN: 'pt-MZ',
  HUF: 'hu',
  AOA: 'pt-AO',
  BAM: 'bs-BA',
  BIF: 'fr-BI',
  BOB: 'es-BO',
  CDF: 'fr-CD',
  CRC: 'es-CR',
  DJF: 'fr-DJ',
  GNF: 'fr-GN',
  GTQ: 'es-GT',
  HNL: 'es-HN',
  KMF: 'fr-KM',
  LBP: 'ar-LB',
  MGA: 'fr-MG',
  MRO: 'ar-MR',
  MUR: 'fr-MU',
  NIO: 'es-NI',
  PAB: 'es-PA',
  PEN: 'es-PE',
  PYG: 'es-PY',
  RWF: 'fr-RW',
  VUV: 'fr-VU',
  XPF: 'fr-PF',
}

const defaultMoneyLocale = 'en'

// https://en.wikipedia.org/wiki/IETF_language_tag
// https://tools.ietf.org/rfc/bcp/bcp47.txt
function localeToBCP47(locale) {
  if (locale == null) {
    return defaultMoneyLocale
  }
  return locale.replace(/[_.]/, '-')
}

export function toCents(amount) {
  if (zeroDecimalCurrencies.includes(Dinero.defaultCurrency)) {
    return amount
  }
  return Math.round(amount * 100.0)
}

export function toAmount(amount, symbol = true, locale = defaultMoneyLocale) {
  let dineroJSAmount
  let format
  let bcp47Locale

  if (zeroDecimalCurrencies.includes(Dinero.defaultCurrency)) {
    format = (symbol === true) ? '$0,0' : 0.0
    dineroJSAmount = Dinero({ amount: Math.round(amount), precision: 0 })
  } else {
    format = (symbol === true) ? '$0,0.00' : '0.00'
    dineroJSAmount = Dinero({ amount: Math.round(amount) })
  }

  if (symbol === true) {
    bcp47Locale = currencyLocale[dineroJSAmount.getCurrency()] || localeToBCP47(locale)
  } else {
    // as we don't care about the symbol, we don't care about the locale linked to the currency either
    bcp47Locale = localeToBCP47(locale)
  }

  const amountWithFormat = dineroJSAmount.setLocale(bcp47Locale).toFormat(format)
  if (currenciesThatOptedInForOmittingCents.includes(dineroJSAmount.getCurrency())) {
    return amountWithFormat.replace(/[.,]00$/, '')
  }
  return amountWithFormat
}

export function formattedAmount(amount, locale = defaultMoneyLocale) {
  let dineroJSAmount
  let format

  if (zeroDecimalCurrencies.includes(Dinero.defaultCurrency)) {
    dineroJSAmount = Dinero({ amount: Math.round(amount), precision: 0 })
    format = '$0,0'
  } else {
    const amountInCents = toCents(amount) // convert to cents to properly display decimal values
    dineroJSAmount = Dinero({ amount: Math.round(amountInCents), precision: 2 })
    format = '$0,0.00'
  }

  const bcp47Locale = currencyLocale[dineroJSAmount.getCurrency()] || localeToBCP47(locale)
  const amountWithFormat = dineroJSAmount.setLocale(bcp47Locale).toFormat(format)

  if (currenciesThatOptedInForOmittingCents.includes(dineroJSAmount.getCurrency())) {
    return amountWithFormat.replace(/[.,]00$/, '')
  }
  return amountWithFormat
}
