import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  actualizePinBtn(e) {
    const action = e.target.value
    const channelId = e.target.dataset.channel
    const { showChannels } = document.querySelector('table').dataset
    let btns
    let pinnedPostCount

    if (showChannels === 'true') {
      btns = Array.from(document.querySelectorAll('input[value=Pin]'))
        .filter(b => b !== e.target)
        .filter(b => b.dataset.channel === channelId)
      pinnedPostCount = Array.from(document.getElementsByClassName('pinned'))
        .filter(b => b.dataset.channel === channelId)
        .length
    } else {
      btns = Array.from(document.querySelectorAll('input[value=Pin]')).filter(b => b !== e.target)
      pinnedPostCount = document.getElementsByClassName('pinned').length
    }
    // dom is not updated atm, so increasing count manually
    pinnedPostCount += 1

    if (action === 'Pin') {
      if (pinnedPostCount === 2) {
        btns.forEach(btn => {
          btn.disabled = true
        })
      }
    } else {
      btns.forEach(btn => {
        btn.disabled = false
      })
    }
  }
}
