import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['alert']

  static values = {
    timeout: { type: Number, default: 1000 },
  }

  connect() {
    if (!this.element.closest('ds-modal')) {
      window.requestAnimationFrame(() => {
        const openedModal = document.querySelector('ds-modal[aria-expanded="true"]')
        if (openedModal) {
          openedModal.appendChild(this.alertTarget)
        }
      })
    }
    setTimeout(() => {
      this.close()
    }, this.timeoutValue)
  }

  close() {
    this.alertTarget.remove()
  }
}
