import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'uploader',
    'picker',
    'labelText',
    'image'
  ]

  static values = { src: { type: String, default: '' } }

  connect() {
    if (this.srcValue !== '') {
      this.labelTextTarget.innerHTML = 'Change image'
    }
  }

  onUploadComplete({ detail }) {
    const [uploadedImage] = detail

    if (!uploadedImage) return

    const { uploadURL } = uploadedImage

    this.element.classList.remove('border-2')
    this.imageTarget.src = uploadURL
    this.imageTarget.classList.remove('hidden')
    this.pickerTarget.classList.add('opacity-0', 'hover:opacity-100')
    this.labelTextTarget.innerHTML = 'Change image'

    // if the user uploads an image and then resets it, the button is hidden, so let's unhide upon new upload
    const resetButton = this.pickerTarget.querySelector('ds-button[data-action="click->image-picker#reset"]')
    if (resetButton) {
      resetButton.classList.remove('hidden')
    }
  }

  reset(e) {
    e.preventDefault()

    this.element.classList.add('border-2')
    this.imageTarget.src = ''
    this.imageTarget.classList.add('hidden')
    this.pickerTarget.classList.remove('opacity-0', 'hover:opacity-100')
    this.labelTextTarget.innerHTML = 'Upload image'

    this.uploaderTarget.resetValue()

    e.currentTarget.classList.add('hidden')
  }

  openUploader(e) {
    console.log('open uploader', e.currentTarget)
    this.uploaderTarget.querySelector('ds-button').click()
  }
}
