<template>
  <div
    class="fixed inset-0 flex justify-around items-start z-50"
  >
    <div
      class="fixed inset-0 bg-black opacity-25"
      @click.self="onClose"
    />

    <div class="container max-w-lg bg-white border rounded-lg overflow-hidden z-30 mt-3 shadow-lg">
      <search-input
        @change="onChange"
        @close="onClose"
      />

      <div
        v-if="!isLoading && !items.length"
        class="
          h-40 flex justify-center items-center
          text-gray-500 content border-t px-6 flex-1
          overflow-hidden overflow-y-auto
        "
      >
        <span
          v-if="!value"
          data-test="empty-state"
        >
          Search for menu items, content, people, etc.
        </span>
        <span
          v-else
          data-test="no-results-state"
        >
          No results found for “<span class="text-gray-900">{{ value }}</span>”
        </span>
      </div>

      <div
        v-if="items.length"
        class="content border-t flex-1 overflow-hidden overflow-y-auto"
      >
        <div
          v-for="group in groups"
          :key="group.key"
          class="px-6 py-4"
        >
          <p class="font-bold text-gray-900">
            {{ group.label }}
          </p>
          <search-item
            v-for="item in group.items"
            ref="item"
            :key="item.url + group.key"
            :item="item"
            :selected="item.index === selectedItemIndex"
            :value="value"
            :icon="group.icon"
            data-test="item"
            @click="onSelect"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import debounce from 'lodash/debounce'
import identity from 'lodash/identity'
import SearchItem from './components/SearchItem'
import SearchInput from './components/SearchInput'

const filterRoles = role => item => (
  role === 'owner'
  || !item.roles
  || item.roles.includes(role)
)

export default {
  components: {
    SearchInput,
    SearchItem,
  },
  props: {
    role: {
      type: String,
      default: '',
    },
    providers: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    isLoading: false,
    selectedItemIndex: 0,
    value: '',
    items: [],
  }),
  computed: {
    groups() {
      let i = 0
      return this.providers
        .map(provider => ({
          ...provider,
          items: this.items
            .filter(item => item.provider === provider.key)
            .map(item => ({ ...item, index: i++ })),
        }))
        .filter(g => g.items.length)
    },
  },
  mounted() {
    document.addEventListener('keydown', this.onKeypress, true)
  },
  beforeMount() {
    document.removeEventListener('keydown', this.onKeypress)
  },
  methods: {
    onKeypress({ key }) {
      if (key === 'Escape') {
        this.onClose()
      }
      if (key === 'Enter' && this.selectedItemIndex !== undefined) {
        const selectedItem = this.$refs.item[this.selectedItemIndex]
        if (selectedItem) {
          this.onSelect(selectedItem.item)
        }
      }
      if (key === 'ArrowDown') {
        const selected = this.selectedItemIndex ?? -1
        this.selectedItemIndex = selected >= this.items.length ? 0 : selected + 1
      }
      if (key === 'ArrowUp') {
        this.selectedItemIndex = !this.selectedItemIndex ? 0 : this.selectedItemIndex - 1
      }
    },
    collectData: debounce(async (value, providers, callback) => {
      if (!value) return callback()

      let firstDataReceived = false
      let resolvedPromises = 0

      const promises = providers
        .filter(provider => provider.request)
        .map(provider => provider.request(value))

      return promises.forEach(async promise => {
        const [providerKey, data] = await promise
        resolvedPromises++

        // Rest items once data received
        if (data.length && !firstDataReceived) {
          firstDataReceived = true
          callback()
        }

        // If no data received from any of promises reset items
        if (
          !data.length
          && !firstDataReceived
          && resolvedPromises === promises.length
        ) {
          return callback()
        }

        return callback({ value, providerKey, data })
      })
    }, 300),

    onChange(e) {
      const { value } = e.target
      this.value = value

      // Don't search for less then 3 symbols, but reset on empty query
      if (value && value.length < 3) {
        this.items = []
        return
      }

      this.isLoading = true

      this.collectData(value, this.providers, result => {
        this.isLoading = false

        if (!result || !value) {
          this.selectedItem = 0
          this.items = []
          return
        }

        const { value: _value, providerKey, data } = result

        if (_value !== value) return
        if (!data.length) return

        const provider = this.providers.find(g => g.key === providerKey)
        if (!provider) return

        this.items.push(
          ...data
            .filter(filterRoles(this.role))
            .map(i => Object.assign(i, { provider: providerKey }))
            .map(provider.mapResponse || identity),
        )
      })
    },

    onSelect(item) {
      this.$emit('select', item)
    },

    onClose() {
      this.$emit('close')
    },
  },
}
</script>
<style scoped>
.container {
  --modal-offset: 1.5rem;
  --menu-height: 70px;

  max-height: calc(100vh - var(--modal-offset));
}

.content {
  max-height: calc(100vh - var(--menu-height) - var(--modal-offset));
}
</style>
