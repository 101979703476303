/* eslint-disable no-param-reassign */
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form']

  handleSubmitButton(e) {
    e.preventDefault()

    this.formTarget.requestSubmit()
  }
}
