import { Controller } from '@hotwired/stimulus'
import { debounce } from 'lodash'
import { isEventChangesForm, stringifyFormState } from '../../../libs/form-state'

export default class extends Controller {
  static targets = ['container', 'form', 'submit']

  _initFormState = ''

  _handleFormChange = debounce(() => {
    if (this.formTarget.getAttribute('aria-busy') === 'true') return

    const currentState = stringifyFormState(this.formTarget)
    // eslint-disable-next-line no-underscore-dangle
    const isChanged = this._initFormState !== currentState

    this.submitTarget.disabled = !isChanged

    if (this.submitTarget.disabled) {
      if (this.submitTarget.innerText !== 'All changes saved!') {
        this.submitTarget.innerText = 'All changes saved!'
      }
    } else if (this.submitTarget.innerText !== 'Save') {
      this.submitTarget.innerText = 'Save'
    }
  }, 300)

  connect() {
    this.checkAndSetMaxWidth()
  }

  submitForm(event) {
    event.preventDefault()
    this.formTarget.requestSubmit()
  }

  checkAndSetMaxWidth() {
    const ugcElement = document.getElementById('ugc')
    if (ugcElement && ugcElement.classList.contains('hidden')) {
      this.containerTarget.style.maxWidth = '896px'
      this.containerTarget.style.padding = '0'
      const settingBlock = document.getElementById('left_column')
      settingBlock.classList.add('lg:w-full')
    }
  }

  handleFormChange = e => {
    if (isEventChangesForm(e)) {
      // eslint-disable-next-line no-underscore-dangle
      this._handleFormChange()
    }
  }
}
