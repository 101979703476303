import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'modal',
    'uploader',
    'button'
  ]

  openModal() {
    this.modalTarget.showModal()
  }

  closeModal() {
    this.modalTarget.close()
  }

  resetUploader(e) {
    if (e.target?.nodeName === 'DIALOG') {
      this.uploaderTarget.reset()
    }
    this.resetButtons()
  }

  resetButtons() {
    this.buttonTarget.disabled = false
    this.uploaderTarget.disabled = false
  }

  upload() {
    this.uploaderTarget.upload()
  }

  uploadStarted() {
    this.buttonTarget.disabled = true
    this.uploaderTarget.disabled = true
  }
}
