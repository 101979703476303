import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['element']

  start() {
    this.elementTargets.forEach(target => target.classList.toggle('animate-spin', true))
  }

  stop() {
    this.elementTargets.forEach(target => target.classList.toggle('animate-spin', false))
  }
}
