import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['appItem', 'tooltip']

  connect() {
    const isMobileParamExists = window.location.search.includes('apps=mobile')
    const isIosItemExists = this.appItemTargets.find((item) => item.dataset.code == 'ios')
    const isAndroidItemExists = this.appItemTargets.find((item) => item.dataset.code == 'android')

    if (isMobileParamExists && isIosItemExists && isAndroidItemExists) {
      this.tooltipTarget.classList.remove('hidden')
    }
  }
}
