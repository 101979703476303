import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['setting']

  connect() {
    this.toggleSetting()
  }

  toggleSetting() {
    requestAnimationFrame(() => {
      const radio = this.element.querySelector('ds-radio#enabled_direct_messages')
      this.settingTarget.classList.toggle('hidden', !radio?.hasAttribute('checked'))
    })
  }
}
