import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'button'];

  connect() {
    this.validateInput()
  }

  validateInput() {
    if (!this.inputTarget.value.trim()) {
      this.disableButton()
    } else {
      this.enableButton()
    }
  }

  disableButton() {
    this.buttonTarget.disabled = true
  }

  enableButton() {
    this.buttonTarget.disabled = false
  }
}
