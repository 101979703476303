import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['roleEditModal', 'roleEditModalContainer']

  modalPlaceholder = '<div class="flex items-center justify-center h-full"><ds-spinner></ds-spinner></div>'

  deleteUser(e) {
    e.preventDefault()

    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to delete this user?')) {
      e.target.closest('form').requestSubmit()
    }
  }

  openEditRolesModal(event) {
    this.roleEditModalContainerTarget.innerHTML = this.modalPlaceholder
    this.roleEditModalContainerTarget.src = event.params.url
    this.roleEditModalTarget.open()
  }

  closeEditRolesModal() {
    this.roleEditModalTarget.close()
  }
}
