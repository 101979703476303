import { Controller } from '@hotwired/stimulus'
import Uploader from 'libs/video-uploader'

export default class extends Controller {
  static targets = [
    'form', 'files', 'infoMessage',
    'successMessage', 'area', 'fileInput',
    'selectFilesButton', 'closeButton',
    'aiAssistantToggle'
  ]

  static values = {
    onSelectType: {
      type: String,
      default: 'video_upload'
    }
  }

  connect() {
    this.element.addEventListener('close', this.handleClose)

    if (this.hasFormTarget) {
      this.formTarget.addEventListener('turbo:submit-start', () => {
        this.infoMessageTarget.classList.remove('hidden')
        this.successMessageTarget.classList.add('hidden')
        this.closeButtonTarget.classList.add('hidden')

        this.areaTarget.classList.remove('h-80')
        this.areaTarget.classList.add('h-48', 'opacity-50', 'pointer-events-none')

        this.element.locked = true

        this.fileInputTarget.disabled = true
        this.fileInputTarget.disabled = true
        this.selectFilesButtonTarget.disabled = true

        if (this.hasAiAssistantToggleTarget) {
          this.aiAssistantToggleTarget.disabled = true
        }
      })

      Uploader.queue.listen(event => {
        if (event !== 'ended') return
        this.infoMessageTarget.classList.add('hidden')
        this.successMessageTarget.classList.remove('hidden')
        this.closeButtonTarget.classList.remove('hidden')
        this.areaTarget.classList.remove('opacity-50', 'pointer-events-none')
        this.fileInputTarget.disabled = false
        this.element.locked = false
        this.fileInputTarget.value = null
        this.selectFilesButtonTarget.disabled = false

        if (this.hasAiAssistantToggleTarget) {
          this.aiAssistantToggleTarget.disabled = false
        }
      })
    }
  }

  setDragClass(isOver) {
    const button = this.areaTarget.querySelector('ds-button')
    if (isOver) {
      this.areaTarget.classList.add('bg-white', 'ring-1')
      button.hidden = true
    } else {
      this.areaTarget.classList.remove('bg-white', 'ring-1')
      button.hidden = false
    }
  }

  onDrag(e) {
    const isDragOver = e.type === 'dragover'
    if (isDragOver) e.preventDefault()
    this.setDragClass(isDragOver)
  }

  close() {
    this.element.close()
  }

  handleClose = () => {
    const modal = document.getElementById('bullet_modal')

    modal.src = null
  }

  onSelectFromDropbox(e) {
    e.preventDefault()

    const type = document.createElement('input')
    type.setAttribute('name', 'type')
    type.setAttribute('value', 'dropbox')
    this.filesTarget.appendChild(type)

    Uploader.selectFromDropbox()
      .catch(error => {
        console.log(error)
      })
      .then(files => {
        Uploader.cacheDropboxItems(files).forEach(x => {
          this.appendFileToFilesTarget({
            name: x.name,
            url: x.url,
            size: x.bytes,
          })
        })

        this.formTarget.requestSubmit()
      })
  }

  onSelect(event) {
    event.preventDefault()
    this.onDrag(event)

    const source = event.dataTransfer || event.target

    const type = document.createElement('input')
    type.setAttribute('name', 'type')
    type.setAttribute('value', this.onSelectTypeValue)
    this.filesTarget.appendChild(type)

    const files = Uploader.cacheSourceItems(source.files)

    files.forEach(x => {
      this.appendFileToFilesTarget({
        name: x.name,
        lastModified: x.lastModified,
        size: x.size,
      })
    })

    if (files.length) this.formTarget.requestSubmit()
  }

  appendFileToFilesTarget(file) {
    const {
      url, name, size, lastModified
    } = file

    const filenameEl = document.createElement('input')
    filenameEl.setAttribute('name', 'files[][filename]')
    filenameEl.setAttribute('value', name)
    this.filesTarget.appendChild(filenameEl)

    if (lastModified) {
      const lastModifiedEl = document.createElement('input')
      lastModifiedEl.setAttribute('name', 'files[][last_modified]')
      lastModifiedEl.setAttribute('value', lastModified)
      this.filesTarget.appendChild(lastModifiedEl)
    }

    if (url) {
      const urlEl = document.createElement('input')
      urlEl.setAttribute('name', 'files[][url]')
      urlEl.setAttribute('value', url)
      this.filesTarget.appendChild(urlEl)
    }

    const sizeEl = document.createElement('input')
    sizeEl.setAttribute('name', 'files[][size]')
    sizeEl.setAttribute('value', size)
    this.filesTarget.appendChild(sizeEl)
  }
}
