import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['frame']

  connect() {
    const frameSelector = this.formController.frameSelectorTarget

    const customFrame = frameSelector.querySelector('ds-radio[data-contents--video-edit--image-preview-target="customImageRadio"]')

    if (customFrame.value === '' && this.isAnyFrameSelected === false) {
      this.frameTargets[0].click()
    }
  }

  get isAnyFrameSelected() {
    return this.frameTargets.find(x => x.checked === true) !== undefined
  }

  get formController() {
    return this.application.getControllerForElementAndIdentifier(this.element.closest('form'), 'contents--video-edit--form')
  }
}
