/* eslint-disable no-param-reassign */
import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = [
    'maxDevicesInput',
    'maxDevicesSelect',
    'planSelect',
    'planTitle',
    'planPrice',
    'planStatus',
    'planDuration',
    'planInfoWrapper',
    'addRowButton'
  ]

  static values = {
    submitUrl: String
  }

  addRowButtonTargetConnected() {
    this.displayAddButton()
  }

  selectPlan(e) {
    const selectedOption = this.application.getControllerForElementAndIdentifier(this.planSelectTarget.querySelector('[data-controller="beam--combobox-list"]'), 'beam--combobox-list').selectedItems[0]
    window.selected = selectedOption

    const { label, value } = e.detail
    const { price, status, duration } = selectedOption.previewTarget.querySelector('[data-price]').dataset

    this.planTitleTarget.textContent = label
    this.planTitleTarget.dataset.planId = value
    this.planPriceTarget.textContent = price
    this.planStatusTarget.textContent = status
    this.planDurationTarget.textContent = duration
    this.planSelectTarget.classList.add('hidden')
    this.planInfoWrapperTarget.classList.remove('hidden')

    this.displayAddButton()
  }

  selectMaxDevices(e) {
    const { value } = e.detail

    this.maxDevicesInputTarget.value = value
  }

  addRow(e) {
    e.preventDefault()

    const url = '/bullet/settings/security/add_subscription_plan_override'
    const btn = e.currentTarget

    btn.disabled = true

    new FetchRequest('get', url, {
      query: {
        excluded_ids: this.selectedPlanIds()
      }
    }).perform().then(() => {
      btn.disabled = false
      btn.classList.add('hidden')
    })
  }

  removeRow() {
    this.maxDevicesInputTarget.value = null
    this.planTitleTarget.dataset.planId = null
    this.element.classList.add('hidden')
    this.displayAddButton()

    if (this.selectedPlanIds().length === 0) {
      document.getElementById('add_subscription_plan_override_button').click()
    }
  }

  displayAddButton() {
    const addSubscriptionPlanOverrideButton = document.getElementById('add_subscription_plan_override_button')
    const plansTotal = Number(addSubscriptionPlanOverrideButton.dataset.plansTotal)
    const plansOverriden = this.selectedPlanIds().length

    if (plansOverriden < plansTotal && plansOverriden >= 1) {
      addSubscriptionPlanOverrideButton.classList.remove('hidden')
    } else {
      addSubscriptionPlanOverrideButton.classList.add('hidden')
    }
  }

  selectedPlanIds() {
    const elements = document.querySelectorAll('[data-settings--security--svl-target="planTitle"]')
    const planIds = []

    elements.forEach(foundElement => {
      planIds.push(foundElement.dataset.planId)
    })

    return planIds.filter(Number)
  }
}
