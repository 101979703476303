import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['hiddenCategories']

  toggleCategory(e) {
    e.preventDefault()

    const badge = e.currentTarget.querySelector('ds-badge')
    const checkbox = e.currentTarget.querySelector('input')
    checkbox.checked = !checkbox.checked

    const variant = badge.getAttribute('variant')

    if (variant === 'solid') {
      badge.setAttribute('variant', 'light')
    } else {
      badge.setAttribute('variant', 'solid')
    }

    this.formController.handleFormChange('internal')
  }

  showMoreCategories(e) {
    e.preventDefault()

    this.hiddenCategoriesTargets.forEach(el => {
      el.classList.remove('hidden')
    })

    e.currentTarget.classList.add('hidden')
  }

  get formController() {
    const form = this.element.closest('form')
    return this.application.getControllerForElementAndIdentifier(form, form.dataset.controller)
  }
}
