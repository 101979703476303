import API from 'libs/api'
import FormController from 'common/controllers/form_controller'

export default class extends FormController {
  preview(event) {
    event.preventDefault()

    const formData = new FormData(this.element)
    formData.set('_method', 'post')

    API
      .post('/bullet/api/community/post_preview', formData, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then(response => window.open(response.data.url, '_blank').focus())
  }
}
