import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['charactersCount']

  static values = { maxLength: String }

  handleChange() {
    this.element.removeAttribute('error')
  }

  updateCharactersCount(event) {
    if (this.charactersCountTarget) {
      const count = event.target.value.length
      const remaining = this.maxLengthValue - count
      this.charactersCountTarget.textContent = `Characters left: ${remaining}`
    }
  }
}
