import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { url: { type: String, default: '' }, closeUrl: { type: String, default: '' }, sseLink: { type: String, default: '' }, userId: { type: String, default: '' }  }

  connect() {
    this.updateConnectionStatus()
  }

  async updateConnectionStatus() {
    const url = new URL(this.urlValue)
    const closeUrl = new URL(this.closeUrlValue)

    fetch(url, { method: 'POST' }).then(response => {})

    const evtSource = new EventSource(this.sseLinkValue)

    evtSource.onmessage = event => {
      const message = JSON.parse(event.data)

      const messageType = message.turbo_stream.action

      // workaround for case when user open stream from more than 1 device and close it on one of them
      if (messageType === 'user_close_sessions') {
        const messageData = message.turbo_stream.data

        if (messageData.user_id == this.userIdValue) {
          fetch(url, { method: 'POST' }).then(response => {})
        }
      }
    }

    window.addEventListener('beforeunload', () => {
      if (window.navigator?.sendBeacon) {
        navigator.sendBeacon(closeUrl, {})
      } else {
        fetch(closeUrl, { method: 'POST' }).then(response => {})
      }
    })
  }
}
