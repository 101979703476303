import Chart from 'chart.js'

export default class MoneyChart {
  constructor(labels, values, currency, id) {
    this.labels = labels
    this.values = values
    this.currency = currency
    this.id = id
    Chart.defaults.global.defaultFontFamily = 'Inter, Helvetica, Arial, sans-serif'

    const ctx = document.getElementById(this.id).getContext('2d')
    const gradientFill = ctx.createLinearGradient(0, 0, 0, 350)
    gradientFill.addColorStop(0, 'rgba(0, 106, 255, 0.35)')
    gradientFill.addColorStop(1, 'rgba(0, 106, 255, 0)')
    this.chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: this.labels,
        datasets: [
          {
            data: this.values,
            fill: true,
            backgroundColor: gradientFill,
            borderColor: '#006aff',
            pointBorderColor: 'rgba(0, 106, 255, 0.35)',
            pointBackgroundColor: '#006aff',
            pointHoverBorderWidth: 6,
            pointBorderWidth: 0,
            pointRadius: 0,
            pointHoverRadius: 4,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        hover: {
          mode: 'nearest',
          intersect: false,
        },
        legend: {
          display: false,
        },
        tooltips: {
          mode: 'nearest',
          intersect: false,
          enabled: false,
          custom(tooltipModel) {
            let tooltipEl = document.getElementById('chartjs-tooltip')

            if (!tooltipEl) {
              tooltipEl = document.createElement('div')
              tooltipEl.id = 'chartjs-tooltip'
              tooltipEl.innerHTML = '<table class="bg-white block p-3 shadow rounded"></table>'
              document.body.appendChild(tooltipEl)
            }

            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0
              return
            }

            tooltipEl.classList.remove('above', 'below', 'no-transform')
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign)
            } else {
              tooltipEl.classList.add('no-transform')
            }

            function getBody(bodyItem) {
              return bodyItem.lines
            }

            if (tooltipModel.body) {
              const titleLines = tooltipModel.title || []
              const bodyLines = tooltipModel.body.map(getBody)

              let innerHtml = '<tbody>'

              titleLines.forEach(title => {
                innerHtml += `<tr><td class="text-xs text-gray-700">${title}</td></tr>`
              })

              const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency,
                minimumFractionDigits: 0,
              })

              bodyLines.forEach(body => {
                innerHtml += `<tr><td>${formatter.format(body / 100)}</td></tr>`
              })
              innerHtml += '</tbody>'

              const tableRoot = tooltipEl.querySelector('table')
              tableRoot.innerHTML = innerHtml
            }

            // eslint-disable-next-line no-underscore-dangle
            const position = this._chart.canvas.getBoundingClientRect()

            tooltipEl.style.opacity = 1
            tooltipEl.style.position = 'absolute'
            tooltipEl.style.left = `${position.left + window.pageXOffset + tooltipModel.caretX}px`
            tooltipEl.style.top = `${position.top + window.pageYOffset + tooltipModel.caretY}px`
            tooltipEl.style.padding = `${tooltipModel.yPadding}px ${tooltipModel.xPadding}px`
            tooltipEl.style.pointerEvents = 'none'
          },
        },
        scales: {
          scale: {
            scaleLabel: {
              fontColor: '#485465',
            },
          },
          yAxes: [{
            gridLines: {
              drawBorder: false,
              color: 'rgba(218, 223, 229, 0.6)',
              zeroLineColor: 'rgba(218, 223, 229, 0.6)',
            },
            ticks: {
              padding: 20,
              beginAtZero: false,
              callback: value => {
                const formatter = new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: this.currency,
                  minimumFractionDigits: 0,
                })
                return formatter.format(value / 100)
              },
            },
          }],
          xAxes: [{
            gridLines: {
              drawBorder: false,
              color: 'rgba(0, 0, 0, 0)',
              zeroLineColor: 'rgba(0, 0, 0, 0)',
            },
            ticks: {
              maxTicksLimit: 10,
              maxRotation: 0,
            },
          }],
        },
      },
    })
  }

  destroy() {
    this.chart.destroy()
    if (document.getElementById('chartjs-tooltip')) {
      document.getElementById('chartjs-tooltip').remove()
    }
  }
}
