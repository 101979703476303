import { Controller } from '@hotwired/stimulus'

// Example of use:
// <form data-controller="content-choices--form" data-content-choices--form-target="form">
//   <input value="" data-content-choices--form-target="contentToRemove" type="hidden" name="content_to_remove">
//   <input value="" data-content-choices--form-target="contentToAdd" type="hidden" name="content_to_add">
//   <a data-action="click->content-choices--form#remove" data-content-id="1">Remove</a>
//   <a data-action="click->content-choices--form#add" data-content-id="2">Add</a>
// </form>
export default class extends Controller {
  static targets = ['form', 'contentToAdd', 'contentToRemove']

  submit(event) {
    event.preventDefault()

    this.formTarget.requestSubmit()
  }

  add(event) {
    event.preventDefault()
    const element = event.target
    const { contentId } = element.dataset
    this.contentToAddTarget.value = contentId
    this.contentToRemoveTarget.value = ''
    this.formTarget.requestSubmit()
  }

  remove(event) {
    event.preventDefault()
    const element = event.target
    const { contentId } = element.dataset
    this.contentToRemoveTarget.value = contentId
    this.contentToAddTarget.value = ''
    this.formTarget.requestSubmit()
  }
}
