import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['inlineMessage', 'radioGroup', 'enableBlock', 'disableBlock']

  enableStatus() {
    this.enableBlockTarget.classList.add('border-blue-600')
    this.enableBlockTarget.classList.remove('border-gray-300')
    this.disableBlockTarget.classList.remove('border-blue-600')

    this.inlineMessageTarget.classList.add('hidden')
    this.radioGroupTarget.value = 'true'
  }

  disableStatus() {
    this.disableBlockTarget.classList.add('border-blue-600')
    this.disableBlockTarget.classList.remove('border-gray-300')
    this.enableBlockTarget.classList.remove('border-blue-600')

    this.inlineMessageTarget.classList.remove('hidden')
    this.radioGroupTarget.value = 'false'
  }
}
