import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    document.querySelector('#connect-integration-modal').close()
    window.setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }, 200)
  }
}
