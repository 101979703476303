import { Controller } from '@hotwired/stimulus'
/* eslint no-undef: off */
import copyTextToClipboard from 'common/utils/copy'

export default class extends Controller {
  static targets = ['button']

  copy(e) {
    const value = e.target.previousElementSibling.innerText
    copyTextToClipboard(value)

    this.buttonTarget.innerHTML = 'Done'

    setTimeout(() => {
      this.buttonTarget.innerHTML = 'Copy'
    }, 3000)
  }
}
