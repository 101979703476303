import { Controller } from '@hotwired/stimulus'

/*
  NOTICE:
  Use this controller for change button to disabled(in feature loading) state when form submitting:
  <ds-button variant="primary" type="submit" data-controller="helpers--button-submitting-state">Save</ds-button>
*/

export default class extends Controller {
  static values = {
    mode: { type: String, default: 'form' }
  }

  connect() {
    const f = this.form

    if (f && this.modeValue === 'form') {
      f.addEventListener('turbo:submit-start', () => {
        this.element.disabled = true
      })

      f.addEventListener('turbo:submit-end', () => {
        this.element.disabled = false
      })
    }

    if (this.modeValue === 'click') {
      this.element.addEventListener('click', () => {
        this.element.disabled = true
      })
    }
  }

  get form() {
    return this.element.closest('form')
  }
}
