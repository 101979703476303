import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['listbox', 'input', 'dropdown']

  static values = {
    multichoice: {
      type: Boolean,
      default: false
    }
  }

  connect() {
    if (this.hasListboxTarget) {
      this.inputTarget.addEventListener('mousedown', this.handleMouseDown);
      this.listboxTarget.addEventListener('option-selected', this.handleOptionSelected);
    }
  }

  disconnect() {
    if (this.hasListboxTarget) {
      this.inputTarget.removeEventListener('mousedown', this.toggleList);
      this.inputTarget.removeEventListener('input', this.handleValueChange);
      this.listboxTarget.removeEventListener('option-selected', this.handleOptionSelected);
    }
  }

  handleMouseDown = (event) => {
    event?.preventDefault()
  }

  handleOptionSelected = (event) => {
    if (!this.multichoiceValue) {
      this.inputTarget.value = event.detail.value;
      this.dropdownController?.close()
    } else {
      const itemsSelected = this.listboxTarget.querySelectorAll('.is-selected').length;
      this.inputTarget.value = `${itemsSelected} items selected`;
    }
  }

  get dropdownController() {
    if (this.hasDropdownTarget) {
      return this.application.getControllerForElementAndIdentifier(this.dropdownTarget, 'beam--dropdown')
    }
  }
}
